import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "submenu" ]

  connect() {
    this.submenuTarget.classList.remove('show');
  }

  openMenu() {
    this.submenuTarget.classList.add("show")
  }

  closeMenu() {
    this.submenuTarget.classList.remove("show")
  }
}
