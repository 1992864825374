import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";


export default class extends Controller {
  static targets = [
    "collapseButton",
    "sideBar"
  ]

  connect(){
    this.state = ($('.sidebar').attr('data-navigation-state') == 'true');
  }

  changeState(){
    // STOP LINK FOLLOW
    event.preventDefault();

    const theSidebar = $('.sidebar');
    const theButton = $('.sidebar__expand-btn');
    const contentSection = $('.content-section');
    const bodySection = $('body');

    if(this.state){
      // UPDATE STATE
      this.state = false;

      // REMOVE CLASSES
      $(theSidebar).removeClass('expanded');
      $(theButton).removeClass('expanded');
      $(contentSection).removeClass('sidebar-expanded');
      $(bodySection).removeClass('sidebar-expanded');

    } else {
      // UPDATE STATE
      this.state = true;

      // ADD CLASSES
      $(theSidebar).addClass('expanded');
      $(theButton).addClass('expanded');
      $(contentSection).addClass('sidebar-expanded');
      $(bodySection).addClass('sidebar-expanded');
    }
  }

  closeSidebar(event) {
    event.preventDefault();
    $("#sidebarIndex").removeClass('open')
    if ($(".mobile-menu").hasClass('show'))
      $(".mobile-menu").removeClass('show').removeClass('expanded')
  }

  sidebarBack(event) {
    event.preventDefault();
    $("#sidebarIndex").removeClass('open')
    if (!$(".mobile-menu").hasClass('show'))
      $(".mobile-menu").addClass('show').addClass('expanded')
  }

  openSidebar(event) {
    event.preventDefault();
    const sidebarMenu = $("#sidebarIndex");

    if (sidebarMenu.length) $("#sidebarIndex").addClass('open')
  }

  toggleTrainingSidebar(event) {
    const trainingSidebar = document.getElementById('training__mobile')
    if (trainingSidebar.classList.contains('open')) {
      trainingSidebar.classList.remove('open')
    } else {
      trainingSidebar.classList.add('open')
    }

  }

  toggleWriterFileSidebar(event) {
    const writerFileSidebar = document.getElementById('writerArticle__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  closeWriterFileSidebar(event) {
    this.closeSidebar(event)
    const writerFileSidebar = document.getElementById('writerArticle__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  closeWriterLearnSidebar(event) {
    this.closeSidebar(event)
    const writerFileSidebar = document.getElementById('writerLearn__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  toggleWriterLearnSidebar(event) {
    const writerFileSidebar = document.getElementById('writerLearn__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  closeWriterEditSidebar(event) {
    this.closeSidebar(event)
    const writerFileSidebar = document.getElementById('writerEdit__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  toggleWriterEditSidebar(event) {
    const writerEditSidebar = document.getElementById('writerEdit__mobile')
    if (writerEditSidebar.classList.contains('open')) {
      writerEditSidebar.classList.remove('open')
    } else {
      writerEditSidebar.classList.add('open')
    }
  }

  closeWriterViewSidebar(event) {
    this.closeSidebar(event)
    const writerFileSidebar = document.getElementById('writerView__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  toggleWriterViewSidebar(event) {
    const writerEditSidebar = document.getElementById('writerView__mobile')
    if (writerEditSidebar.classList.contains('open')) {
      writerEditSidebar.classList.remove('open')
    } else {
      writerEditSidebar.classList.add('open')
    }
  }

  closeWriterShareSidebar(event) {
    this.closeSidebar(event)
    const writerShareSidebar = document.getElementById('writerShare__mobile')
    if (writerShareSidebar.classList.contains('open')) {
      writerShareSidebar.classList.remove('open')
    } else {
      writerShareSidebar.classList.add('open')
    }
  }

  toggleWriterShareSidebar(event) {
    const writerFileSidebar = document.getElementById('writerShare__mobile')
    if (writerFileSidebar.classList.contains('open')) {
      writerFileSidebar.classList.remove('open')
    } else {
      writerFileSidebar.classList.add('open')
    }
  }

  closeCowriterSidebar(event) {
    this.closeSidebar(event)
    const writerCowriterSidebar = document.getElementById('writerCowriter__mobile')
    if (writerCowriterSidebar.classList.contains('open')) {
      writerCowriterSidebar.classList.remove('open')
    } else {
      writerCowriterSidebar.classList.add('open')
    }
  }

  toggleCowriterSidebar(event) {
    const writerCowriterSidebar = document.getElementById('writerCowriter__mobile')
    if (writerCowriterSidebar.classList.contains('open')) {
      writerCowriterSidebar.classList.remove('open')
    } else {
      writerCowriterSidebar.classList.add('open')
    }
  }

  closeWriterDownloadSidebar(event) {
    this.closeSidebar(event)
    const writerDownloadSidebar = document.getElementById('writerDownload__mobile')
    if (writerDownloadSidebar.classList.contains('open')) {
      writerDownloadSidebar.classList.remove('open')
    } else {
      writerDownloadSidebar.classList.add('open')
    }
  }

  toggleWriterDownloadSidebar(event) {
    const writerDownloadSidebar = document.getElementById('writerDownload__mobile')
    if (writerDownloadSidebar.classList.contains('open')) {
      writerDownloadSidebar.classList.remove('open')
    } else {
      writerDownloadSidebar.classList.add('open')
    }
  }

  closeWriterCopySidebar(event) {
    this.closeSidebar(event)
    const writerCopySidebar = document.getElementById('writerCopy__mobile')
    if (writerCopySidebar.classList.contains('open')) {
      writerCopySidebar.classList.remove('open')
    } else {
      writerCopySidebar.classList.add('open')
    }
  }

  toggleWriterCopySidebar(event) {
    const writerCopySidebar = document.getElementById('writerCopy__mobile')
    if (writerCopySidebar.classList.contains('open')) {
      writerCopySidebar.classList.remove('open')
    } else {
      writerCopySidebar.classList.add('open')
    }
  }
}
