import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    setTimeout(() => {
      this.hideLoader();
    }, 2000)
  }
  hideLoader() {
   $('.preloader').hide()
  }
}