// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `bin/rails generate channel` command.

import { logger, createConsumer } from "@rails/actioncable"

if (process.env.NODE_ENV === 'development') {
  logger.enabled = true
}

export default createConsumer()
