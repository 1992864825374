import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {

	connect() {
		console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: drafts--new");

		this.projectShortcode = $('body').data("project-shortcode");
		console.log(this.projectShortcode);
	}

	toggle(e) {
		var title = $(e.currentTarget).data("title");
		var mission = $(e.currentTarget).data("mission");
		var content_category_id = $(e.currentTarget).data("content-category-id");

		// SET TITLE
		if (title != undefined){
			$('form#new_article input#article_title').val(title);
		} else {
			$('form#new_article input#article_title').val("");
		}

		// SET MISSION
		if (mission != undefined){
			$('form#new_article textarea#article_mission').val(mission);
		} else {
			$('form#new_article textarea#article_mission').val("");
		}

		// SET CONTENT CATEGORY ID
		if (content_category_id != undefined){
			$('form#new_article input#article_content_category_id').val(content_category_id);
		} else {
			$('form#new_article input#article_content_category_id').val("");
		}
	}

	newDraftFormSubmit(e) {
		var title = $('form#new_article input#article_title').val();
		var objective = $('form#new_article textarea#article_mission').val();

		if (title.length == 0 && objective.length > 0){
			e.preventDefault();

			fetch(`/p/${this.projectShortcode}/articles/generate_title`, {
				headers: { accept: 'application/json' },
				body: JSON.stringify({
					objective: objective
				}),
				method: "POST"})
			.then( data => {
				return data.json();
			}).then( payload => {
				// UPDATE THE TITLE TO CREATE ARTICLE
				if(payload.result.length != 0){
					$('form#new_article input#article_title').val(payload.result);
				}

				$('form#new_article').submit();
			});
		}
	}

}
