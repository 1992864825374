import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    'container',
    'questionInput'
  ]

  connect(){
    this.getGeneration();
  }

  select( event ){
    $('#article_title').val(event.currentTarget.innerText);
    // $('#article_mission').focus();

    this.adjustHeight();
  }

  adjustHeight(){
    // CALCULATE HEIGHT
    var title = $('#article_title');

    $(title).height("1px");
    $(title).height($(title).prop('scrollHeight') + "px");
  }

  getGeneration(){
    const pathname = window.location.pathname
    const parts = pathname.split('/');
    const path = `/${parts[1]}/${parts[2]}/articles/ideas.json`;
    // GRAB GENERATION DATA
    fetch(path, {
      headers: { accept: 'application/json' },
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      if(payload.result == false){
        // ERROR
        console.log("Something went wrong.")
        $('.wais__actions-container').html(" ");
      } else {
        $('.wais__actions-container').html(" ");
        $.each(payload.result, function( i, value ){
          $('.wais__actions-container').prepend(`<button class="btn btn-outline-dark" data-action="click->ideas#select">${value}</button>`);
        });
      }
    });
  }
}
