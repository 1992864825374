import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Cookies from "js-cookie"
import TurndownService from 'turndown'
import { gfm } from 'turndown-plugin-gfm';
import {closeMobileMenus, isMobile, numberWithCommas} from "../../../utility_functions";

export default class extends Controller {

  connect(){
    // EXPANSION STATE
    // TOGGLE STATE
    this.collapsed = false;
  }

  focusMode( event ){
    event.preventDefault();

    // HIDE NAV
    $('nav.navbar').hide();

    // HIDE SIDEBAR NAV
    $('.editor-sidebar').attr('style','display:none !important');

    // HIDE SIDEBAR
    window.dispatchEvent(new CustomEvent("sidebar:collapse"));

    // SET CLASS
    $('body').addClass('focus-mode');
  }

  relaxMode( event ){
    event.preventDefault();

    // SHOW NAV
    $('nav.navbar').show();

    // SHOW SIDEBAR NAV
    $('.editor-sidebar').attr('style','display:flex !important');

    // SHOW SIDEBAR
    window.dispatchEvent(new CustomEvent("sidebar:expand"));

    // SET CLASS
    $('body').removeClass('focus-mode');
  }

  forceSave(){
    // USED TO SAVE WITHOUT DELAY
    // E.G FOR INTEGRATIONS TO UPDATE TO LATEST VERSION

    // FORCE SAVE
    window.dispatchEvent(new CustomEvent("autosave:save"));
  }

  formSuccess(){
    // FOR OPTIONS FORM SUCCESS
    this.toast('notice', "Your options were saved.");
    // CLOSE ALL MODALS
    $('.modal').modal('hide');
  }

  formError(){
    // FOR OPTIONS FORM FAILURE
    this.toast('alert', "This slug is already taken, please pick another one.");
  }

  copyMarkdown(){
    event.preventDefault();

    // GET HTML
    var output = tinymce.activeEditor.getContent({format : 'html'});

    console.log(output);

    // GET HEADLINE
    var headline = $('textarea#title').val();

    // CONVERT TO MARKDOWN
    var turndownService = new TurndownService(
        {
            headingStyle: 'atx',
            horizontalRule: '-'
        });

    turndownService.use(gfm);

    // REGEX REPLACE TO FIX HTML > MARKDOWN LIST SPACING BUG
    var markdown = turndownService.turndown(output).replace(/   +/g, ' ');

    // COPY TO CLIPBOARD
    navigator.clipboard.writeText(`# ${headline}\n\n${markdown}`);

    this.toast('notice', "Markdown copied to clipboard.");
  }

  copyText( event ){
    event.preventDefault();

    // COPIES TEXT TO CLIPBOARD
    var output = tinymce.activeEditor.getContent({format : 'text'});
    var headline = $('textarea#title').val();
    navigator.clipboard.writeText(`${headline}\n${output}`);

    // SEND TOAST
    this.toast('notice', "Text copied to clipboard.");
  }

  copyHTML( event ){
    event.preventDefault();
    
    // COPIES HTML TO CLIPBOARD
    var output = tinymce.activeEditor.getContent({format : 'html'});
    var headline = $('textarea#title').val();
    // SANITIZE OUTPUT
    var cleaned_output = output.replace(/<span\s+class="comment"\s+data-comment="[^"]*">(.*?)<\/span>/gi, '$1');


    // COPY TO CLIPBOARD
    navigator.clipboard.writeText(`<h1>${headline}</h1>\n${cleaned_output}`);

    // SEND TOAST
    this.toast('notice', "HTML copied to clipboard.");
  }

  undo( event ){
    tinymce.activeEditor.undoManager.undo();
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  redo( event ){
    tinymce.activeEditor.undoManager.redo();
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  cut( event ){
    tinymce.activeEditor.execCommand("Cut");
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  copy( event ){
    tinymce.activeEditor.execCommand("Copy");
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  paste( event ){
    tinymce.activeEditor.execCommand("Paste");
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  selectAll( event ){
    tinymce.activeEditor.execCommand("SelectAll");
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  findAndReplace( event ){
    tinymce.activeEditor.execCommand('SearchReplace');
    if (isMobile()) {
      closeMobileMenus()
    }
  }

  fullscreen( event ){
    event.preventDefault();

    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.documentElement;
    if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
            docElm.requestFullscreen();
        } else if (docElm.mozRequestFullScreen) {
            docElm.mozRequestFullScreen();
        } else if (docElm.webkitRequestFullScreen) {
            docElm.webkitRequestFullScreen();
        } else if (docElm.msRequestFullscreen) {
            docElm.msRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
  }

  sidebarProxy(e){
    var sidebarTarget = $(e.currentTarget).data("sidebar-target");
    window.dispatchEvent(new CustomEvent(`sidebar:${sidebarTarget}`));
  }

  toggleSidebar(e){
    e.preventDefault();
    
    if ($(e.currentTarget).data("state") == true){
      // HIDE SIDEBAR
      window.dispatchEvent(new CustomEvent("sidebar:collapse"));
    } else {
      // SHOW SIDEBAR
      window.dispatchEvent(new CustomEvent("sidebar:expand"));
    }
  }

  toggleWordCount(e){
    e.preventDefault();

    if ($(e.currentTarget).data("state") == true){
      // UPDATE COOKIE & STATE
      Cookies.set('viewWordCount', false);
      $(e.currentTarget).data("state", false);
      $(e.currentTarget).children('.dynamic-check-holder').hide();

      // HIDE WORD COUNT
      $('.viewWordCount').hide();
    } else {
      // UPDATE COOKIE & STATE
      Cookies.set('viewWordCount', true);
      $(e.currentTarget).data("state", true);
      $(e.currentTarget).children('.dynamic-check-holder').show();

      // SHOW WORD COUNT
      $('.viewWordCount').show();

      // UPDATE INITIAL COUNT
      var wordcount = tinymce.activeEditor.plugins.wordcount;
      $('.viewWordCount span.totalWordCount').text(`${numberWithCommas(wordcount.body.getWordCount())} Words`);
    }

    if (isMobile()) {
      closeMobileMenus()
    }
  }

  toggleSpellCheck(e) {
    if ($(e.currentTarget).data("state") == true){
      // UPDATE COOKIE & STATE
      Cookies.set('enableSpellcheck', false);
      $(e.currentTarget).data("state", false);
      $(e.currentTarget).children('.dynamic-check-holder').hide();
    } else {
      // UPDATE COOKIE & STATE
      Cookies.set('enableSpellcheck', true);
      $(e.currentTarget).data("state", true);
      $(e.currentTarget).children('.dynamic-check-holder').show();
    }
  }

  toggleInternalLinking(e){
    e.preventDefault();

    if ($(e.currentTarget).data("state") == true){
      // UPDATE COOKIE & STATE
      Cookies.set('enableInternalLinking', false);
      $(e.currentTarget).data("state", false);
      $(e.currentTarget).children('.dynamic-check-holder').hide();
    } else {
      // UPDATE COOKIE & STATE
      Cookies.set('enableInternalLinking', true);
      $(e.currentTarget).data("state", true);
      $(e.currentTarget).children('.dynamic-check-holder').show();
    }
  }

  toggleAutocorrection(e){
    e.preventDefault();
    
    if ($(e.currentTarget).data("state") == true){
      // UPDATE COOKIE & STATE
      Cookies.set('enableAutocorrection', false);
      $(e.currentTarget).data("state", false);
      $(e.currentTarget).children('.dynamic-check-holder').hide();
    } else {
      // UPDATE COOKIE & STATE
      Cookies.set('enableAutocorrection', true);
      $(e.currentTarget).data("state", true);
      $(e.currentTarget).children('.dynamic-check-holder').show();
    }
  }

  // START HELPER FUNCTIONS
  
  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }

  request(url){
    // MAKE PARTIAL HTML REQUEST
    fetch(url, {
      headers: { accept: 'application/json' }})
    .then( data => {
      return data.json();
    }).then( payload => {
      $('#sidebar__container').html(payload.html);
    });
  }
}
