import CommentsAllController from "../comments/all_controller"
import {initializeTooltips} from "../../../utility_functions";
export default class extends CommentsAllController {

    connect(){
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--dropdown-list");

      // VALIDATE COMMENTS
      window.dispatchEvent(new CustomEvent("comments:validate"));

      // SET SIDEBAR STATE
      $('#sidebar__container').data("sidebar-state", "comments--all");
      $('.navSidebar .nav-link').removeClass("active");
      $('.nav-link#comments').addClass("active");

      // CLEAN TOOLTIPS THAT STICK
      $('.tooltip').remove();

      // SET ARTICLE ID
      this.idValue = $('.writer__container').data('article-id');

      // UPDATE TO LATEST DATA STORE
      this.shortcodes = $('#data__store #data__store_comments').data("value");

      // PREPARATION OF COMMENTS
      this.preparer();

      // COUNT COMMENTS
      this.setCounter();

      // SELECT A COMMENT
      this.selectCurrent();

      initializeTooltips()
    }
}
