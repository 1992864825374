import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import {showModal} from "../../utility_functions";
import {initializeTooltips} from "../../utility_functions";

export default class extends Controller {
  static targets = ['modal', 'modalContent']

  connect() {
    console.log("modal controller connected")

    // if modal has data-type="remote"
    // trigger modal show with modal id
    if (this.element.dataset.modalType === "remote") {
      showModal(this.element.id)
      let validettaOptions = {
        realTime: true,
        validators: {
          regExp: {
            valid_url: {
              pattern : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()@:%_\+.~#?&=/,-]*$/i,
              errorMessage: "Enter a URL that begins with http or https."
            }
          }
        },
        onError: function( event ){
          $($(event.currentTarget).find('button')).blur();
        }
      }
      $("form.validetta").validetta(validettaOptions);
    }
  }
  open() {
    this.modalTarget.classList.remove('hidden')
    // Force a reflow to ensure the transition happens
    this.modalContentTarget.getBoundingClientRect()
    this.modalContentTarget.classList.add('show')
    this.modalTarget.querySelector('.modal__backdrop').classList.add('show')

    // CLEAN TOOLTIPS
    initializeTooltips()
  }

  close() {
    // CLEAN TOOLTIPS
    initializeTooltips()
    
    this.modalContentTarget.classList.remove('show')
    this.modalTarget.querySelector('.modal__backdrop').classList.remove('show')
    const validetta = this.element.querySelector('.validetta-bubble')
    if (validetta) {
      validetta.remove();
    }
    setTimeout(() => {
      this.modalTarget.classList.add('hidden')

      if (this.element.id === "voiceTrainingModal") {
        Turbo.visit(window.location.href, {action: "replace"})
      }

    }, 200) // Match the duration of the transition (300ms)
  }
}