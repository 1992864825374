import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["radioOption"];

  connect(){
   // console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: ui--radio-group");

    // Iterate over all checkbox option targets
    this.radioOptionTargets.forEach((target) => {
      // Find the input within the target
      const input = target.querySelector('input[type="radio"]');

      // Check if the input is checked
      if (input && input.checked) {
        // Add 'checked' class to the label
        target.classList.add("checked");
      }
    });
  }

  onChange(e){
    //console.log("🔵 CLICKED: ui--cradio-group");

    // Remove 'checked' class from all checkbox options
    this.radioOptionTargets.forEach((target) => {
      target.classList.remove("checked");
    });

    // Add 'checked' class to the clicked radio button's label
    const clickedOption = e.target.closest(".largeCheckbox-label");
    if (clickedOption) {
      clickedOption.classList.add("checked");
    }
  }
}