import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {isMobile} from "../../../utility_functions";

export default class extends Controller {
  static get identifier() {
    return 'tinymce-controller';
  }
  static targets = ['listItem']


  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--language");
    this.language = $('.writer--language').data("language");
    this.mceLanguage = $('.writer--language li[data-country-code="'+ this.language +'"]').data("mce-language") ?? this.language;
    document.body.setAttribute('lang', this.language); // language set
    this.setLanguage();
    this.updateArticle();
  }

  change(e){
    this.language = $(e.currentTarget).parent().data('country-code');
    const mceLanguage = this.language ?? $(e.currentTarget).parent().data('mce-code');
    this.mceLanguage = mceLanguage;
    $('.tinymce').attr('lang', this.language);
    this.setLanguage();
    this.updateArticle(this.language);

    setTimeout(() => {
      document.dispatchEvent(new Event('tinymce:reconnect'));
    }, 50);

    if (isMobile()) {
      // close the menu
      // navigation#closeCowriterSidebar
    }
  }

  setLanguage(){
    localStorage.removeItem('mce.spelling');
    // SET LANGUAGE GLOBAL
    // Target the element with the 'tinymce' controller
    const language = this.language
    const mceLanguage = this.mceLanguage

    if (this.language) {
      $('.writer--language').data("language", language);
    }
    if (this.mceLanguage) {
      $('.writer--language').data("mce-language", mceLanguage);
    }
    $('.writer--language ul li .dynamic-check-holder').hide();
    $(`.writer--language ul li[data-country-code='${language}'] .dynamic-check-holder`).show();
  }

  updateArticle(){
      fetch(`${window.articleId}/language.json?lang=${this.language}`, {
        headers: {accept: 'application/json'},
        method: "POST"
      })
      .then(data => {
        return data.json();
      }).then(payload => {
        // UPDATED LANGUAGE
      });
  }
}
