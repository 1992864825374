import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    'btnContent',
    'btnLoader'
  ]

  // PROVIDES A NICE SPINNER UI ON CLICK
  // HOW TO USE -
  // Add btnContent and btnLoader spans inside the button with
  //          the data-controller="ui--button-loader" attribute
  //          element with controller needs this action: 'click->ui--btn-loader#click'
  // content span has btnContent, loader span has btnLoader and class named btnLoader style="display:none"

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: ui--button-loader");

    // RESTORE DEFAULT STATE, USEFUL ON BACK
    this.blur();
  }

  click(e){


    setTimeout(() => {
      const errors = document.querySelectorAll(".validetta-bubble");
      console.log("errors: ", errors);
      if (errors.length === 0) {
        console.log("loaderTarget: ", this.btnLoaderTarget);
        console.log("btnContentTarget: ", this.btnContentTarget);

        // HIDE CONTENT
        $(this.btnContentTarget).css("opacity", 0);
        $(this.btnContentTarget).prop("disabled", true);
        // SHOW LOADER
        $(this.btnLoaderTarget).show();
      }
    }, 120)
  }

  blur(e){
    // SHOW CONTENT
    if (this.btnContentTarget) {
      $(this.btnContentTarget).css("opacity", 100);
      $(this.btnContentTarget).prop("disabled", false);
    }


    // HIDE LOADER
    if (this.btnLoaderTarget) $(this.btnLoaderTarget).hide();
  }
}
