import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import consumer from "channels/consumer";
import Cookies from "js-cookie"
import {
    focusPrompt,
    followView,
    getOffsetTopRelativeToContainer,
    getParentTagOfSelection,
    isMobile,
    removeAiCaret
} from "../../../utility_functions";
import Appsignal from "@appsignal/javascript";

export const appsignal = new Appsignal({
  key: "87a297e7-d14c-448a-9b14-4729a31a21d9",
});

export default class extends Controller {
    static targets = [
        'input',
        'container',
        'command',
        'form'
    ]
    static values = {
        id: Number
    }

    setupCommands() {
        const buttons  = $(".command__btn");
        if (buttons.length > 0) {
            $(buttons[0]).addClass("selected");
        }
    }

    connect() {
        console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: command")
        this.element[this.identifier] = this

        var that = this
        const editor = tinymce.activeEditor

        // RECENTS TRACKING
        this.insertHistory = JSON.parse(localStorage.getItem(`insertHistory`)) || [];
        this.editHistory = JSON.parse(localStorage.getItem(`editHistory`)) || [];

        // BIND SCROLL EVENT
        this.element.addEventListener("scroll", this.handleScroll.bind(this))

        $(this.inputTarget).keydown(function(event) {
            // Check if the Escape key is pressed (key code 27)
            if (event.keyCode === 27) {
                // Call the function to close the element
                const prompt = document.querySelector('.inline__command-prompt');
                if (prompt.classList.contains('open')) {
                    const customSelection = editor.dom.select('.emph__selection');
                    // if customSelection still exists it means that we are not hiding from execute
                    if (customSelection) {
                        const selection = customSelection[0];
                        selection.classList.remove('emph__selection')
                        window.dispatchEvent(new CustomEvent("tinymce:sanitize"));
                    }
                    $('.inline__command-prompt').removeClass('open');
                }

                that.hide();
            }
        });

        // FOCUS ON COMMAND INPUT
        $(this.inputTarget).focus({ preventScroll: true });

    }

    handleScroll(event) {
        this.hide()
    }

    show() {
        $(".inline__command-prompt").addClass("open");
    }

    hide() {
        const customSelection = tinymce.activeEditor.dom.select('.emph__selection')
        // if customSelection still exists it means that we are not hiding from execute
        console.log("customSelection: ", customSelection)
        if (customSelection.length) {
            //console.log("customSelection: ", customSelection)
            if (customSelection.length) {
                customSelection.forEach(selection => {
                    selection.classList.remove('emph__selection')
                })
            }
        }
        // add onEditor focus to remove open from prompt container
        $(".inline__command-prompt").removeClass("open")
        $("#inlinecommandInput").val("")
    }

    enterSubmit(event) {
        // this already checks if input empty or not
        if (event.which === 13 && !event.shiftKey) {
            event.preventDefault();

            if (($(this.inputTarget).val().length) > 0) {
                this.execute(event);
            } else {
                $('.command__btn.selected').click();
            }
        }
    }

    hideEditorImperfections() {
        function checkIfSelectionPartial() {
            const editor = tinymce.activeEditor;
            const selection = editor.selection.getRng();
            const startContainer = selection.startContainer;
            const endContainer = selection.endContainer;
            const startBlock = editor.dom.getParent(startContainer, editor.dom.isBlock);
            const endBlock = editor.dom.getParent(endContainer, editor.dom.isBlock);

            return startBlock !== endBlock;
        }

        function checkIfSelectionInsideSinglePTag() {
            const editor = tinymce.activeEditor;
            const selection = editor.selection.getRng();
            const startContainer = selection.startContainer;
            const endContainer = selection.endContainer;
            const startP = editor.dom.getParent(startContainer, 'p');
            const endP = editor.dom.getParent(endContainer, 'p');

            return startP && endP && startP === endP;
        }

        const isPartialSelection = checkIfSelectionPartial()
        const isSmallSelection = checkIfSelectionInsideSinglePTag()
        const selectedContent = tinymce.activeEditor.selection.getContent();
        const moreThanOneP = /<\s*p[^>]*>(.*?)<\s*\/\s*p>/ig; // This will match <p> tags case-insensitively and allow for whitespace
        const hasList = /<\s*ul[^>]*>(.*?)<\s*\/\s*ul>/ig
        const hasOList = /<\s*ol[^>]*>(.*?)<\s*\/\s*ol>/ig
        const matchesList = (selectedContent.match(hasList) || []).length
        const matchesOlist = (selectedContent.match(hasOList) || []).length
        const isList = (matchesList => 2) || (matchesOlist => 2)
        const isLargeSelection = (selectedContent.match(moreThanOneP) || []).length

        if (selectedContent && !isPartialSelection &&
            !isSmallSelection &&
            (isLargeSelection || isList)) {
            const selection = tinymce.activeEditor.selection.getSel();
            let range = selection.getRangeAt(0)

            const contentClone = range.cloneContents()
            const newDiv = document.createElement('div')
            newDiv.classList.add('emph__selection')
            newDiv.appendChild(contentClone)

            range.deleteContents()
            range.insertNode(newDiv)

            selection.removeAllRanges()
            const newRange = document.createRange()
            newRange.selectNode(newDiv)
            selection.addRange(newRange)

        } else if (isPartialSelection) {
            const editor = tinymce.activeEditor
            let bookmark = editor.selection.getBookmark(2, true)

            const isContainer = node => node.nodeType === Node.ELEMENT_NODE && ['DIV', 'P', 'SECTION', 'ARTICLE', 'H1', 'H2', 'H3', 'H4', 'UL', 'OL', 'BLOCKQUOTE', 'TABLE'].includes(node.nodeName)
            const findNearestContainer = node => {
                while (node && !isContainer(node)) {
                    node = node.parentNode
                }
                return node
            }

            const addClassToRange = (range, className) => {
                const ancestorContainer = range.commonAncestorContainer;

                const filter = node => {
                    if (node.nodeType === Node.ELEMENT_NODE && node.textContent.trim !== '') {
                        return NodeFilter.FILTER_ACCEPT
                    } else {
                        return NodeFilter.FILTER_REJECT
                    }
                }
                const treeWalker = document.createTreeWalker(
                    ancestorContainer,
                    NodeFilter.SHOW_ELEMENT,
                    filter
                )

                let node = treeWalker.nextNode()

                while (node) {
                    const currentNode = node
                    //console.log("currentNode: ", currentNode)
                    if (currentNode && !currentNode.classList.contains('tinymce') &&
                        range.intersectsNode(currentNode) && currentNode.textContent.trim() !== ''
                    ) {
                        currentNode.classList.add(className)
                    }
                    node = treeWalker.nextNode()
                }
            }

            if (editor.selection.getSel().rangeCount > 0) {
                const range = editor.selection.getSel().getRangeAt(0)
                addClassToRange(range, 'emph__selection')
                const startContainer = findNearestContainer(range.startContainer);
                const endContainer = findNearestContainer(range.endContainer)
                var newRange = document.createRange();
                newRange.setStartBefore(startContainer)
                newRange.setEndAfter(endContainer)
                editor.selection.getSel().removeAllRanges()
                editor.selection.getSel().addRange(newRange)

                bookmark = editor.selection.getBookmark(2, true)

            }

            editor.selection.moveToBookmark(bookmark)

        } else {
            const wrappedContent = '<span class="emph__selection">' + selectedContent + '</span>';
            tinymce.activeEditor.selection.setContent(wrappedContent);
        }

        const bookmarks = tinymce.activeEditor.dom.select('span[data-mce-type="bookmark"]')
        if (bookmarks.length) {
            bookmarks.forEach(mark => mark.remove())
        }

        const allLis = document.querySelectorAll('.tinymce li')
        allLis.forEach(listItem => {
            if (listItem.textContent.trim() === '') { // it's not empty... and the select doesn't select it...
                listItem.classList.add("hideThis")
            }
        })

        const allULs = document.querySelectorAll('.tinymce ul')
        const allOLs = document.querySelectorAll('.tinymce ol')
        const lists = [...allULs, ...allOLs]
        lists.forEach(list => {
            if (list.textContent.trim() === '') { // it's not empty... and the select doesn't select it...
                list.classList.add("hideThis")
            }
        })
    }

    command() {
        // DISPLAY COMMAND UI
        const editor = tinymce.activeEditor
        const selection = editor.selection

        // CLEAN UP TOOLTIPS
        const clearTooltips = this.clearTooltips
        clearTooltips();

        // SET VARS
        this.contextObject = this.findSelection(editor.selection);
        this.structure = this.getStructure();
        this.mode = (this.contextObject["selectionContext"] != false) ? 'edit' : 'insert'

        if (selection) {
            const fiveVWInPixels = window.innerWidth * 0.05;
            const selectionRect = selection.getBoundingClientRect()

            const before = $("span.before");
            const after = $("span.after");
            const tmceElement = $('.tinymce')
            const leftOffset = Math.ceil(tmceElement.width() * 0.05)
            const left = (selectionRect.left + selectionRect.width/2) - leftOffset - 7

            let top = selectionRect.left < fiveVWInPixels && selectionRect.width > 100 ?
                `${selectionRect.top + selectionRect.height - 26}px` :
                `${selectionRect.top - 6}px`;

            const topViewportOffset = 200
            top = top < topViewportOffset ? topViewportOffset : top
            const promptWidth = 580

            let promptLeft = selectionRect.right > promptWidth && selectionRect.left > leftOffset
              ? selectionRect.right - promptWidth
              : leftOffset;

            if (selectionRect.left < fiveVWInPixels && selectionRect.width < fiveVWInPixels) {
                before.css("left", '25px')
                after.css("left", '25px')
            } else if (promptLeft > leftOffset) {
                let adjustOnEnd = 0;

                if (selectionRect.width === 1) {
                    promptLeft += 30
                    adjustOnEnd = 25
                }

                const beforeValue = `${selectionRect.width/2 - 6 + adjustOnEnd}px`;
                const afterValue = `${selectionRect.width/2 - 7 + adjustOnEnd}px`

                const newCSSbefore = {
                    "left": "unset",
                    "right": beforeValue,
                }
                const newCSSafter = {
                    "left": "unset",
                    "right": afterValue,
                }

                before.css(newCSSbefore)
                after.css(newCSSafter)
            } else {
                if (left > 15) {
                    before.css("left", left)
                    after.css("left", left)
                } else {
                    before.css("left", 17)
                    after.css("left", 17)
                }
            }

            this.hideEditorImperfections()

            // show and position command component
            const prompt = $(".inline__command-prompt");
            const container = $('.editor__container');
            const viewportHeight = $(window).height();

            const promptHeight = window.innerWidth > 768 ? 444 : prompt.outerHeight();

            console.log("top: ", top)
            let promptTop = parseInt(top);

            const topBarHeight = 100;

            const windowHeight = window.innerHeight;
            const magicNumber = windowHeight < 800 ? 16 : 92;

            const isPromptFullyVisible = (promptHeight + promptTop + 92) < viewportHeight;


            if (isMobile()) {
                const selection = editor.selection.getRng();
                const editorContainer = $('.tinymce-wrapper');

                const selectionBottom = selection.getBoundingClientRect().bottom;

                const difference = promptTop - topBarHeight;
                promptTop = topBarHeight + 66; // this is buffer from navbar
                let scrollAmount = container.scrollTop() + difference - 66;

                const selectionElement = $('.emph__selection')

                if (selectionElement.length > 1) {
                    const editorTopRelativeToContainer = editorContainer.position().top;

                    // Calculate the position to scroll to relative to the container
                    const scrollToPositionRelativeToContainer = selectionBottom - editorTopRelativeToContainer - 24;
                    container.animate({
                        scrollTop: scrollToPositionRelativeToContainer,
                    }, 200);

                } else if (selectionElement.length === 1) {
                    // this is needed because selectionBottom does not exist or is wrong
                    // in some of the cases where only one selectionElement
                    const editorTopRelativeToContainer = editorContainer.position().top;
                    const selectionBottomEdge = selectionElement[0].getBoundingClientRect().bottom
                    // Calculate the position to scroll to relative to the container
                    const scrollToPositionRelativeToContainer = selectionBottomEdge - editorTopRelativeToContainer - 24;

                    container.animate({
                        scrollTop: scrollToPositionRelativeToContainer,
                    }, 200);

                } else {
                    container.animate({
                        scrollTop: scrollAmount,
                    }, 200);
                }

                setTimeout(() => {
                    prompt.css({
                        top: promptTop,
                        left: "4vw"
                    });
                    $('.tox-pop').hide();
                }, 200)

            } else if (isPromptFullyVisible) {
                const markedSelection = document.querySelector('.emph__selection') // exists even if selection is empty
                const markedSelectionRect = markedSelection.getBoundingClientRect()

                if (markedSelection && markedSelectionRect.width > 0) {
                    // Get the bounding client rectangle of the marked selection
                    const rectMarked = markedSelection.getBoundingClientRect();

                    // this is only for top buffer
                    const bufferArea = (window.innerHeight / 100) * 26 // 26vh is good approximation of sketch

                    const shouldScrollBack = (rectMarked.top + rectMarked.height) < bufferArea;


                    if (shouldScrollBack) {
                        // IMPORTANT PROBLEM EMPH__SELECTION HANGS A LOT INSIDE
                        const adjustPrompt = parseInt(top);

                        const bufferAdjustment =
                          ((rectMarked.top + 20 < bufferArea && rectMarked.top > 0) && rectMarked.height < 32) ?
                            rectMarked.bottom + 32 : rectMarked.top + 20;
                        container.animate({
                            scrollTop: container.scrollTop() - bufferAdjustment
                        }, 200);

                        prompt.css({
                            top: `${adjustPrompt + bufferAdjustment}px`,
                            left: promptLeft + 'px'
                        });
                    } else {
                        $(this.containerTarget).css({
                            top: top,
                            left: promptLeft + 'px'
                        });
                    }
                } else {
                    $(this.containerTarget).css({
                        top: top,
                        left: promptLeft + 'px'
                    });
                }

                // $('.tox-pop').hide(); // probably hides teh tinymce small menu inline
            } else {
                const targetScrollTop = magicNumber + promptTop + promptHeight - viewportHeight;

                const scrollAmount = container.scrollTop() + targetScrollTop;
                if (scrollAmount <= container.height) {

                    container.animate({
                        scrollTop: scrollAmount
                    }, 200);

                    setTimeout(() => {
                        const topOffsetPrompt = promptTop - targetScrollTop;
                        prompt.css({
                            top: topOffsetPrompt,
                            left: promptLeft + 'px'
                        });
                    }, 300)

                } else {
                    const bottomBuffer = (window.innerHeight / 100) * 13
                    const topOffsetPrompt = promptTop - targetScrollTop // - bottomBuffer;

                    if (topOffsetPrompt + bottomBuffer + promptHeight > window.innerHeight) {
                        container.animate({
                            scrollTop: scrollAmount + bottomBuffer
                        }, 200);

                        setTimeout(() => {

                            prompt.css({
                                top: topOffsetPrompt - bottomBuffer + 'px',
                                left: promptLeft + 'px'
                            });
                        }, 300)
                    } else {
                        container.animate({
                            scrollTop: scrollAmount
                        }, 200);

                        setTimeout(() => {
                            prompt.css({
                                top: topOffsetPrompt + 'px',
                                left: promptLeft + 'px'
                            });
                        }, 300)
                    }
                }
            }

            window.settingUIData = true;
            setTimeout(() => {
                // window.promptUIData is used in tinymce_controller.js to keep the prompt in place
                const editorContainer = document.querySelector('.editor__container')
                const commandPrompt = document.querySelector('.inline__command-prompt')
                const commandPromptOffsetTop = getOffsetTopRelativeToContainer(commandPrompt, editorContainer)
                const initialPromptTop = commandPromptOffsetTop;
                const initialEditorScroll = editorContainer.scrollTop
                window.promptUIData = { initialPromptTop, initialEditorScroll }
                window.settingUIData = false;
            }, 500)

        }
        this.loadPresets()
        this.loadHistory()

        setTimeout(() => {
            this.show()
            this.setupCommands();
            focusPrompt(); // this is needed in both places i dunno what the error is
        }, 200)
    }

    loadPresets(){
        const presetCommands = {
            edit: [
                "Rephrase in my voice",
                "Simplify the language",
                "Fix spelling and grammar",
                "Insert a link placement",
                "Continue writing this section"
            ],
            insert: [
                "Continue writing this section",
                "Start writing a new section",
                "Insert a relevant table of data",
                "Write an engaging introduction",
                "Add a list of relevant facts"
            ]
        }

        // Get the ul element
        const presetCommandsList = document.querySelector('.inline__quickCommands');
        presetCommandsList.innerHTML = '';

        // SET PLACEHOLDER
        if (this.contextObject["selectionContext"] != false) {
            // PLACEHOLDER FOR EDITING
            $('#inlinecommandInput').attr("placeholder", "What changes would you like to make?");
            // Where Are we using the selection context?
            // Iterate through commandHistory and create list items
            presetCommands.edit.forEach(function(command, index) {
                const listItem = document.createElement('li');
                const button = document.createElement('button');
                button.classList.add("command__btn")
                button.setAttribute('data-writer--commands-target', 'command');
                button.dataset.action = 'click->ui--command-dropdown#quickCommand';
                button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="#FF6702" viewBox="0 0 24 24" fill="currentColor"><path d="M13 9H21L11 24V15H4L13 0V9ZM11 11V7.22063L7.53238 13H13V17.3944L17.263 11H11Z"></path></svg> <span class="commandWrap">${command}</span>`;

                listItem.appendChild(button);
                presetCommandsList.appendChild(listItem);
            });
        } else {
            // PLACEHOLDER FOR CREATING
            $('#inlinecommandInput').attr("placeholder", "What would you like to write next?");
            // Iterate through commandHistory and create list items
            presetCommands.insert.forEach(function(command, index) {
                const listItem = document.createElement('li');
                const button = document.createElement('button');
                button.classList.add("command__btn")
                button.setAttribute('data-writer--commands-target', 'command');
                button.dataset.action = 'click->ui--command-dropdown#quickCommand';
                button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 24 24" fill="currentColor"><path d="M13 9H21L11 24V15H4L13 0V9ZM11 11V7.22063L7.53238 13H13V17.3944L17.263 11H11Z" fill="#FF6702"></path></svg> <span class="commandWrap">${command}</span>`;

                listItem.appendChild(button);
                presetCommandsList.appendChild(listItem);
            });
        }
    }

    loadHistory(){
        // Get the ul element
        const recentCommandsList = document.querySelector('.inline__recents');
        recentCommandsList.innerHTML = '';

        if(this.mode == "insert"){
            if (this.insertHistory.length > 0) {
                
                // ITERATE THROUGH STORE OBJECT
                this.insertHistory.forEach(function(command) {
                    //console.log(command);
                    var listItem = document.createElement('li');
                    var button = document.createElement('button');
                    button.classList.add("command__btn")
                    button.dataset.action = 'click->ui--command-dropdown#quickCommand';
                    button.setAttribute('data-writer--commands-target', 'command');
                    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="#FF6702" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path></svg> <span class="commandWrap"> ${command}</span>`;
                    listItem.appendChild(button);
                    recentCommandsList.appendChild(listItem);
                });

                $('.inline__recents-group').show()
            } else {
                $('.inline__recents-group').hide()
            }
        } else {
            console.log(`EDIT HISTORY: ${this.editHistory}`);
            if (this.editHistory.length > 0) {

                // ITERATE THROUGH STORE OBJECT
                this.editHistory.forEach(function(command) {
                    var listItem = document.createElement('li');
                    var button = document.createElement('button');
                    button.classList.add("command__btn")
                    button.dataset.action = 'click->ui--command-dropdown#quickCommand';
                    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" fill="#FF6702" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM13 12H17V14H11V7H13V12Z"></path></svg> <span class="commandWrap"> ${command}</span>`;
                    button.setAttribute('data-writer--commands-target', 'command');
                    listItem.appendChild(button);
                    recentCommandsList.appendChild(listItem);
                });

                $('.inline__recents-group').show()
            } else {
                $('.inline__recents-group').hide()
            }
        }
    }

    storeToHistory(command){
        if(command.length > 0 && command != " "){
            if(this.mode == "insert"){
                if (!this.insertHistory.includes(command)) {
                    // INSERT ITEM AT START OF ARRAY
                    this.insertHistory.unshift(command);

                    // LIMIT TO 5 VALUES
                    this.insertHistory = this.insertHistory.slice(0, 5);

                    // SYNC TO LOCAL STORAGE
                    localStorage.setItem(`${this.mode}History`, JSON.stringify(this.insertHistory));
                }
            } else {
                if (!this.editHistory.includes(command)) {
                    // INSERT ITEM AT START OF ARRAY
                    this.editHistory.unshift(command);

                    // LIMIT TO 5 VALUES
                    this.editHistory = this.editHistory.slice(0, 5);

                    // SYNC TO LOCAL STORAGE
                    localStorage.setItem(`${this.mode}History`, JSON.stringify(this.editHistory));
                }
            }
        }
    }

    execute(event) {
        event.preventDefault();

        // this only runs in case selection spans above viewport
        let selectionTmce = tinymce.activeEditor.selection.getBoundingClientRect();

        if (selectionTmce && selectionTmce.top < 10) {
            const offsetFromTopOfScreen = 160
            const container = $('.editor__container')
            const currentScrollTop = container.scrollTop();
            const decreaseAmount = 10 - selectionTmce.top; // Calculate the amount to decrease
            container.scrollTop(currentScrollTop - decreaseAmount - offsetFromTopOfScreen); // Decrease scrollTop
        }

        const loader = $('#askCommandForm .orangeBtn .btn__loader');

        // SET TO LOADING STATE
        loader.css('display', 'block');
        $('#askCommandForm textarea').prop('disabled', true);

        const customSelection = tinymce.activeEditor.dom.select('.emph__selection')

        // METHODS/VARS
        const editor = tinymce.activeEditor
        const toast = this.toast
        const aiUnlock = this.aiUnlock
        const aiLock = this.aiLock
        const insertGeneration = this.insertGeneration
        const bookmark = editor.selection.getBookmark()
        const command = $(this.inputTarget).val()

        this.storeToHistory(command)

        tinymce.activeEditor.execCommand('mceSpellcheckDisable');
        fetch(`${window.articleId}/command.json`, {
                headers: {
                    accept: 'application/json'
                },
                body: JSON.stringify({
                    contextObject: this.contextObject,
                    structure: this.structure,
                    command: command
                }),
                method: "POST"
            })
            .then(data => {
                // AI LOCK
                aiLock();

                return data.json();
            })
            .then(payload => {
                // HIDE PROMPT BOX
                this.hide();
                loader.css('display', 'none');

                // INIT VARS
                var buffer = "";
                var tagContext = false;

                // ENSURE SELECTION IS BOOKMARK
                // PREVENTS SELECTION CHANGE
                editor.selection.moveToBookmark(bookmark);

                // OPEN SOCKET
                consumer.subscriptions.create({
                    channel: "GenerationChannel",
                    id: payload.id,
                }, {
                    received: function(data) {
                        //console.log(data);
                        if ($('#tinymce').data("typer") === "ai") {
                            if (data === "[!START!]") {
                                // REMOVE EXISTING
                                editor.selection.setContent("");

                            } else if (data === "[!END!]") {
                                // UNLOCK AI
                                const cursorLoaderLarge = $('#customCursorLoaderLarge')
                                if (cursorLoaderLarge.hasClass('show')) {
                                    $('body').removeClass('showingLargeLoader')
                                    cursorLoaderLarge.removeClass('show')
                                }
                                aiUnlock();
                            } else {

                                // APPEND TO BUFFER
                                buffer += data;

                                // SWITCH TO INSERT FUNCTION
                                var insert = insertGeneration(tagContext, buffer, data, window.keyPhrases);
                                tagContext = insert[0]
                                buffer = insert[1]
                            }
                        } else {
                            // ABORT EARLY
                            this.unsubscribe();
                        }
                    }
                })

            })
            .catch(error => {
                // CATCH ERROR
                console.error(error);
                aiUnlock();
                toast("alert", "Our AI is over capacity. Please try again.");
            });
        $('#askCommandForm textarea').prop('disabled', false);
        // clean up textarea
        $("#inlinecommandInput").val("")
        // if customSelection still exists, we need to remove it here after running execute
        if (customSelection) tinymce.activeEditor.dom.remove(customSelection)
    }

    insertGeneration(tagContext, buffer, data, keyPhrases) {
        // ERROR RESCUING
        try {
            window.insertingGeneration = true;
            // NEED TO PROGRAM A BUFFER THAT ENCAPTULATES WORDS AND FULL TAGS
            // SET EDITOR
            var editor = tinymce.activeEditor;

            // Remove ignored strings from buffer
            const ignoredStrings = ["<section>", "</section>", "<div>", "</div>", "```html"];
            const ignoredRegex = new RegExp(ignoredStrings.join('|'), 'g');
            // Check if there are any matches
            if (ignoredRegex.test(buffer)) {
                buffer = buffer.replace(ignoredRegex, '');
            }

            // DETECT PARTIAL TAGS
            if (buffer.includes("<") || buffer.includes(">")) {
                let count = 0;
                for (let i = 0; i < buffer.length; i++) {
                    if (buffer[i] === '<') {
                        count++;
                    } else if (buffer[i] === '>') {
                        count--;
                    }
                    if (count < 0) {
                        return false;
                    }
                }
                var partialTags = !(count === 0)

                var incompleteTags = !(/<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/i.test(buffer));
            } else {
                var partialTags = false;
                var incompleteTags = false;
            }

            if (['table', 'ul', 'ol', 'blockquote'].includes(tagContext)) {
                const editor = tinymce.activeEditor;
                const bookmark = editor.selection.getBookmark(0); // Save the current cursor position
                const markerHtml = '<span id="cursor-position-marker">&#8203;</span>'; // Zero-width space for marker
                editor.selection.setContent(markerHtml); // Insert temporary marker
                const marker = editor.contentDocument.getElementById('cursor-position-marker');

                if (marker) {
                    const wrapper = $('.writer__container');
                    const wrapperOffset = wrapper.offset();
                    const markerOffset = $(marker).offset();

                    // Calculate position relative to the wrapper
                    let top, left;

                    top = markerOffset.top - wrapperOffset.top - 5
                    left = markerOffset.left - wrapperOffset.left - 6

                    // fix edge case when on start of container
                    const container = document.querySelector('.tinymce');
                    const containerStyle = window.getComputedStyle(container);
                    const paddingRight = containerStyle.paddingRight;
                    left = left < parseInt(paddingRight) ? left + 5 : left;

                    function findRelevantParent(element) {
                        let currentElement = element;
                        // Loop until you find a relevant parent or there are no more parents
                        while (currentElement && currentElement.tagName !== 'BODY') {
                            if (['H2', 'H3', 'H4', 'P'].includes(currentElement.tagName)) {
                                return currentElement; // Return the found relevant parent
                            }
                            currentElement = currentElement.parentElement; // Move up to the next parent
                        }
                        return null; // Return null if no relevant parent is found
                    }

                    const parentElement = marker.parentElement;
                    const relevantParent = findRelevantParent(parentElement);

                    const cursorLoaderLarge = $('#customCursorLoaderLarge')
                    if (relevantParent) {
                        if (relevantParent.tagName === 'H2') {
                            cursorLoaderLarge.removeClass('size-p', 'size-4', 'size-3')
                            cursorLoaderLarge.addClass('size-2')
                        } else if (relevantParent.tagName === 'H3') {
                            cursorLoaderLarge.removeClass('size-p', 'size-4', 'size-2')
                            cursorLoaderLarge.addClass('size-3')
                        } else if (relevantParent.tagName === 'H4') {
                            cursorLoaderLarge.removeClass('size-p', 'size-2', 'size-3')
                            cursorLoaderLarge.addClass('size-4')
                        } else if (relevantParent.tagName === 'P') {
                            cursorLoaderLarge.removeClass('size-2', 'size-4', 'size-3')
                            cursorLoaderLarge.addClass('size-p')

                        }
                    }

                    // Position the cursorLoader
                    cursorLoaderLarge.css({
                        'top': top + 'px',
                        'left': left + 'px',
                    });
                    // Clean up: remove the marker and restore the cursor position
                    editor.dom.remove(marker); // Remove the temporary marker
                    editor.selection.moveToBookmark(bookmark); // Restore the cursor position
                    $('body').addClass('showingLargeLoader')
                    cursorLoaderLarge.show()
                }
            }

            // Capture the pre-insertion height of the '.tinymce-wrapper' element
            const editorContainer = document.querySelector('.editor__container');
            const tinymceEditorElement = document.querySelector('.tinymce');
            var initialEditorHeight = tinymceEditorElement.scrollHeight;

            if (tagContext && buffer && buffer.includes(`</${tagContext}>`)) {
                console.log(`END OF CONTENT: ${buffer} \n TAG CONTEXT: ${tagContext}`);
                const editorToFollow = tinymce.activeEditor
                followView(editorToFollow);
                removeAiCaret(editor);
                // INSERT FINAL
                if (tagContext === 'p') {
                    editor.insertContent(buffer.replace(`</${tagContext}>`, ""));
                    var node = editor.selection.getNode();

                    // PLACE INTERNAL LINKS
                    if (Cookies.get('enableInternalLinking') != "false") {
                        var nodeHTML = node.outerHTML;
                        var editorHTML = editor.getContent({
                            format: 'html'
                        });
                        var match = false;
                        $.each(window.keyPhrases, function (key, value) {
                            const regex = new RegExp('(\\b\\w*' + key.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&') + '\\w*\\b)(?![^<]*>)', 'i');
                            var anchor = nodeHTML.match(regex);

                            if (anchor != null) {
                                // CHECK FOR PREXISTING
                                var includesLink = editorHTML.includes(value);
                                var includesAnchor = editorHTML.includes(`>${anchor[0]}</a>`);

                                if (!includesLink && !includesAnchor) {
                                    nodeHTML = nodeHTML.replace(regex, '<a href="' + value + '">' + anchor[0] + '</a>');

                                    // UPDATE CONTENT
                                    $(node).remove();
                                    editor.insertContent(nodeHTML);

                                    // EXIT EARLY
                                    match = true;
                                }
                            }

                            // STOP AT 1 LINK
                            if (match) {
                                console.log('match, end early');
                                window.insertingGeneration = false;
                                return false;
                            }
                        });
                    }
                } else if (jQuery.inArray(tagContext, ["ul", "dl", "ol", 'blockquote', 'table']) !== -1) {
                    if ($('#tinymce').data("typer") === "ai") {
                        editor.insertContent(buffer.replace(`</${tagContext}>`, `</${tagContext}><br/>`));
                    }
                } else {
                    if ($('#tinymce').data("typer") === "ai") {
                        removeAiCaret(editor)
                        editor.insertContent(`${buffer}`);
                    }
                }

                // this is scroll down on adding those elements
                if (tagContext && jQuery.inArray(tagContext, ["ul", "dl", "ol", "blockquote", "table"])) {

                    const cursorLoaderLarge = $('#customCursorLoaderLarge')
                    $('body').removeClass('showingLargeLoader')
                    cursorLoaderLarge.hide()
                    cursorLoaderLarge.removeClass('size-2', 'size-3', 'size-4');

                    removeAiCaret(editor)
                    editor.once('NodeChange', (event) => {
                        const newHeight = tinymceEditorElement.scrollHeight;
                        const heightDifference = newHeight - initialEditorHeight;

                        if (heightDifference > 0 && !isMobile()) {
                            const contentWindow = document.querySelector('.editor__container');
                            const currentScrollTop = contentWindow.scrollTop;

                            let top = currentScrollTop + heightDifference

                            setTimeout(() => {
                                contentWindow.scrollTo({
                                    top: top,
                                    behavior: 'smooth',
                                });
                            }, 20)

                        } else if (heightDifference > 0 && isMobile()) {
                            const contentWindow = document.querySelector('.editor__container');
                            const currentScrollTop = contentWindow.scrollTop;
                            //const approximateKeyboardHeight = window.innerHeight * 0.25;
                            const top = (currentScrollTop + heightDifference)//) - approximateKeyboardHeight

                            contentWindow.scrollTo({
                                top: top,
                                behavior: 'smooth',
                            });
                        } else {
                           // console.log("noHeightDifference ? - probably when inline node added")
                        }
                    });
                }

                // RESET
                tagContext = false;
                buffer = "";

            } else if (partialTags == false && incompleteTags == false && buffer.includes(" ")) {
                if (jQuery.inArray(tagContext, ["p", "h2", "h3", "h4"]) !== -1) {
                    console.log(`MID CONTENT: ${buffer} \n TAG CONTEXT: ${tagContext}`);

                    if (tagContext !== 'p') {
                        const editor = tinymce.activeEditor;
                        removeAiCaret(editor);

                        const parentTag = getParentTagOfSelection(editor);
                        let loaderClass = ''; // Initialize spinnerClass variable
                        // Use switch statement to assign the appropriate class based on tagContext
                        switch (parentTag) {
                            case 'p':
                                loaderClass = 'size-p';
                                break;
                            case 'h2':
                                loaderClass = 'size-h2';
                                break;
                            case 'h3':
                                loaderClass = 'size-h3';
                                break;
                            case 'h4':
                                loaderClass = 'size-h4';
                                break;
                            default:
                                break; // No class is added for 'span' or any other unspecified tagContext
                        }

                        // HANDLE MID CONTENT
                        editor.insertContent(buffer);
                        const newSpinner = `<span class="customCursorLoader ${loaderClass}" contenteditable="false"><span class="loader__flag">Cowriter</span><span contenteditable="false" class="loader__verticalBar loader__pole"></span></span>`
                        tinymce.activeEditor.undoManager.ignore(() => {
                            tinymce.activeEditor.insertContent(newSpinner);
                        });
                        // RESET
                        buffer = "";
                    } else if (tagContext === 'p') {
                        const editor = tinymce.activeEditor;
                        removeAiCaret(editor);
                        editor.insertContent(buffer);
                        const newSpinner = `<span class="customCursorLoader size-p" contenteditable="false"><span class="loader__flag">Cowriter</span><span contenteditable="false" class="loader__verticalBar loader__pole"></span></span>`
                        editor.undoManager.ignore(() => {
                            editor.insertContent(newSpinner);
                        });
                        buffer = "";
                    }
                }
            } else if (/<[a-z0-9\-][^>]*>/i.test(buffer) && partialTags == false) {
                console.log(`START OF CONTENT: ${buffer} \n TAG CONTEXT: ${tagContext}`);
                // HANDLE START OF CONTENT
                if (tagContext == false) {
                    // PREVENTS PREMATURE OVERWRITING
                    tagContext = (/\<([a-z0-9\-]+)[^\>]*>/ig.exec(buffer))[1];
                }
                var tagsCount = buffer.match(/<([a-z][a-z0-9]*)\b[^>]*>/gi).length;

                if (tagContext && jQuery.inArray(tagContext, ["p", "span", "h2", "h3", "h4"]) !== -1 && tagsCount <= 1 && buffer.includes(`<${tagContext}>`)) {
                    const editor = tinymce.activeEditor;
                    editor.insertContent(buffer);
                    // RESET
                    buffer = "";
                }
            } else {
                console.log(`BUFFERING: ${buffer}`);
            }

            const editorToFollow = tinymce.activeEditor
            followView(editorToFollow);

            // CLEAN UP
            $('.tinymce p:empty').remove();

            return [tagContext, buffer];

        }
        catch (error) {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            appsignal.sendError(error, (span) => {
                span.setAction("command#insertGeneration");
                span.setNamespace("command");
                span.setTags({
                    tagContext: tagContext,
                    windowHeight: windowHeight,
                    windowWidth: windowWidth,
                    buffer: buffer,
                    data: data,
                    keyPhrases: keyPhrases
                });
            });

            return [null, null]
        }
    }

    getStructure() {
        var htmlContent = tinymce.activeEditor.getContent();
        var structure = '';
        var headings = htmlContent.match(/<(h[1-6])>(.*?)<\/\1>/g);

        if (headings) {
            headings.forEach(function(heading) {
                structure += heading + '\n';
            });
        }

        return structure;
    }

    findSelection(selection) {
        // FINDS CORRECT SELECTION SO THAT HTML IS INCLUDED
        // DESIGNED TO BE MULTI-PURPOSE

        // - CONTEXT -
        //
        // FALSE
        // INLINE
        // FOLLOWING

        // - SELECTION -
        //
        // FALSE
        // INLINE
        // ALL

        // OUTPUT IS AN OBJECT, IN THE FOLLOWING FORMAT
        var selectionObject = {
            parentElement: false,
            selectionElement: false,
            parentContext: false,
            selectionContext: false
        }

        var content = selection.getContent({
            format: 'html'
        });
        var offset = selection.getRng().startOffset;
        var node = selection.getNode();
        var current_element = node.outerHTML;
        var parent_element = $(node).parent()[0].outerHTML;
        var prev_element = $(node).prev()[0];

        if (content.length > 0) {
            // THERE IS A SELECTION
            selectionObject["selectionElement"] = content

            if (/<\/?[a-z][\s\S]*>/i.test(content)) {
                // IT'S ALREADY HTML (MULTI-SELECT)
                selectionObject["parentElement"] = content
                selectionObject["parentContext"] = false
                selectionObject["selectionContext"] = "all"
            } else {
                // IT'S NOT HTML (SINGLE-SELECT)
                var parent_tag_types = "p, h1, h2, h3, h4, h5, h6, blockquote, ul, li";

                // CHECK IF IT IS A TOP-LEVEL TAG
                if ($(node).is(parent_tag_types)) {
                    // IF IT IS, RETURN HTML
                    selectionObject["parentElement"] = current_element
                } else {
                    // IF IT IS NOT, GET PARENT OR RETURN AS A FALLBACK
                    if ($(node).parent().is(parent_tag_types)) {
                        selectionObject["parentElement"] = parent_element
                    } else {
                        selectionObject["parentElement"] = current_element
                    }
                }

                if (offset != 0) {
                    selectionObject["parentContext"] = "inline"
                    selectionObject["selectionContext"] = "inline"
                } else {
                    selectionObject["parentContext"] = false
                    selectionObject["selectionContext"] = "all"
                    selectionObject["selectionElement"] = current_element;
                }
            }
        } else {
            // THERE IS NOT A SELECTION

            if ($(current_element).text().length > 0) {
                // IS WITHIN AN ELEMENT
                selectionObject["parentElement"] = current_element
                selectionObject["parentContext"] = "inline"
            } else {
                if (prev_element == undefined) {
                    // NO PREVIOUS ELEMENT (INTRO)
                } else {
                    // PREVIOUS ELEMENT EXISTS
                    selectionObject["parentElement"] = prev_element.outerHTML
                    selectionObject["parentContext"] = "following"
                }
            }
        }

        // RETURN OBJECT
        return selectionObject;
    }

    aiLock() {
        tinymce.activeEditor.execCommand('mceSpellcheckDisable');
        // SET TYPER
        $('#tinymce').data("typer", "ai");
        $('body').addClass('generating')
        // LOCK EDITOR
        $('.editor__container').append("<div id='readonly' data-action='touchstart->tinymce#aiUnlock click->tinymce#aiUnlock keydown@window->writer--command#aiUnlock'></div>");
    }

    aiUnlock(event) {
        window.insertingGeneration = false;

        const editor = tinymce.activeEditor;
        removeAiCaret(editor);

        // SET TYPER
        $('#tinymce').data("typer", "human");

        // HIDE LARGE ELEMENT LOADER
        const largeLoader = $('#customCursorLoaderLarge')
        $('body').removeClass('showingLargeLoader generating');
        largeLoader.hide();
        largeLoader.removeClass('size-2', 'size-3', 'size-4');
        // SAVE EVENT
        window.dispatchEvent(new CustomEvent("tinymce:change"));

        // UNLOCK EDITOR
        $('#readonly').remove();
        tinymce.activeEditor.execCommand('mceSpellcheckEnable');
    }

    clearTooltips() {
        const hangedTooltips = document.querySelectorAll('.tooltip')
        if (hangedTooltips.length) {
            hangedTooltips.forEach((element) => {
                element.parentNode.removeChild(element);
            });
        }
    }

    toast(mode, message) {
        var imagePath = {
            'alert': '/alert-circle.svg',
            'notice': '/check-circle.svg'
        } [mode]
        var className = {
            'alert': 'text-bg-danger',
            'notice': 'text-bg-success'
        } [mode]

        $('.toast').remove();
        $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
        var toast = new bootstrap.Toast($('.toast'));
        toast.show();
    }
}