import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];


  connect() {
  }

  submitForm(event) {
    console.log("Submit search intent form")
    try {
      const inputValue = this.inputTarget.value.trim();

      if (inputValue !== "") {
        event.preventDefault();
        $("#new_article_intent button[type='submit']").click();
      }
    } catch (error) {
    }
  }
}
