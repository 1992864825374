import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect(){
    //
  }

  change(){
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.search, 1000);
  }

  search(){
    // FIREFOX PATCH
    // JQUERY SUBMIT() WILL NOT WORK ON FF
    const form = document.querySelector('form#search');
    const formData = new FormData(form);
    const fieldValue = formData.get('search[query]');

    const url = new URL(window.location.href)
    url.searchParams.set('query', fieldValue);

    Turbo.visit(url.href);
  }
}
