import { Controller } from "@hotwired/stimulus"
import { Subscriptions } from "@rails/actioncable";
import Rails from "@rails/ujs";
import consumer from "channels/consumer";
import {initializeTooltips} from "../../../utility_functions";


export default class extends Controller {

  static targets = []

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: articles--new");
  }

  newInstance(){
    // SHOW THE MODAL
    $('#addArticlesModal').modal('show');
  }
}
