import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = []

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: cello");

    if (typeof window.celloState === 'undefined') {
      this.bootCello();
    } else {
     this.shutdownCello();
     this.bootCello ();
    }
  }

  bootCello(){
    //VARIABLES
    const productId = this.data.get("productId");
    const token = this.data.get("token");
    const firstName = this.data.get("firstName");
    const lastName = this.data.get("lastName");
    const name = this.data.get("name");
    const email = this.data.get("email");
    //METHODS
    const refreshToken = this.refreshToken;

    window.cello = window.cello || { cmd: [] };
    window.cello.cmd.push(function (cello) {
      cello.boot({
        productId: productId,
        token: token,
        language: "en",
        hideDefaultLauncher: true,
        customLauncherSelector: '.celloLauncher',
        productUserDetails: {
          firstName: firstName,
          lastName: lastName,
          fullName: name,
          email: email
        }, 
        onTokenExpiring: ()=> {
          refreshToken();
        },
        onTokenExpired: ()=> {
          refreshToken();
        }
      });
    });

    window.celloState = true
  }

  shutdownCello(){
    console.log("SHUTING DOWN CELLO");
    window.Cello("shutdown")
  }

  click(){
    window.Cello("open")
  }

  refreshToken(){
    fetch(`/p/${window.projectShortcode}/jwt/cello.json`, {
      headers: { accept: 'application/json' },
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      console.log(payload);
      window.Cello("updateToken", payload.token);
    });
  }
}
