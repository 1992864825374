import {Controller} from "@hotwired/stimulus"
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = {
    pageUrl: String,
    retryUrl: String
  }

  retry() {
    this.disposeTooltip()

    post(this.retryUrlValue, {
      body: { url: this.pageUrlValue },
    })
  }

  disposeTooltip() {
    const tooltip = bootstrap.Tooltip.getInstance(this.element)

    if (tooltip) {
      tooltip.dispose()
    }
  }
}
