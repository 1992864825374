import { Controller } from "stimulus";
import {initializeValidetta} from "../../../utility_functions";

export default class extends Controller {
    connect() {
        this.element.style.overflowY = 'hidden'; // Optional: Prevents scrollbar flash
        this.resize();

        initializeValidetta()
    }

    resize() {
        this.element.style.height = this.element.scrollHeight + 'px';
    }
}