import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";


export default class extends Controller {

  static targets = [
    'headlinesBox',
  ]

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--plagiarism-audit");

    initializeTooltips()

    this.fetchPlagiarism();
  }

  fetchPlagiarism(event){
    if (event) {
      event.preventDefault();
    }

    // var paragraphContent = this.findParagraphs();

    const url = `/p/${window.projectShortcode}/sidebar/${window.articleId}/plagiarism_audit`
    fetch(url, {
      headers: {
        "Content-type": "application/json",
        accept: 'application/vnd.turbo.stream.html'
      },
      // body: JSON.stringify({paragraphs: paragraphContent}),
      method: 'POST'})
      .then (response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  findParagraphs(){
    var editor = tinymce.activeEditor;

    // Get the content from the editor as HTML
    var content = editor.getContent();

    // Create a temporary div element to parse the HTML content
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    // Get all <p> elements from the temporary div
    var pTags = tempDiv.getElementsByTagName('p');

    // Extract text content from each <p> tag and store it in an array
    var pTagsContent = [];
    for (var i = 0; i < pTags.length; i++) {
        pTagsContent.push(pTags[i].textContent);
    }

    // Log the array to the console (or use it as needed)
    return pTagsContent;
  }

  markComplete(event) {
    event.preventDefault()
    patch(event.params.url)
    const btn = $(event.target);
    //   btn.addClass('btn__complete')
    //   btn.prop('disabled', true)
      btn.tooltip('dispose')
  }
}

