import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.observer = new MutationObserver((mutationsList, observer) => {
      for(let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const addedNodes = Array.from(mutation.addedNodes);
          const removedNodes = Array.from(mutation.removedNodes);

          // Check if the modal was added
          const modalOpened = addedNodes.some(node => node.classList &&
            node.classList.contains('tam-assetmanager-root'));
          if (modalOpened) {
            // Find the .tam-assetmanager element
            const assetManager = document.querySelector('.tam-assetmanager');
            if (assetManager) {
              // Add click event listener to .tam-assetmanager
              assetManager.addEventListener('click', (event) => {
                // Check if the click is directly on .tam-assetmanager and not on .tam-assetmanager-container
                if (!event.target.closest('.tam-assetmanager-container')) {
                  // Find the close button within .tam-assetmanager
                  const closeButton = assetManager.querySelector('button[title="Close"]');
                  if (closeButton) {
                    closeButton.click(); // Trigger click on the close button
                  }
                }
              }, { once: false }); // Option to remove the listener after the first trigger
            }
          }

          // Check if the modal was removed
          const modalClosed = removedNodes.some(node => node.classList &&
            node.classList.contains('tam-assetmanager-root'));
          if (modalClosed) {
            //console.log('Modal closed');
            // Probably not needed...
          }
        }
      }
    });

    // Start observing
    this.observer.observe(document.body, { childList: true, subtree: false });
  }

  disconnect() {
    // Stop observing when the controller is disconnected
    this.observer.disconnect();
  }
}