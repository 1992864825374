import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["item", "inner"]

  connect() {
    console.log("connected")
  }

  closeAll() {
    this.innerTargets.forEach((inner, index) => {
      $(inner).slideUp();
      $(this.itemTargets[index]).removeClass('open');
    });
  }

  toggle(event) {
    this.closeAll();

    const itemElement = $(event.target).closest('.checklist__item');
    const innerContent = $(itemElement).find('.checklist__innerContent');

    if (!innerContent.is(":visible")) {
      innerContent.slideDown();
      itemElement.addClass('open');
    } else {
      innerContent.slideUp();
      itemElement.removeClass('open');
    }
  }
}