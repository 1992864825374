import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["searchScoreRing", "searchScoreText", "searchScoreOverlay", "searchScoreWrapper"];

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: ui--search-score");

    this.articleId = this.data.get("articleId")
    this.intentScores = this.data.get("intentScores")
    this.score = this.data.get("score")
    this.lastChecked = this.data.get("lastChecked")
    this.searchScorePayload;

    if (this.intentScores.length > 0 && this.lastChecked.length > 0){
      this.setSearchScore(this.score, this.intentScores)
    } else {
      this.getSearchScore()
    }
  }

  getSearchScore(){
    const setSearchScore = this.setSearchScore.bind(this);

    fetch(`articles/${this.articleId}/search_score.json`, {
      headers: { accept: 'application/json' },      
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      this.searchScorePayload = payload
      setSearchScore(this.searchScorePayload.score, Object.values(this.searchScorePayload.intents).join(","));
    });
  }

  setSearchScore(score, intentScores){
    var intentValues = intentScores.split(",")
    var scoreToHex = {
      0: "#C8102E",
      1: "#D7344E",
      2: "#F19C39",
      3: "#F1C139",
      4: "#339C68",
      5: "#228252"
    }

    // SET SCORE
    $(this.searchScoreTextTarget).text(score);

    // SET GRADIENT
    switch(intentValues.length){
      case 5:
        this.gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[4]]} 72deg, ${scoreToHex[intentValues[3]]} 144deg, ${scoreToHex[intentValues[2]]} 216deg, ${scoreToHex[intentValues[1]]} 288deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 4:
        this.gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[3]]} 90deg, ${scoreToHex[intentValues[2]]} 180deg, ${scoreToHex[intentValues[1]]} 270deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 3:
        this.gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[2]]} 120deg, ${scoreToHex[intentValues[1]]} 240deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 2:
        this.gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[1]]} 180deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 1:
        this.gradientCss = `${scoreToHex[intentValues[0]]}`
        break;
      default:
        this.gradientCss = '#EEEEEE'
    }

    // REMOVE LOADING
    $(this.searchScoreRingTarget).removeClass("loading")

    // UPDATE GRADIENT
    $(this.searchScoreRingTargets).css("background", this.gradientCss)
  }

  mouseover(){
    if (window.innerWidth > 768) {
      $(this.searchScoreOverlayTarget).show();

      if($(this.searchScoreWrapperTarget).data("loaded-partial") != true){
        fetch(`articles/${this.articleId}/search_score_overlay.json`, {
          headers: { accept: 'application/json' }})
        .then( data => {
          return data.json();
        }).then( payload => {
          $(this.searchScoreWrapperTarget).html(payload.html)
          $(this.searchScoreRingTargets).css("background", this.gradientCss)
          $(this.searchScoreWrapperTarget).data("loaded-partial", true)
        });
      }
    }
  }

  mouseout(){
    if (window.innerWidth > 768) {
      $(this.searchScoreOverlayTarget).hide();
    }
  }
}