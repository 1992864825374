import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";
import Rails from "@rails/ujs";
import {cleanTooltips, editorDropLock, editorDropUnlock} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    "sortableItem" // these need to have data-sortable-order
  ]

  connect() {
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--sort")
  }
  // sort controller missing in new frame!!!!
  initSortable() {
    const containerElement = this.element
    this.sortable = Sortable.create(containerElement, {
     // handle: '.sidebar__dragIcon', // specifies the handle selector
      animation: 150,
      ghostClass: 'sortable-ghost', // Class name for the drop placeholder
      dragClass: 'sortable-drag',
      filter: 'sortable-ignore',
      onStart: (event) => {
        this.onDragStart(event)
      },
      onEnd: this.onDragEnd.bind(this),
    });
  }

  changeMode(event) {
    const container = $(this.element)
    if (container.hasClass('edit__mode')) {
      // SET CLASS
      container.removeClass('edit__mode')

      // CHANGE TOOLTIP
      $('li.rearrange').attr('data-bs-original-title', "Rearrange apps")
    } else {
      // SET CLASS
      container.addClass('edit__mode')

      // CHANGE TOOLTIP
      $('li.rearrange').attr('data-bs-original-title', "Save app order")

      // ENABLE SORTABLE
      this.initSortable()
    }
    cleanTooltips()
  }

  onDragStart() {
    editorDropLock()
  }

  onDragEnd(event) {
    const items = this.sortableItemTargets;
    this.order = []
    items.forEach((item, index) => {
      if (item.dataset.sortableOrder !== index.toString()) {
        item.dataset.sortableOrder = index
      }

      this.order.push($(item).data("identifier"))
    })

    // PERSIST ORDER
    this.saveOrder();
    
    editorDropUnlock()
  }

  saveOrder(){
    fetch(`/p/${window.projectShortcode}/sidebar/order.json`, {
      headers: { accept: 'application/json' },
      body: JSON.stringify({order: this.order}),
      method: "POST"})
  }

  disconnect() {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }
}