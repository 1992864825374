import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = []

    connect(){
      // STORE FUNCTION
      var setCitation = this.setCitation;

      // PREP CONTENT
      var controllerNode = $(this.element);
      var content = controllerNode.children()[0].innerText;
      var encodedContent = encodeURIComponent(content);
      var url = `${window.articleId}/citation.json?query=${encodedContent}`;

      // SET LOADER
      controllerNode.append('<div class="citation-loader"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"y="0px"width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml"attributeName="transform"type="rotate"from="0 25 25"to="360 25 25"dur="0.6s"repeatCount="indefinite"/></path></svg></div>');

      // MAKE REQUEST
      fetch(url, {
        headers: { accept: 'application/json' }})
      .then( data => {
        return data.json();
      }).then( payload => {
        console.log(payload);
        if(payload.result != false){
          setCitation(controllerNode, payload.result);
        }
      });
    }

    setCitation(controllerNode, result){
      // GET HOSTNAME
      var domain = new URL(result.replace("www.", "")).hostname;

      // REMOVE LOADER TODO - not removing
      $(controllerNode).children('.citation-loader').remove();

      // APPEND CITATION
      controllerNode.append(`<div class="url-line"><a href="${result}" target="_blank"><img src="https://www.google.com/s2/favicons?domain=${result}">${domain}</a></div>`);

      // SET DATA VAR FOR TAG URL
      $(controllerNode).data("tag-url", result);
    }
}
