import { Controller } from "@hotwired/stimulus"
import { Subscriptions } from "@rails/actioncable";
import Rails from "@rails/ujs";
import consumer from "channels/consumer";
import {initializeTooltips, isMobile} from "../../../utility_functions";

export default class extends Controller {
  static targets = [
    'questionsSection',
    'questionInput'
  ]

  connect(){
    this.loadContextualQuestions();

    initializeTooltips();
  }

  loadContextualQuestions(){
    // GET ADDITIONAL QUESTIONS
    var questionsSection = this.questionsSectionTarget;
    fetch(`${window.articleId}/questions.json`, {
      headers: { accept: 'application/json' },
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      // REMOVE LOADER
      $(questionsSection).html("");
      $.each(payload.result, function( i, value ){
        // INSERT RESULTS
        $(questionsSection).append(`<button class="btn btn-outline-light" data-controller="prompt" data-action="click->sidebar--research#prompt" data-prompt="${value}" data-method="generic"> ${value} </button>`);
      });
    });
  }

  prompt( event ){
    console.log("🟢 STIMULUS METHOD RUNS: research#prompt()!")
    // DISPLAY PROMPT UI
    var prompt = $(event.currentTarget).data("prompt");
    var method = $(event.currentTarget).data("method");
    var loadMore = $(event.currentTarget).data("load-more");
    this.tagType = $(event.currentTarget).data("tag-type");

    // CHANGE STATE
    $('#sidebar__container').data("sidebar-state", method);

    $('.sideBar__Research').html(`<div class="writer_sidebar_results"><button type="button" id="backDefault" class="btn btn-transparent" data-action="click->sidebar#backToDefault"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none"><path d="M11.875 7.5H3.125" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.5 11.875L3.125 7.5L7.5 3.125" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path></svg> Back </button><h2 class="lead">${prompt}</h2><div class="wais__actions-container"></div><div class="skel-loader"><div class="skel-row"><div class="skel-col w-100 h-60 mt-10 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div></div></div>`);
    this.getGenerationData(method, prompt, loadMore);
  }

  askQuestion( event ){
    console.log("🟢 STIMULUS METHOD RUNS: research#askQuestion()!")
    event.preventDefault();
    // DISPLAY PROMPT UI
    var prompt = $(this.questionInputTarget).val();
    var method = "generic";
    var loadMore = $(event.currentTarget).data("load-more");
    this.tagType = 'generic';

    // CHANGE STATE
    $('#sidebar__container').data("sidebar-state", method);

    $('.sideBar__Research').html(`<div class="writer_sidebar_results"><button type="button" id="backDefault" class="btn btn-transparent" data-action="click->sidebar#backToDefault"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 15 15" fill="none"><path d="M11.875 7.5H3.125" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7.5 11.875L3.125 7.5L7.5 3.125" stroke="black" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"></path></svg> Back </button><h2 class="lead">${prompt}</h2><div class="wais__actions-container"></div><div class="skel-loader"><div class="skel-row"><div class="skel-col w-100 h-60 mt-10 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-60 mb-10"></div></div></div></div>`);
    this.getGenerationData(method, prompt, loadMore);
  }

  insert( event ){
    console.log("🟢 STIMULUS METHOD RUNS: research#insert()!")
    // GET INNER TEXT
    var innerText = $(event.currentTarget).find('span')[0].innerText;

    // INSERT A RESULT
    if(this.tagType == "title"){
      console.log(`INSERT: ${event}`)
      // REPLACE TITLE
      $('textarea#title').val(innerText);
      $('textarea#title').trigger("click");

      // REGISTER THE CHANGE EVENT
      window.dispatchEvent(new CustomEvent("tinymce:change"));
    } else if(this.tagType == "a") {
      // LINK INSERT
      tinymce.activeEditor.insertContent(`<a href="${$(event.currentTarget).data("tag-url")}">${innerText}</a>`);
    } else if(this.tagType == "blockquote"){
      // QUOTE INSERT
      tinymce.activeEditor.insertContent(`<blockquote>${innerText}</br>- ${$(event.currentTarget).data("tag-author")}</blockquote>`);
    } else {
      // GENERIC INSERT
      tinymce.activeEditor.insertContent(`<${this.tagType}>${innerText}</${this.tagType}>`);
    }

    if (isMobile()) {
      window.dispatchEvent(new CustomEvent("sidebar:collapse"));
    }

  }

  getGenerationData(method, prompt, loadMore){
    var tagType = this.tagType;
    const activeConnections = []

    function unsubscribeFromAllConnections() {
      activeConnections.map(connection => connection.unsubscribe())
    }
    unsubscribeFromAllConnections();

    // GETS THE GENERATION PAYLOAD AND INSERTS TO SIDEBAR
    fetch(`${window.articleId}/generation.json?method=${method}&loadMore=${loadMore}`, {
      headers: { accept: 'application/json' },
      body: JSON.stringify({input: prompt}),
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      buffer = ""
      if(payload.stream === false){
        processData({ data: payload.result, loaded: true, tagType, prompt, method })
      } else if (payload.stream === true) {
        consumer.subscriptions.create(
          {
            channel: "GenerationChannel",
            id: payload.id
          },
          {
            initialized() {
            },
            connected: function () {
              unsubscribeFromAllConnections()
              activeConnections.push(this)
            },
            received: function(data) {
              processData({ data, loaded: false, tagType, prompt, method })

              if (data === END_POINTER) {
                this.unsubscribe();
              }else{
                const backDefault = document.getElementById("backDefault");
                if (backDefault) {
                  backDefault.addEventListener("click", () => {
                    // Unsubscribe from the ActionCable channel
                    this.unsubscribe();
                  });
                }
              }
            }
          },
        )
      } else if (payload.result == false) {
        //this.backToDefault();
      }
    });
  }
}

function clearActionsContainer(prompt) {
  if($('.writer_sidebar_results h2.lead').text() == prompt) {
    $('.wais__actions-container').html(" ");
  }
}

function appendLoadMoreButton(prompt, method, tagType) {
  if($('.writer_sidebar_results h2.lead').text() == prompt) {
    $('.writer_sidebar_results').append(`<button class="load-more" data-controller="prompt" data-action="click->sidebar--research#prompt" data-prompt="${prompt}" data-method="${method}" data-tag-type="${tagType}" data-load-more="true"><svg xmlns="http://www.w3.org/2000/svg"viewBox="0 -960 960 960"><path d="M418-126q-111-21-184.5-109T160-439q0-71 30-134t85-107q8-6 19-5.5t19 8.5q10 10 9 23t-13 23q-42 35-65.5 85.5T220-439q0 94 58 163.5T425-186q11 2 18.5 11t7.5 20q0 14-10 22.5t-23 6.5Zm126 0q-13 2-23-6.5T511-155q0-11 7.5-20t18.5-11q90-20 147-89.5T741-439q0-109-75.5-184.5T481-699h-20l39 39q8 8 8 21t-8 21q-9 9-22 9t-21-9l-91-90q-5-5-7-10t-2-11q0-6 2-11t7-10l91-91q8-8 21-8t22 8q8 9 8 22t-8 21l-39 39h20q134 0 227 93.5T801-439q0 116-73 204T544-126Z"></path></svg> Load more </button>`);
  }
}

function cleanSentence(string) {
  let result = string.trim()
  result = result.replaceAll('\"', '')
  result = result.replaceAll(']', '')
  result = result.replaceAll('[', '')
  result = result.replaceAll('[!END!]', '')
  result = result.replaceAll('[!START!]', '')
  result = result.replaceAll('[!START!', '')
  result = result.replaceAll('!START!', '')
  result = result.replaceAll('[!END!', '')
  result = result.replaceAll('!END!', '')
  result = result.replaceAll('null[!END!]', '')
  result = result.replaceAll('null', '')

  if (result.charAt(result.length - 1) === ",") {
    result = result.slice(0, -1)
  }

  return result;
}

function cleanQuoteSentence(buffer) {
  let result = `${ buffer }`;
  result = result.replace("[!START!]", "")
  result = result.replace("[!START!", "")
  result = result.replace("[!END!]", "")
  result = result.replace("[!END!", "")
  result = result.trim();
  result = result.replace(/ {2,}/g, '')
  result = result.replaceAll("]", "")
  result = result.replaceAll("[", "")
  result = result.replaceAll("null", "")

  if (result.charAt(result.length - 1) === ",") {
    result = result.slice(0, -1)
  }

  return result;
}

function createQuoteObject(buffer) {
  let object = cleanQuoteSentence(buffer)

  try {
    object = JSON.parse(`${object}`)

    return object;
  } catch(error) {
    console.error("Could not parse object", object)
  }
}

const START_POINTER = '[!START!]'
const END_POINTER = '[!END!]'
var buffer = "";

function processData({ data, loaded, tagType, prompt, method }) {
  if (loaded) {
    [].concat(START_POINTER, data, END_POINTER ).forEach(chunk => processChunks(chunk))
  } else {
    processChunks(data);
  }

  function processChunks(chunk) {
    let sentence;

    buffer += chunk;

    if(tagType == "blockquote") {
      if (chunk && chunk.includes('}')) {
        sentence = createQuoteObject(buffer);

        if (sentence) {
          updateView(sentence, tagType, prompt);
        }

        buffer = ""
      }
    } else {
      let foundMatch = false;
      let separator = '",'
      if (chunk && (chunk.includes(separator) || chunk.includes('\n\n') || chunk.includes(']'))) {
        let index = chunk.indexOf(',')
        if (chunk[index - 1] === "\\") {
          foundMatch = false;
        } else {
          foundMatch = true;
        }
      }

      if (chunk && foundMatch) {
        sentence = cleanSentence(buffer);
        buffer = ""

        if (sentence) {
          updateView(sentence, tagType, prompt);
        }
      }
    }

    if (chunk && chunk === END_POINTER) {
      appendLoadMoreButton(prompt, method, tagType);
      removeLoader(prompt);
      if (chunk === END_POINTER) {
        if (!$('.wais__actions-container').text() && $('.writer_sidebar_results h2.lead').text() == prompt) {
          $('.wais__actions-container').html('<span class="something-went-wrong">Something went wrong. Please try again!</span>');
        }
      }
    }
  }
}

function updateView(value, tagType, prompt) {
  if($('.writer_sidebar_results h2.lead').text() == prompt) {
    // FIX FOR BUG WHERE THEY SWITCH TO A DIFFERENT OPTION
    // AND IT THEN INSERTS THE BELOW IN THE WRONG PLACE
    if(tagType == "a"){
      $('.wais__actions-container').append(`<button data-controller="sidebar--citation" class="btn btn-outline-dark" data-tag-type="${tagType}" data-action="click->sidebar--research#insert" data-tag-url="#"><span>${value}</span></button>`);
    } else if(tagType == "blockquote") {
      // NOTE: it does not send the starting "["
      $('.wais__actions-container').append(`<button class="btn btn-outline-dark" data-tag-type="${tagType}" data-action="click->sidebar--research#insert" data-tag-author="${value["author"]}"><span>${value["quote"]}</span><div class="author-line">${value["author"]}</div></button>`);
    } else {
      $('.wais__actions-container').append(`<button class="btn btn-outline-dark" data-action="click->sidebar--research#insert" data-tag-type="${tagType}"><span>${value}</span></button>`);
    }
  }
}

function removeLoader(prompt) {
  if($('.writer_sidebar_results h2.lead').text() == prompt) {
    $('.writer_sidebar_results .skel-loader').remove();
  }
}