import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";

const unsplashAccessKey = 'OrmkzIT5HFffa2ms8_I-XSIZKsmJClZuHTXq7e6_xlw';

export default class extends Controller {

  static targets = ['gallery', 'queryInput']

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--unsplash");
    initializeTooltips();

    this.query = $('#title').val();
    this.page = 1
    this.fetchPhotos()
  }

  searchQuery(e){
    e.preventDefault();

    console.log($(this.queryInputTarget).val())

    this.query = $(this.queryInputTarget).val();
    this.fetchPhotos();
  }

  fetchPhotos(){
    const url = `https://api.unsplash.com/search/photos?query=${encodeURIComponent(this.query)}&per_page=30&page=${this.page}&client_id=${unsplashAccessKey}`
    const toast = this.toast;

    // ENTER LOADING STATE
    this.galleryTarget.innerHTML = '<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"y="0px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml"attributeName="transform"type="rotate"from="0 25 25"to="360 25 25"dur="0.6s"repeatCount="indefinite"/></path></svg>';

    fetch(url)
      .then(response => {
        if (!response.ok) {
          // TOAST ERROR HERE
          toast("alert", "Unsplash is unavailable, please try again.")
        }
        return response.json();
      })
      .then(data => {
        this.insertPhotos(data.results)
      })
      .catch(error => {
        toast("alert", "Unsplash is unavailable, please try again.")
      });
  }

  insertPhotos(photos){
    this.galleryTarget;
    this.galleryTarget.innerHTML = ''; // Clear the gallery before adding new images

    photos.forEach(photo => {
        const imgElement = document.createElement('img');
        imgElement.src = photo.urls.regular; // Using 'regular' size images

        imgElement.alt = photo.alt_description;
        imgElement.setAttribute('data-action', 'click->sidebar--unsplash#selectPhoto click->sidebar#collapseMobile');
        imgElement.setAttribute('data-bs-custom-class', 'custom-tooltip-commentResolved');
        imgElement.setAttribute('data-bs-toggle', 'tooltip');
        imgElement.setAttribute('data-bs-placement', 'bottom');
        imgElement.setAttribute('aria-expanded', 'false');
        imgElement.setAttribute('aria-label', 'Insert photo');
        imgElement.setAttribute('data-bs-original-title', 'Insert photo');
        this.galleryTarget.appendChild(imgElement);
    });

    $(this.galleryTarget).append(`<button class="load-more" data-controller="prompt" data-action="click->sidebar--unsplash#loadMore"><svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M418-126q-111-21-184.5-109T160-439q0-71 30-134t85-107q8-6 19-5.5t19 8.5q10 10 9 23t-13 23q-42 35-65.5 85.5T220-439q0 94 58 163.5T425-186q11 2 18.5 11t7.5 20q0 14-10 22.5t-23 6.5Zm126 0q-13 2-23-6.5T511-155q0-11 7.5-20t18.5-11q90-20 147-89.5T741-439q0-109-75.5-184.5T481-699h-20l39 39q8 8 8 21t-8 21q-9 9-22 9t-21-9l-91-90q-5-5-7-10t-2-11q0-6 2-11t7-10l91-91q8-8 21-8t22 8q8 9 8 22t-8 21l-39 39h20q134 0 227 93.5T801-439q0 116-73 204T544-126Z"></path></svg> Load more </button>`)

    initializeTooltips();
  }

  selectPhoto(e){
    let url = e.currentTarget.getAttribute('src')
    let altText = e.currentTarget.getAttribute('alt')
    let imgHtml = `<img src="${url}" alt="${altText}" style="max-width: 100%;">`

    tinymce.activeEditor.insertContent(imgHtml)

    this.toast("notice", "Photo has been inserted.")
  }

  loadMore(){
    this.page = (this.page + 1)
    this.fetchPhotos();

    // SCROLL TO TOP
    $('.writer__ai-sidebar').animate({
        scrollTop: 0
    }, 'slow');
  }

  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}
