import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import $ from 'jquery'
import 'select2'
import {showModal} from "../../utility_functions";

export default class extends Controller {

  connect() {
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: drafts--index");
    // Select all elements with the class 'check-table'
    //TODO! - articles needs to have this too, possibly refactor to util functions
    const checkboxes = document.querySelectorAll('.check-table');
    const tableRows =  document.querySelectorAll('tr.selected')
    if (tableRows.length > 0 ) {
      tableRows.forEach((row) => {
        row.classList.remove('selected');
      })
      // Convert NodeList to Array and filter only the checked checkboxes
      const checkedCheckboxes = Array.from(checkboxes).filter(function(checkbox) {
        return checkbox.checked;
      });
      // Iterate over each checked checkbox and uncheck it
      checkedCheckboxes.forEach(function(checkbox) {
        checkbox.checked = false;
      });
    }
  }

  renameFolder() {
    const folderId = event.currentTarget.dataset["draftsIndexId"];
    const folderName = event.currentTarget.dataset["draftsIndexName"];
    document.getElementById("draft_folder_edit_name").value = folderName;
    document.getElementById("edit_draft_folder_form").action = "draft_folders/" + folderId;
    //$('#renameFolderModal').modal('show');
  }

  openAssignToFolderModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const selectedArticles = window.selectedIds;
    if (selectedArticles.length > 0){
      // ASSIGN IDs TO FORM
      $('#assignToFolderModal #article_article_ids').val(selectedArticles);
    } else {
      return;
    }

    showModal('assignToFolderModal');

    $('#assignToFolderModal #article_draft_folder_id').select2({
      placeholder: 'Select a cluster',
      minimumResultsForSearch: 3,
      width: '100%',
      dropdownParent: $('#assignToFolderModal')
    }).on("select2:opening", this.attachInputListener.bind(this))
        .on("select2:closing", this.detachInputListener.bind(this));

    this.cleanExtraSelect2Elements()
  }

  openAssignToTeammateModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const selectedArticles = window.selectedIds;
    if (selectedArticles.length > 0){
      // ASSIGN IDS TO FORM
      $('#assignToTeammateModal #article_article_ids').val(selectedArticles);
    } else {
      return;
    }

    showModal('assignToTeammateModal');

    $('#assignToTeammateModal #article_user_id').select2({
      placeholder: 'Select a teammate',
      minimumResultsForSearch: 3,
      width: '100%',
      dropdownParent: $('#assignToTeammateModal')
    }).on("select2:opening", this.attachInputListener.bind(this))
        .on("select2:closing", this.detachInputListener.bind(this));

    this.cleanExtraSelect2Elements()
  }

  openAssignToClusterModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const selectedArticles = [...(new Set(window.selectedIds))];
    if (selectedArticles.length > 0){
      // ASSIGN IDS TO FORM
      $('#assignToClusterModal #article_article_ids').val(selectedArticles);
      // SHOW MODAL
    } else {
      return;
    }

    showModal('assignToClusterModal');

    $('#assignToClusterModal #article_content_category_id').select2({
      placeholder: 'Select a cluster',
      minimumResultsForSearch: 3,
      width: '100%',
      dropdownParent: $('#assignToClusterModal')
    }).on("select2:opening", this.attachInputListener.bind(this))
        .on("select2:closing", this.detachInputListener.bind(this));

    this.cleanExtraSelect2Elements()
  }
  attachInputListener() {
    setTimeout(() => {
      const inputField = document.querySelector('.select2-search__field');
      if (inputField) {
        inputField.addEventListener('input', this.handleInputChange);
      }
    }, 0);
  }

  detachInputListener() {
    const inputField = document.querySelector('.select2-search__field');
    if (inputField) {
      inputField.removeEventListener('input', this.handleInputChange);
    }
  }

  handleInputChange(event) {
    const inputField = event.target;
    if (inputField.value !== "") {
      inputField.style.backgroundColor = "white";
    } else {
      inputField.style.backgroundColor = "transparent"; // reset to default or other color
    }
  }

  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }

  cleanExtraSelect2Elements() {
    // Find all extra select2 elements
    const selectElements = document.querySelectorAll('.select2 + .select2');
    selectElements.forEach((element) => {
      element.remove()
    });
  }
}
