import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";
import {numberWithCommas} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    'wordsCount',
    'charCount',
    'humanPercent',
    'aiPercent'
  ]

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--info");

    initializeTooltips();

    this.update();
  }

  update(){
    // SET COUNTS
    if(tinymce.activeEditor){
      var wordcount = tinymce.activeEditor.plugins.wordcount;
      $(this.wordsCountTarget).text(numberWithCommas(wordcount.body.getWordCount()));
      $(this.charCountTarget).text(numberWithCommas(wordcount.body.getCharacterCount()));
    }
  }
}
