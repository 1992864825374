import { Controller } from "@hotwired/stimulus"
import { hideModal } from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    'button',
    'input'
  ]

  formSuccess(e){
    if(e.detail.success){
      hideModal('publishedModal')
    }
  }
}
