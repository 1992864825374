import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
      "newInput",
      "newForm",
    ]

    connect(){
      console.log("🟢😉 STIMULUS CONTROLLER OPERATIONAL: comments--new");

        // SET SIDEBAR STATE
      $('#sidebar__container').data("sidebar-state", "comments--new");
      $('.navSidebar .nav-link').removeClass("active");
      $('.nav-link#comments').addClass("active");

      // SET DROPDOWN STATE
      $('#sidebar__container').data("sidebar-state", "comments--new");

      // GET VALUES
      this.shortcode = this.getHex(10);

      // FORMAT SELECTION
      tinymce.activeEditor.formatter.apply('comment', {shortcode: this.shortcode});

      // SET FIELDS
      $('#comment_shortcode').val(this.shortcode);

      // AUTO FOCUS
      $(this.newInputTarget).trigger("click");
      $(this.newInputTarget).focus();
    }

    submit(e){
      // ON ACTUAL SUBMIT OF FORM
      $('#new_comment').addClass("submitted");
    }

    newComment(){
      
      if($(this.newInputTarget).val().length != 0) {
        var shortcodes = $('#data__store #data__store_comments').data("value");
        shortcodes.push(this.shortcode);
        $('#data__store #data__store_comments').data("value", shortcodes);

        // AUTOSAVE EVENT
        window.dispatchEvent(new CustomEvent("tinymce:change"));
      }

      if (window.innerWidth > 768) {
          window.dispatchEvent(new CustomEvent("comments:allcomments"));
      }

    }

    adjustHeight( event ){
      this.newInputTarget.style.height = "1px";
      this.newInputTarget.style.height = (this.newInputTarget.scrollHeight + 5 + "px");
    }

    keydown( event ){
        console.log("🟢 Ran keydown(): comments--new");
      this.adjustHeight( event );

      if (event.keyCode == '13') {
        event.preventDefault();
        $(this.newFormTarget).find('button').trigger("click");
      }
    }

    error() {
      // AJAX ERROR
      $('#new_comment').removeClass("submitted");
    }

    blur(){
      //
        console.log("🟢 Ran blur(): comments--new");
    }

    request(url){
      // MAKE PARTIAL HTML REQUEST
        console.log("🟢 Ran request(): comments--new -> ", url);
      fetch(url, {
        headers: { accept: 'application/json' }})
      .then( data => {
        return data.json();
      }).then( payload => {
        $('.commentsDropdown__container .dropdown-menu.comments').html(payload.html);
      });
    }

    getHex(len){
      const hex = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWYXZ';
      let output = '';
      for (let i = 0; i < len; ++i) {
          output += hex.charAt(Math.floor(Math.random() * hex.length));
      }
      return output;
    }
}
