import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    'button',
    'textArea'
  ]

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--meta-description");
    initializeTooltips();

    this.generateMetaDescription();
  }

  generateMetaDescription(){
    const url = `/p/${window.projectShortcode}/sidebar/${window.articleId}/meta_description`
    fetch(url, {
      headers: { accept: 'application/json' },
      method: 'POST'})
    .then( data => {
      return data.json();
    }).then( payload => {
      if (payload.result){
        $(this.textAreaTarget).val(payload.result)
        $('.sideBar__meta_description #loader').remove();
      } else {
        this.toast("alert", "Something went wrong, please reload the sidebar app.")
      }
    });
  }

  copyToClipboard(){
    const toast = this.toast;
    navigator.clipboard.writeText($(this.textAreaTarget).val()).then(function() {
        toast("notice", "Copied to clipboard.")
    }).catch(function(err) {
        toast("alert", "Your browser won't let us copy to clipboard.")
    });
  }

  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}
