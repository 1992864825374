import {Controller} from "@hotwired/stimulus"
import {cleanTooltips} from "../../utility_functions";

export default class extends Controller {

  connect() {
    console.log("modal proxy controller connected !")
  }

  open(e) {

    cleanTooltips()
    const modalIdentifier = $(e.currentTarget).data("modal-identifier")
    //console.log(`modal:${modalIdentifier}`)
    window.dispatchEvent(new Event(`modal:${modalIdentifier}`));
  }

}