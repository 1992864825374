import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    'versionBtn',
    'modalEditorContent',
    'modalEditorTitle',
    'activeDate'
  ]

  selectedContent;

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--version")

    // INIT BOOTSTRAP MODAL
    var myModal = new bootstrap.Modal(document.getElementById('versionControlModal'), {
      keyboard: false
    });
  }

  selectVersion(event) {
    let id = event.target.dataset.id
    let html = event.target.dataset.html
    let title = event.target.dataset.title
    let created = event.target.dataset.created
    let modalEditor = this.modalEditorContentTarget
    let modalTitle = this.modalEditorTitleTarget

    if (html.length > 0 && title.length > 0){
      // UPDATE DOM
      modalEditor.innerHTML = this.cleanContent(html)
      modalTitle.innerHTML = title

      // SET ACTIVE VARS
      this.selectedHTML = html
      this.selectedTitle = title
    } else {
      this.skelLoader();
      fetch(`/p/${window.projectShortcode}/revisions/${window.articleId}/${id}.json`, {
        headers: { accept: 'application/json' },
        method: "GET"})
      .then( data => {
        return data.json();
      }).then( payload => {
        // UPDATE DOM
        modalEditor.innerHTML = this.cleanContent(payload.html)
        modalTitle.innerHTML = payload.title

        // UPDATE HTML ATTRS
        event.target.dataset.html = payload.html
        event.target.dataset.title = payload.title

        // SET ACTIVE VARS
        this.selectedHTML = payload.html
        this.selectedTitle = payload.title
      });
    }

    // UPDATE ACTIVE DATE
    $(this.activeDateTarget).text(created)

    $('.sidebar__box.active').removeClass('active')
    $(event.target).addClass('active')
  }

  cleanContent(input){
    // Parse the HTML string into a DOM Document
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');

    // Find all <span> elements with the class "comment"
    const spans = doc.querySelectorAll('span.comment');

    // Replace each <span> with its own contents
    spans.forEach(span => {
      // Create a document fragment to hold the contents
      const fragment = document.createDocumentFragment();
      while (span.firstChild) {
        fragment.appendChild(span.firstChild);
      }

      // Replace the <span> with the fragment
      span.parentNode.replaceChild(fragment, span);
    });

    // Serialize the document back to a string
    return doc.body.innerHTML;
  }

  skelLoader() {
    this.modalEditorContentTarget.innerHTML = '<div class=skel-loader><div class=skel-row><div class="skel-col h-30 mb-10 w-100"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-90"></div></div><div class=skel-row><div class="skel-col h-30 w-100 mb-30"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-100"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-100"></div></div><div class=skel-row><div class="skel-col h-30 mb-30 w-80"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-100"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-90"></div></div><div class=skel-row><div class="skel-col h-30 mb-30 w-80"></div></div><div class=skel-row><div class="skel-col h-30 mb-10 w-100"></div></div><div class=skel-row><div class="skel-col h-30 mb-30 w-90"></div></div></div>'
    this.modalEditorTitleTarget.innerHTML = '<div class=skel-loader><div class=skel-row><div class="skel-col w-100 mb-30 h-80"></div></div>'
  }

  failWithError() {
    // 
  }

  restoreVersion() {
    if (tinymce && tinymce.activeEditor) {
      if (tinymce.activeEditor.getContent().trim() === this.selectedHTML.trim()) {
        this.toast('alert', 'This is your current revision.');
        return;
      }

      // UPDATE CONTENT
      tinymce.activeEditor.setContent(this.selectedHTML)
      $('textarea#title').text(this.selectedTitle)

      // Manually trigger the 'change' event
      // so that it gets persisted
      tinymce.activeEditor.fire('change');

      // Hide the modal
      const modalElement = document.getElementById('versionControlModal');
      if (modalElement) {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
          modalInstance.hide();
        }
      }
      this.toast('notice', 'Your revision has been restored.');
    }
  }

  toast(mode, message) {
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}