import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
    static targets = [
        "websiteMenuItem",
        "projectDescriptionMenuItem",
        "audienceMenuItem",
        "websiteBody",
        "projectDescriptionBody",
        "audienceBody",
        "hiddenForm"
    ]

    connect() {
        console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: priming")

        // bind data from hidden fields to visible fields on init
        const hiddenFieldWebsite = $("#project_website").val()
        const hiddenFieldDescription = $("#project_description").val()
        const hiddenFieldAudience = $("#project_audience").val()
        const shownFieldWebsite = $("#project_website_proxy")
        shownFieldWebsite.val(hiddenFieldWebsite)
        const shownFieldDescription = $("#project_description_proxy")
        shownFieldDescription.val(hiddenFieldDescription)
        const shownFieldAudience = $("#project_audience_proxy")
        shownFieldAudience.val(hiddenFieldAudience)

    }

    prevStep(e) {
        e.preventDefault()
        const backToStep = $(e.currentTarget).data("step")
        this.setStep(backToStep)
    }

    setStep(step) {

        if (step === 3) {
            // CHANGE MENU
            $('.ideaIntroStep').removeClass("active")
            $(this.audienceMenuItemTarget).addClass("active")

            // CHANGE STATE
            $(this.projectDescriptionBodyTarget).hide()
            $(this.audienceBodyTarget).addClass("active")

            // mobile view uses it
            $('.step2').removeClass('active')
            $('.step3').addClass('active')
        } else if (step === 2) {
            // CHANGE MENU
            $('.ideaIntroStep').removeClass("active")
            $(this.projectDescriptionBodyTarget).addClass("active")

            $('.step1').removeClass('active')
            $('.step2').addClass('active')

            // CHANGE STATE
            $(this.websiteBodyTarget).hide()
            $(this.projectDescriptionBodyTarget).show()
        } else if (step === 1) {
            // CHANGE MENU
            $('.ideaIntroStep').removeClass("active")
            $(this.websiteBodyTarget).addClass("active")

            $('.step1').removeClass('active')
            $('.step2').addClass('active')

            // CHANGE STATE
            $(this.projectDescriptionBodyTarget).hide()
            $(this.websiteBodyTarget).show()
        }

        // DEACTIVATE OLD STEP
        $(`#primingModal .ideaIntroStep`).removeClass("active");

        // CHANGE STATE TO CURRENT STEP
        $(`#primingModal .step${step} .ideaIntroStep`).addClass("active");

        // HIDE ACTIVE FRAME
        $(`#primingModal .form__step`).removeClass("active");
        $(`#primingModal .ideaIntroStep__container`).removeClass("active");

        // SHOW STEP FRAME
        $(`#primingModal .form__step.form__step${step}`).addClass("active");
        $(`#primingModal .ideaIntroStep__container.step${step}`).addClass("active")
    }

    resetState() {
        // RESET STATE
        // NEED TO DO MORE
        $(this.websiteBodyTarget).show();
        $('.ideaIntroStep').removeClass("active")
        $('.form__step.active').removeClass("active")
        $('.form__step.form__step1').addClass("active")
        $(this.websiteMenuItemTarget).addClass("active")
    }

    submitProjectName(e) {
        e.preventDefault()
        console.log("submitting project name")

        var error = $(this.websiteBodyTarget).find(".validetta-error")
        var objective = $('#project_website_proxy').val()

        if (error.length == 0 && objective.length > 0) {
            // MAKE REQUEST

            const hiddenFieldWebsite = $("#project_website")
            const shownFieldWebsiteValue = $("#project_website_proxy").val()
            hiddenFieldWebsite.val(shownFieldWebsiteValue)
            this.setStep(2)
        }
    }


    submitProjectDescription(e) {
        e.preventDefault()
        const error = $(this.projectDescriptionBodyTarget).find(".validetta-error")

        if (error.length == 0) {
            // UI part needs to go to setStep method


            const hiddenFieldDescription = $("#project_description")
            const shownFieldDescriptionValue = $("#project_description_proxy").val()
            hiddenFieldDescription.val(shownFieldDescriptionValue)

            this.setStep(3)
        }
    }

    submitAudience(e) {
        e.preventDefault()
        const error = $(this.projectDescriptionBodyTarget).find(".validetta-error")

        if (error.length == 0) {

            const hiddenFieldAudience = $("#project_audience")
            const shownFieldAudienceValue = $("#project_audience_proxy").val()
            hiddenFieldAudience.val(shownFieldAudienceValue)

            this.hiddenFormTarget.submit()
        }
    }
}