import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
    'input',
    'generate',
    'credits'
    ]

    connect(){
      // IDENTITY CHECK OF SOME SORT HERE
    }

    generate(){
      // GENERATION REQUEST
      var inputTarget = $(this.inputTarget);
      var creditsTarget = $(this.creditsTarget);
      var input = inputTarget.val();

      if(input.length > 0){
        // LOCK BUTTON
        $(this.generateTarget).prop('disabled', true);

        fetch(`generate`, {
          headers: { accept: 'application/json' },
          body: JSON.stringify({input: input}),
          method: "POST"})
        .then( data => {
          return data.json();
        }).then( payload => {
            // UNLOCK BUTTON
            $(this.generateTarget).prop('disabled', false);

            if(payload.result == false){
                // ERROR
                console.log("ERROR!");
                $(this.inputTarget).val("Hey! It looks like you have no free tool credits left today :(")
            } else {
              inputTarget.val(payload.result);
              creditsTarget.val(payload.remaining_credits);
            }
        });
      }
    }
}