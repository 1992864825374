// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// FUNDAMENTALS
import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import "jquery"
import "channels"

// STIMULUS CONTROLLERS
window.Stimulus = Application.start()
window.Stimulus.debug = true
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// TYPICAL JS DEPENDENCIES
window.bootstrap = require('/app/javascript/packs/bootstrap')
require("packs/bootstrap")
require("packs/shared")
require("packs/validetta")

// LAUNCH
Rails.start()
ActiveStorage.start()

Turbo.setConfirmMethod((message, element) => {
  const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
  const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
  innerMessage.textContent = message;

  myModal.show();

  return new Promise((resolve, _reject) => {
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");
    confirmButton.addEventListener('click', (e) => {
      e.preventDefault();
      myModal.hide();
      resolve(true)
    })
  })
})
