import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
        'modal',
        'videoOverlay',
    ]
    currentStep = 0;

    connect() {
        super.connect()
        console.log("INTRO MODAL CONTROLLER - connected")
        this.currentStep = 1
    }

    onVideoOverlayClick(event) {
        this.videoOverlayTarget.style.display = 'none'
    }


    setActiveStep(activeStep) {
        // top links
        const steps = document.querySelectorAll('.ideaIntroStep');
        steps.forEach(step => {
            if (step.classList.contains('active'))
                step.classList.remove('active')
        })
        const newActiveStep = document.querySelector(`.ideaIntroStep__container[data-step="${activeStep}"] .ideaIntroStep`)
        const activeStepContainer = document.querySelector(`.ideaIntroStep__container[data-step="${activeStep}"]`)
        if (activeStepContainer.classList.contains('disabled'))
            activeStepContainer.classList.remove('disabled')
        newActiveStep.classList.add('active')
        // form steps
        const forms = document.querySelectorAll('.form__step');
        forms.forEach(step => {
            if (step.classList.contains('active'))
                step.classList.remove('active')
        })
        const newActiveForm = document.querySelector(`.form__step${activeStep}`)
        newActiveForm.classList.add('active')
    }

    clearActiveErrors() {
        const activeStepError = document.querySelectorAll(`.form__group-error`)
        activeStepError.forEach((error) => {
            if (error.classList.contains('active'))
                error.classList.remove('active')
        })

    }

    checkValid() {
        const isValid = document.querySelector(`textarea[data-target="text${this.currentStep}"]`).value.length
        console.log("isValid: ", isValid)
        if (!isValid) {
            //setError
            const activeStepError = document.querySelector(`.form__step${this.currentStep} .form__group-error`)
            activeStepError.classList.add('active')
            console.log("set validation error!")
            return false;

        }
        return true;
    }

    nextStep() {
        // validate before next
        // show error on empty only
        // this button should change to submit on last page
       const isValid = this.checkValid()

        if (this.currentStep < 3 && isValid) {
            this.clearActiveErrors()
            this.currentStep = this.currentStep + 1
            this.setActiveStep(this.currentStep)
        } else {
            console.log("disable what needs to be disabled")
        }

        // remove disabled with nth child and step ideaIntroStep__container

    }

    prevStep() {
        // disabled on first page
        if (this.currentStep > 1) {
            this.clearActiveErrors()
            this.currentStep = this.currentStep - 1
            this.setActiveStep(this.currentStep)
        } else {
            console.log("disable what needs to be disabled")
        }
    }

    setStep(event) {
        event.preventDefault()
        this.currentStep = parseInt(event.target.dataset.step)
        this.setActiveStep(this.currentStep)
    }

    submit(event) {
        event.preventDefault()
        const isValid = this.checkValid()

        console.log("event: ", event)
        if (isValid)
            Turbolinks.visit('ideas/brainstorming')
    }

    disconnect() {
        super.disconnect();
    }
}