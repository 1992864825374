import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";
import {numberWithCommas} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    'headlinesBox',
    'currentHeadlines',
    'targetHeadlines',
    'wordsBox',
    'currentWords',
    'targetWords',
    'imagesBox',
    'currentImages',
    'targetImages',
    'linksBox',
    'currentLinks',
    'targetLinks',
    'intentsTable',
    'searchScore'
  ]

  getIntentScore() {
    // bellow is for the search score calculation
    const input = tinymce.activeEditor.getContent({format: "text"});
    const wordCount = this.currentWords
    const headlineCount = this.currentHeadlines
    const linkCount = this.currentLinks

    fetch(`${window.articleId}/performance/search_score.json`, {
      headers: { accept: 'application/json' },
      body: JSON.stringify({
        input: input,
        wordCount: wordCount,
        headlineCount: headlineCount,
        linkCount: linkCount
      }),
      method: "POST"})
      .then( data => {
        return data.json();
      }).then( payload => {

      window.searchScore = {...payload};
      console.log("window.searchScore - ", window.searchScore)

      this.setSearchScore();
    })
  }

  activateEditMode() {
    document.dispatchEvent(new CustomEvent("sortable:init"));
    this.element.classList.add("editing__intents");
  }

  deactivateEditMode() {
    console.log("rearrange mode off")
    document.dispatchEvent(new CustomEvent("sortable:destroy"));
    this.element.classList.remove("editing__intents");
    this.getIntentScore()
  }

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--performance");
    // testing this event
    document.addEventListener('turbo:before-stream-render', (event) => this.handleStreamRender(event));

    // SET SIDEBAR STATE
    $('#sidebar__container').data("sidebar-state", "performance");
    $('.editor-sidebar a.nav-link').removeClass("active");
    $('.editor-sidebar a.nav-link#performance').addClass("active");

    // GET COUNTS
    this.currentHeadlines = $('#tinymce').find('h2').length
    this.currentWords = tinymce.activeEditor.plugins.wordcount.body.getWordCount()
    this.currentLinks = $('#tinymce').find('a').length

    // GET SEARCH SCORE
    this.getSearchScore()

    initializeTooltips()
  }

  update(){
    // AUTO UPDATING E.G LIVE TYPING

    this.currentHeadlines = $('#tinymce').find('h2').length
    this.currentWords = tinymce.activeEditor.plugins.wordcount.body.getWordCount()
    this.currentLinks = $('#tinymce').find('a').length

    this.setWords(true)
    this.setHeadlines()
    this.setLinks()
  }

  getSearchScore(){
    var input = tinymce.activeEditor.getContent({format: "text"});
    var wordCount = this.currentWords
    var headlineCount = this.currentHeadlines
    var linkCount = this.currentLinks

    // SET LOADER STATE
    const loaderHtml = '<div class="aiLoader__allComments">\n' +
        '<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>\n' +
        '  </div>'
    $(this.searchScoreTarget).html(loaderHtml);
    
    fetch(`${window.articleId}/performance/search_score.json`, {
      headers: { accept: 'application/json' },
      body: JSON.stringify({
        input: input,
        wordCount: wordCount,
        headlineCount: headlineCount,
        linkCount: linkCount
      }),
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      //console.log("payload: ", payload)
      window.searchScore = {...payload};
      // UPDATE CONTENT DEPTH TARGETS
      this.setWords(false)
      this.setHeadlines()
      this.setLinks()
      this.setIntents()
      this.setSearchScore()
    });
  }

  setWords(reCheckable){
    // SET WORDS
    $(this.currentWordsTarget).text(numberWithCommas(this.currentWords));
    if(this.currentWords > window.searchScore["wordTarget"]){
      $(this.wordsBoxTarget).addClass("on_target");

      if($(this.wordsBoxTarget).hasClass("off_target_down") && reCheckable){
        console.log("CHECKING SEARCH SCORE");
        this.getSearchScore();
      }

      $(this.wordsBoxTarget).removeClass("off_target_down");
    } else {
      $(this.wordsBoxTarget).addClass("off_target_down");

      if($(this.wordsBoxTarget).hasClass("on_target") && reCheckable){
        this.getSearchScore();
      }

      $(this.wordsBoxTarget).removeClass("on_target");
    }

    // UPDATE TARGET
    $('.cb__target').show();
    $(this.targetWordsTarget).text(`Target: ${window.searchScore["wordTarget"]}`);
  }

  setHeadlines(){
    // SET HEADLINES
    $(this.currentHeadlinesTarget).text(this.currentHeadlines);
    if(this.currentHeadlines > window.searchScore["headingTarget"]){
      $(this.headlinesBoxTarget).addClass("on_target");
      $(this.headlinesBoxTarget).removeClass("off_target_down");
    } else {
      $(this.headlinesBoxTarget).addClass("off_target_down");
      $(this.headlinesBoxTarget).removeClass("on_target");
    }

    // UPDATE TARGET
    $('.cb__target').show();
    $(this.targetHeadlinesTarget).text(`Target: ${window.searchScore["headingTarget"]}`);
  }

  setLinks(){
    // SET LINKS
    $(this.currentLinksTarget).text(this.currentLinks);
    if(this.currentLinks > window.searchScore["linkTarget"]){
      $(this.linksBoxTarget).addClass("on_target");
      $(this.linksBoxTarget).removeClass("off_target_down");
    } else {
      $(this.linksBoxTarget).addClass("off_target_down");
      $(this.linksBoxTarget).removeClass("on_target");
    }

    // UPDATE TARGET
    $('.cb__target').show();
    $(this.targetLinksTarget).text(`Target: ${window.searchScore["linkTarget"]}`);
  }

  setIntents(){
    // SET INTENTS
    const intentsTableTarget = this.intentsTableTarget;

    $(this.intentsTableTarget).find("#ai-loader").hide();

    // MAKE SURE INTENTS EXIST
    if(window.searchScore["intentObjects"] && window.searchScore["intentObjects"].length > 0){
      console.log("window.searchScore[intentObjects]: ", window.searchScore["intentObjects"])
      // RESET INTENTS
      $(intentsTableTarget).html("");
      const articleId = window.articleId;

      window.searchScore['intentObjects'].forEach((intent) => {
        let icon, message;

        switch (intent.score) {
          case 5:
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path></svg>'
            message = "Highly satisfied"
            break;
          case 4:
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11.0026 16L6.75999 11.7574L8.17421 10.3431L11.0026 13.1716L16.6595 7.51472L18.0737 8.92893L11.0026 16Z"></path></svg>'
            message = "Mostly satisfied"
            break;
          case 3:
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>'
            message = "Loosely satisfied"
            break;
          case 2:
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>'
            message = "There's more work to do"
            break;
          case 1:
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>'
            message = "Not satisfied"
            break;
          case 0:
            icon = ''
            icon = '<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="currentColor"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z"></path></svg>'
            message = "Not satisfied"
            break;
        }

        const dragIcon = `
        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 -960 960 960" fill="#6E757C">
            <path d="M360-175.386q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Zm240 0q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Zm-240-240q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Zm240 0q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Zm-240-240q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Zm240 0q-26.653 0-45.634-18.98-18.98-18.981-18.98-45.634t18.98-45.634q18.981-18.98 45.634-18.98t45.634 18.98q18.98 18.981 18.98 45.634t-18.98 45.634q-18.981 18.98-45.634 18.98Z"/>
        </svg>
        `
        const removeIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" class="feather feather-minus-circle mr-5" width="16" height="16"
           viewBox="0 0 24 24" fill="none" stroke="#BD3D44" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="8" y1="12" x2="16" y2="12"></line>
          </svg>
        `

        const removeBtn = `
        <form action="/articles/${articleId}/article_intents/${intent.id}" method="post">
          <input type="hidden" name="_method" value="delete">
          <button type="submit" class="btn-transparent btn text-danger dr__remove" 
                  title="Remove search intent"
                  data-turbo-confirm="Are you sure you want to remove this search intent?"
                  data-bs-custom-class="custom-tooltip-commentResolved"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left">
             ${removeIcon}
          </button>
        </form>
      `;
        const newDataRow = `<div class="data__row articleIntents__item" 
                id="article_intent_${intent.id}"
                data-id="${intent.id}"
                data-writer--sortable-target="sortableItem"                 
                data-sortable-order="${intent.position ?? 0}">
                <span tabindex="0" class="dr__dragIcon"
                   data-bs-custom-class="custom-tooltip-commentResolved" data-bs-placement="left"
                   data-bs-toggle="tooltip" title="Drag to change priority">
                    ${dragIcon}
                </span>
                <span  class="dr__name">${intent.intent}</span>
                <span class="dr__value label${intent.score}" data-bs-custom-class="custom-tooltip-commentResolved" 
                data-bs-placement="left" data-bs-toggle="tooltip" title="${message}">
                    ${icon}
                </span>
                ${removeBtn}
              </div>`
        // ADD INTENT
        $(intentsTableTarget).append(newDataRow)
      })

      const addNewBtn = `<div id="add_intent_component">
          <div id="new_article_intent"></div>
          <span id="add_new_intent_button">
            <a type="button"
               class="articleIntents__add-button"
               data-action="click->drafts--search-intent#submitForm"
               href="/articles/${articleId}/article_intents/new">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
               viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" 
               stroke-linecap="round" stroke-linejoin="round" 
               class="feather feather-plus-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>
              Add a new search intent
            </a>
          </span>
        </div>
        `

      $(intentsTableTarget).append(addNewBtn)
    } else {
      // RETURN ERROR MESSAGE
      $('p.no-intents').show();
    }

    initializeTooltips()
  }

  setSearchScore(){
    var intents = window.searchScore["intentObjects"]
      .sort((a, b) => a.position - b.position)
      .reduce((acc, intent) => {
        acc[intent.intent] = intent.score;
        return acc;
      }, {});

    var intentValues = Object.values(intents)
    var intentKeys = Object.keys(intents)
    var scoreToHex = {
      0: "#C8102E",
      1: "#D7344E",
      2: "#F19C39",
      3: "#F1C139",
      4: "#339C68",
      5: "#228252"
    }

    // SET SCORE
    $(this.searchScoreTarget).html(`${window.searchScore["score"]}<span>/100</span>`)

    // SET GRADIENT
    switch(intentKeys.length){
      case 5:
        var gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[4]]} 72deg, ${scoreToHex[intentValues[3]]} 144deg, ${scoreToHex[intentValues[2]]} 216deg, ${scoreToHex[intentValues[1]]} 288deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 4:
        var gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[3]]} 90deg, ${scoreToHex[intentValues[2]]} 180deg, ${scoreToHex[intentValues[1]]} 270deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 3:
        var gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[2]]} 120deg, ${scoreToHex[intentValues[1]]} 240deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 2:
        var gradientCss = `conic-gradient(from 90deg at 50% 50%, ${scoreToHex[intentValues[0]]} 0deg, ${scoreToHex[intentValues[1]]} 180deg, ${scoreToHex[intentValues[0]]} 360deg`
        break;
      case 1:
        var gradientCss = `${scoreToHex[intentValues[0]]}`
        break;
      default:
        var gradientCss = `#F0F0F0`
    }

    // UPDATE GRADIENT
    $('.sideBar__performance .searchScore').css("background", gradientCss)
  }

  handleStreamRender(event) {
    const intentsTableTarget = this.intentsTableTarget;

    // this updates the turbo stream element for the performance sidebar
    if (event.detail.newStream &&
      event.detail.newStream.attributes[1].nodeValue === 'article_intents') {

      setTimeout(() => {
        const newContent = $(intentsTableTarget).find('.articleIntents__item:last-child')
        newContent.addClass('data__row data__row-js')
        newContent.find('.btn-transparent').addClass('dr__remove')

        // bellow is for the search score calculation
        const input = tinymce.activeEditor.getContent({format: "text"});
        const wordCount = this.currentWords
        const headlineCount = this.currentHeadlines
        const linkCount = this.currentLinks

        fetch(`${window.articleId}/performance/search_score.json`, {
          headers: { accept: 'application/json' },
          body: JSON.stringify({
            input: input,
            wordCount: wordCount,
            headlineCount: headlineCount,
            linkCount: linkCount
          }),
          method: "POST"})
          .then( data => {
            return data.json();
          }).then( payload => {

          window.searchScore = {...payload};

          const intentObjects = window.searchScore.intentObjects
          const newIntentObject = intentObjects[intentObjects.length - 1]

          this.setSearchScore();

          switch (newIntentObject.score) {
            case 5:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>';
              var message = "Highly satisfied"
              break;
            case 4:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>';
              var message = "Mostly satisfied"
              break;
            case 3:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>';
              var message = "Loosely satisfied"
              break;
            case 2:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>';
              var message = "There's more work to do"
              break;
            case 1:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>';
              var message = "Not satisfied"
              break;
            case 0:
              var icon = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>';
              var message = "Not satisfied"
              break;
          }

          const searchIntentScore = `
            <span class="dr__value label${newIntentObject.score}" data-bs-custom-class="custom-tooltip-commentResolved"
              data-bs-placement="left" data-bs-toggle="tooltip" title="${message}">
                ${icon}
            </span>
          `
          const theRemoveButton = newContent.find('form:last-child')
          $(searchIntentScore).insertBefore(theRemoveButton)
        });

      }, 22)

    }
  }

  disconnect() {
    // testing this event
    document.removeEventListener('turbo:before-stream-render', (event) => this.handleStreamRender(event));
  }
}

