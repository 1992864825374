import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = [ "button" ]

    tour(e){
        var tourId = $(e.currentTarget).data("tour-id");
        console.log(`🔁 STARTING TOUR: ${tourId}`);

        // LAUNCH TOUR
        chmln.show(tourId);
    }

}
