import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    'button'
  ]


  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: sidebar--wordpress");
    initializeTooltips();
  }

  sync(){
    // GLOBALS
    var toast = this.toast;
    var buttonReset = this.buttonReset;

    // CHANGE BUTTON STATE
    this.buttonLoader();

    // MAKE REQUEST
    fetch(`/p/${window.projectShortcode}/articles/${window.articleId}/wordpress.json`, {
      headers: { accept: 'application/json' },
      method: "POST"})
    .then( data => {
      return data.json();
    }).then( payload => {
      // REMOVE LOADER
      if(payload.result == false){
        toast("alert", "We couldn't sync your article. Please try again.");
      } else {
        toast("notice", "Your article has been synchronised to WordPress.");
      }
      buttonReset();
    })
    .catch( error => {
      // CATCH ERROR
      toast("alert", "We couldn't sync your article. Please try again.");
      buttonReset();
    });
  }

  buttonLoader(){
    // LOADING BUTTON UI
    $('.integrationsModal .blackBtn .btn__default').hide();
    $('.integrationsModal .blackBtn .btn__loader').show();
    $('.integrationsModal .blackBtn').prop("disabled", true);
  }

  buttonReset(){
    // RESET LOADER
    $('.integrationsModal .blackBtn .btn__default').show();
    $('.integrationsModal .blackBtn .btn__loader').hide();
    $('.integrationsModal .blackBtn').prop("disabled", false);
  }

  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}
