import { Controller } from "@hotwired/stimulus"
import {isMobile} from "../../../utility_functions";

export default class extends Controller {
    static targets = ["buttonsContainer"]
    static values = { expanded: Boolean }

    connect(){
      this.expanded = false;
    }

    toggleButtons(){
        this.expandedValue = !this.expandedValue
        $(this.buttonsContainerTarget).toggleClass("expanded")
    }

    closeButtons(){
      if (isMobile()) {
        this.expandedValue = false
        $(this.buttonsContainerTarget).removeClass("expanded")
      }
    }

    changeState(id){
      // SET STATE
      $('#sidebar__container').data("sidebar-state", id);

      // MAKE SURE SIDEBAR IS NOT HIDDEN
      if (isMobile()) {
        $('.writer__ai-sidebar').removeClass("hidden");
      }
      window.dispatchEvent(new CustomEvent("sidebar:expand"));
      this.collapsed = false;

      // SET ACTIVE ICON
      $('.editor-sidebar a.nav-link').removeClass("active");
      $(`.editor-sidebar a.nav-link#${id}`).addClass("active");
    }

    research(e){
      const sidebarContainer = $('#sidebar__container')
      if (!isMobile()) {
        if(e && e.currentTarget && $(e.currentTarget).hasClass("active")){
          // REMOVE CLASS
          $(e.currentTarget).removeClass("active");
          // COLLAPSE SIDEBAR
          window.dispatchEvent(new CustomEvent("sidebar:collapse"));
          // MARK AS COLLAPSED
          this.collapsed = true;
        } else {
          // SET STATE
          this.changeState("research");

          // SET LOADING SCREEN
          sidebarContainer.html('<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"y="0px"width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml"attributeName="transform"type="rotate"from="0 25 25"to="360 25 25"dur="0.6s"repeatCount="indefinite"/></path></svg>');

          // GO BACK TO RESEARCH
          window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
        }
      } else {
        // ignore active class on mobile
        // SET STATE
        this.changeState("research");

        // SET LOADING SCREEN
        sidebarContainer.html('<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"y="0px"width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml"attributeName="transform"type="rotate"from="0 25 25"to="360 25 25"dur="0.6s"repeatCount="indefinite"/></path></svg>');

        // GO BACK TO RESEARCH
        window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
      }
    }

    sources(e){
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("sources");

        // SET LOADING SCREEN
        $('#sidebar__container').html(
          '<div class="skel-loader"><div class="skel-row"><div class="skel-col w-60 h-30 mb-30"></div></div><div class="skel-row"><div class="skel-col w-50 h-70 mb-20"></div><div class="skel-col w-50 h-70 mb-20"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div></div></div>'
        );

        // LOAD INTERFACE
        this.request(`${window.articleId}/sources/all.json`);
      }
    }

    performance(e){
      console.log("sidebar nav performance")
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("performance");

        // SET LOADING SCREEN
        $('#sidebar__container').html(
          '<div class="skel-loader"><div class="skel-row"><div class="skel-col w-60 h-30 mb-30"></div></div><div class="skel-row"><div class="skel-col seo-score mb-30"></div></div><div class="skel-row"><div class="skel-col w-50 h-30 mb-20"></div></div><div class="skel-row"><div class="skel-col w-100 h-70 mb-30"></div><div class="skel-col w-100 h-70 mb-30"></div><div class="skel-col w-100 h-70 mb-30"></div></div><div class="skel-row"><div class="skel-col w-50 h-30 mb-20"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-50 mb-10"></div></div></div>'
        );

        // GO BACK TO PERFORMANCE
        this.request(`${window.articleId}/performance.json`);
      }
    }

    wordpress(e){
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("wordpress");
        // GO BACK TO WORDPRESS
        this.request(`/p/${window.projectShortcode}/sidebar/${window.articleId}/wordpress.json`);
      }
    }

    plagiarism_audit(e){
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("plagiarism_audit");

        // SET LOADING SCREEN
        $('#sidebar__container').html(
          ''
        );

        // GO BACK TO PERFORMANCE
        this.request(`/p/${window.projectShortcode}/sidebar/${window.articleId}/plagiarism_audit.json`);
      }
    }

    meta_description(e){
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("meta_description");

        // SET LOADING SCREEN
        $('#sidebar__container').html(
          '<div class="skel-loader"><div class="skel-row"><div class="skel-col w-60 h-30 mb-30"></div></div><div class="skel-row"></div><div class="skel-row"><div class="skel-col w-100 h-100 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div></div>'
        );

        // GO BACK TO PERFORMANCE
        this.request(`/p/${window.projectShortcode}/sidebar/${window.articleId}/meta_description.json`);
      }
    }

    unsplash(e){
      if($(e.currentTarget).hasClass("active")){
        // REMOVE CLASS
        $(e.currentTarget).removeClass("active");
        // COLLAPSE SIDEBAR
        window.dispatchEvent(new CustomEvent("sidebar:collapse"));
        // MARK AS COLLAPSED
        this.collapsed = true;
      } else {
        // SET STATE
        this.changeState("unsplash");

        // SET LOADING SCREEN
        $('#sidebar__container').html(
          '<div class="skel-loader"><div class="skel-row"><div class="skel-col w-60 h-30 mb-30"></div></div><div class="skel-row"></div><div class="skel-row"><div class="skel-col w-100 h-100 mb-10"></div></div><div class="skel-row"><div class="skel-col w-100 h-40 mb-10"></div></div></div>'
        );

        // GO BACK TO PERFORMANCE
        this.request(`/p/${window.projectShortcode}/sidebar/${window.articleId}/unsplash.json`);
      }
    }

    request(url){
      // MAKE PARTIAL HTML REQUEST
      fetch(url, {
        headers: { accept: 'application/json' }})
      .then( data => {
        return data.json();
      }).then( payload => {
        $('#sidebar__container').html(payload.html);
      });
    }
}
