import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.addEventListener("flash", this.flash.bind(this));
  }

  disconnect() {
    document.removeEventListener("flash", this.flash.bind(this));
  }
  flash({ detail: { mode, message }}) {
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}
