import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "record",
    "checkAll",
  ]

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: bulk-select")

    // SET WINDOW VAR FOR ACCESS
    window.selectedIds = []
  }

  toggleSelectAll(e){
    window.selectedIds = [];
    const state = $(e.currentTarget).is(':checked');
    const changeArray = this.changeArray;

    $(this.recordTargets).each(function() {
      const id = $(this).attr("id");
      changeArray(id, state);

      // Toggle the 'selected' class on the parent row of each checkbox
      $(this).closest('tr').toggleClass('selected', state);

      // Update the checked state of each checkbox
      $(this).prop("checked", state);
    });

    if ( state ){
      $(this.recordTargets).prop("checked", true);
    } else {
      $(this.recordTargets).prop("checked", false);
    }
  }

  toggleSelect(e){
    const id = $(e.currentTarget).attr("id");
    const state = $(e.currentTarget).is(':checked');

    this.changeArray(id, state);
  }

  changeArray(id, state){
    if( state ){
      window.selectedIds.push(id);
    } else {
      var index = window.selectedIds.indexOf(id);
      if (index > -1) {
        window.selectedIds.splice(index, 1);
      }
    }

    if (window.selectedIds.length > 0){
      $('#bulkActionDropdown').show();
    } else {
      $('#selectAll').prop("checked", false);
      $('#bulkActionDropdown').hide();
    }
  }

  onRowClick(e) {
    const clickedElement = e.target;

    if (clickedElement.tagName === 'A') {
      // Prevent the event from propagating or bubbling up
      event.stopPropagation();
      // Your handling code for the <a> tag with class 'title-column' here
    } else {
      const row = $(e.currentTarget).closest('tr'); // Find the closest tr ancestor
      const input = row.find('.check-table');
      const id = input.attr('id');
      const currentState = input.prop('checked'); // Get the current state
      const newState = !currentState; // Toggle the state

      // Update the checkbox state
      input.prop('checked', newState);

      // Toggle the 'selected' class on the tr based on the new state
      row.toggleClass('selected', newState);

      this.changeArray(id, newState);
    }
  }
}