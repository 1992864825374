import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import 'select2'
import {showModal} from "../../utility_functions";

export default class extends Controller {


  closeManualModal() {
    Turbo.visit(window.location.href)
  }

  openAssignToClusterModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const selectedArticles = [...(new Set(window.selectedIds))];
    if (selectedArticles.length > 0){
      // ASSIGN IDS TO FORM
      $('#articleAssignToClusterModal #article_article_ids').val(selectedArticles);
      // SHOW MODAL
    } else {
      return;
    }

    showModal('articleAssignToClusterModal')

    $('#articleAssignToClusterModal #article_content_category_id').select2({
      placeholder: 'Select a cluster',
      minimumResultsForSearch: 3,
      width: '100%',
      dropdownParent: $('#articleAssignToClusterModal')
    }).on("select2:opening", this.attachInputListener.bind(this))
        .on("select2:closing", this.detachInputListener.bind(this));

    this.cleanExtraSelect2Elements()
  }


  attachInputListener() {
    setTimeout(() => {
      const inputField = document.querySelector('.select2-search__field');
      if (inputField) {
        inputField.addEventListener('input', this.handleInputChange);
      }
    }, 0);
  }

  detachInputListener() {
    const inputField = document.querySelector('.select2-search__field');
    if (inputField) {
      inputField.removeEventListener('input', this.handleInputChange);
    }
  }


  handleInputChange(event) {
    const inputField = event.target;
    if (inputField.value !== "") {
      inputField.style.backgroundColor = "white";
    } else {
      inputField.style.backgroundColor = "transparent"; // reset to default or other color
    }
  }
  
  toast(mode, message){
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }

  cleanExtraSelect2Elements() {
    // Find all extra select2 elements
    const selectElements = document.querySelectorAll('.select2 + .select2');
    if (selectElements.length > 0){
      selectElements.forEach((element) => {
        element.remove()
      });
    }
  }
}
