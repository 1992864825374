// app/javascript/controllers/checkbox_group_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["box"]

  connect() {
    console.log("connected radio checkbox")
  }

  toggle(event) {
    console.log("Checkbox toggle triggered");

    const checkbox = event.target.closest('label') ? event.target.control : event.target;

    // Deselect all checkboxes
    this.boxTargets.forEach(cb => {
      cb.checked = false;
    });

    // Check the clicked checkbox
    checkbox.checked = true;
  }
}