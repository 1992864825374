import {Controller} from "stimulus";

const pricingPlans = {
  "grow": {
    "yearly": 48,
    "monthly": 60
  },
  "pro": {
    "yearly": 299,
    "monthly": 359
  }
}

export default class extends Controller {

  static targets = [
    'input',
    'calculatePrice',
    'plan'
  ];

  connect() {
    this.mode = "yearly";
    this.project_shortcode = this.data.get("projectShortcode");
  }

  decrement() {
    let oldValue = parseInt(this.inputTarget.value, 10) || 0;
    if (oldValue > 1) {
      this.adjustValue(-1);
      const newValue = parseInt(this.inputTarget.value, 10) || 0;
      this.setPricing()
    }
  }

  increment() {
    this.adjustValue(1);
    let newValue = parseInt(this.inputTarget.value, 10) || 0;
    this.setPricing()
  }

  adjustValue(value) {
    let input = this.inputTarget;
    let currentValue = parseInt(input.value, 10) || 0;
    let newValue = currentValue + value;

    if (newValue >= 0) {
      input.value = newValue;
    }
  }

  switchMode() {
    if (this.mode == "yearly") {
      this.mode = "monthly";
    } else {
      this.mode = "yearly";
    }

    this.setPricing();
  }

  setPricing() {
    let input = this.inputTarget;
    var mode = this.mode;
    var project_shortcode = this.project_shortcode;
    let calculatePrice = this.calculatePrice

    $(this.planTargets).each(function () {
      let plan = $(this).data("plan");
      let priceBase = pricingPlans[plan][mode];
      let packagePrice = calculatePrice(input, priceBase, plan, mode)

      // DEFAULT TO MONTHLY PRICING. THINK THIS WILL HAVE A HIGHER CONVERSION RATE.
      // var link = `/p/${project_shortcode}/settings/billing/plan?cycle=monthly&plan=${plan}`
      if (packagePrice) {
        $(this).find('.plan__price').text(`$${packagePrice}`);
      } else {
        $(this).find('.plan__price').text(`$${priceBase}`);
      }
    });
  }

  calculatePrice(input, base, plan, mode) {
    let drafts = input.value || 0;
    let extra = 0;
    if (plan === 'grow' && mode === 'monthly') {
      extra = (drafts - 20);
    } else if (plan === 'grow' && mode === 'yearly') {
      extra = (drafts - 20);
    }

    let price = (base + extra);
    return price > base ? price : base;
  }
}