import { Controller } from "@hotwired/stimulus"

import TurndownService from 'turndown'
import Cookies from "js-cookie";

export default class extends Controller {
    // THIS CONTROLLER IS DESIGNED TO CLEAN UP AND VALIDATE THE EXISTENCE OF COMMENTS
    // RELIES ON A DATA STORE IN HTML
    // TRIGGER BY WINDOW EVENT

    static targets = [
      "launcher",
      "counter"
    ]

    connect(){
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--helper");

      // UPDATE TO LATEST DATA STORE
      this.shortcodes = $('#data__store #data__store_comments').data("value");

    }

    fullCheck(){
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--helper#fullCheck");

      // INITIAL AI ANALYSIS
      // if(typeof this.lastCheck == 'undefined' || ((new Date() - this.lastCheck) / 1000) > 20 ){
        // this.lastCheck = new Date();

        // GET HTML
        // var html = tinymce.activeEditor.getContent({format : 'html'});

        // // CONVERT TO MARKDOWN
        // var turndownService = new TurndownService(
        //     {
        //         headingStyle: 'atx',
        //         horizontalRule: '-'
        //     });
        // var markdown = turndownService.turndown(html);

        // this.executeCheck(markdown);
      // }
    }

    executeCheck( input ){
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--helper#executeCheck");
      //  GET AI REVIEW OF CONTENT
      var addComment = this.addComment;
      var launcherTarget = this.launcherTarget;
      var toast = this.toast;

      fetch(`${window.articleId}/comments/analyse.json`, {
        headers: { accept: 'application/json' },
        body: JSON.stringify({input: input}),
        method: "POST"})
      .then( data => {
        return data.json();
      }).then( payload => {
        console.log(payload.result);
          if((payload.result == false) || (typeof payload.result == 'undefined')){
            // UPDATE UI
            $('.comments__container .btn').removeClass('active');
            toast("notice", "Your Cowriter has no suggestions.");
            // TODO! - this has not been updated in a while....
          } else {

            if(payload.result.length != 0){
              // CREATE COMMENTS
              $(payload.result).each(function( i ){
                addComment(this);
              });

              // UPDATE LAUNCHER TARGET
              $(launcherTarget).addClass("comments-exist");

              // UPDATE UI
              window.dispatchEvent(new CustomEvent("comments:allcomments"));
            }
          }
      });
    }

    aiReviewRun(event) {
      // GET HTML
      var html = tinymce.activeEditor.getContent({format : 'html'});

      // CONVERT TO MARKDOWN
      var turndownService = new TurndownService(
        {
          headingStyle: 'atx',
          horizontalRule: '-'
        });

      // REGEX REPLACE TO FIX HTML > MARKDOWN LIST SPACING BUG
      var markdown = turndownService.turndown(html).replace(/   +/g, ' ');

      // TRIGGER CHECK
      this.executeCheck(markdown);
    }

    aiReview( event ){
        setTimeout(() => {
          this.aiReviewRun(event);
        }, 2000)
    }

    addComment( comment ){
      var shortcodes = $('#data__store #data__store_comments').data("value");
      // LOOP THROUGH CONTENT ELEMENTS
      // CURRENTLY ONLY FOR P
      // OTHER ELS NEED TESTING, E.G LISTS
      $('#tinymce').find('p, h2, h3, li, blockquote, th, td').each(function () {
        // CHECK IF MISTAKE EXISTS
        if(~$(this).text().indexOf(comment.selection)){
          // NEED TO MAKE SURE NO COMMENTS EXIST ALREADY IN THE SAME PLACE
          // UPDATE TEXT
          var encoded_selection = $("<div></div>").text(comment.selection).html();
          var modified = $(this).html().replace(encoded_selection, `<span class="comment" data-comment="${comment.shortcode}">${comment.selection}</span>`);

          // REPLACE EXISTING IN DOM
          $(this).html(modified);

          // UPDATE DATA STORE
          shortcodes.push(comment.shortcode);
          $('#data__store #data__store_comments').data("value", shortcodes);

          // AUTOSAVE EVENT
          window.dispatchEvent(new CustomEvent("tinymce:change"));

          // QUIT LOOP
          return false;
        }
      });
    }

    validate(){
      // VALIDATES THAT COMMENTS SHOULD EXIST IN UI
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--helper#validate");
      // UPDATE TO LATEST DATA STORE
      this.shortcodes = $('#data__store #data__store_comments').data("value");

      if(this.shortcodes.length != 0){
        $(this.launcherTarget).addClass("comments-exist");
      } else {
        $(this.launcherTarget).removeClass("comments-exist");
      }

      // RUN CHECKS
      this.checkDatabase();

      // COUNT COMMENTS
      this.setCommentsCounter();
    }

    setCommentsCounter(){
      var commentCount = this.shortcodes.length;

      if (commentCount > 0){
        $('.comment__counter').show();
        $(this.counterTargets).text(commentCount);
      } else {
        $('.comment__counter').hide();
      }
    }

    checkDatabase(){
      var shortcodes = this.shortcodes;
      $('#tinymce .comment').each(function( i ){
        if(jQuery.inArray($(this).attr("data-comment"), shortcodes) == -1) {
          $(this).contents().unwrap();
        }
      });
    }

    toast(mode, message){
      var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
      var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

      $('.toast').remove();
      $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
      var toast = new bootstrap.Toast($('.toast'));
      toast.show();
    }
}
