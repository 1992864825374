import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    redirectUrl: String
  }

  connect() {
    this.modal = new bootstrap.Modal(this.element)
    this.modal.show()
  }

  hide() {
    this.modal.hide()
    this.element.remove()
    if (this.hasRedirectUrlValue) {
      Turbo.visit(this.redirectUrlValue, { action: 'replace' })
    }
  }
}
