import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tooltip" ]

  connect(){
    this.tooltipTargets.map((target) => {
      if ( target.dataset.bsTitle !== "" ) {
        new bootstrap.Tooltip(target)
      }
    })
  }
}
