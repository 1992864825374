import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import Cookies from "js-cookie"
import {
	numberWithCommas,
	removeAiCaret,
	autosaveState, cleanHtml
} from "../../../utility_functions";

const LEAVING_PAGE_MESSAGE = "Autosave has not finished saving your changes. Are you sure you would like to discard these changes?"

export default class extends Controller {

	change(){
		console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--autosave#change");

		// SET SAVED STATE
		$('#tinymce').data("saved", "false");

		// START TIMEOUT
		clearTimeout(this.timeout);
		this.timeout = setTimeout(this.save, 3000);

		// UPDATE SIDEBARS ON CHANGES
    var sidebarState = $('#sidebar__container').data('sidebar-state');
    if(sidebarState === 'info'){
      window.dispatchEvent(new CustomEvent("info:update"));
    } else if (sidebarState === 'performance'){
    	window.dispatchEvent(new CustomEvent("performance:update"));
    }

    // UPDATE WORD COUNT
    if (Cookies.get('viewWordCount') == 'true'){
			var wordcount = tinymce.activeEditor.plugins.wordcount;
			$('.viewWordCount span.totalWordCount').text(`${numberWithCommas(wordcount.body.getWordCount())} Words`);
    }
	}

	save(){
		if (autosaveState.shouldPreventAutosave()) {
			clearTimeout(this.timeout);
			return;
		}
		// ENSURE CARETS DO NOT HANG
		removeAiCaret(tinymce.activeEditor);
		// GET CONTENT
		var html = tinymce.activeEditor.getContent({format: 'html'});
		var text = tinymce.activeEditor.getContent({format: 'text'});
		var title = $('textarea#title').val();

		// cleans both .emph__selection and .hideThis classes (used for the UI)
		const cleanedHtml  = cleanHtml(html)

		// SET VALUES
		$('#draft_title').val(title);
		$('#draft_html').val(cleanedHtml);
		$('#draft_text').val(text);

		// FIREFOX PATCH
		// JQUERY SUBMIT() WILL NOT WORK ON FF
		var form = document.querySelector('#autosave-form');
		Rails.fire(form, 'submit');

		// IF PERFORMANCE SIDEBAR, TRIGGER CHECK
    var sidebarState = $('#sidebar__container').data('sidebar-state');
    if(sidebarState === 'performance'){
    	window.dispatchEvent(new CustomEvent("performance:getSearchScore"));
  	}

		// UPDATE SAVED STATE
		$('#tinymce').data("saved", "true");
	}

  leavingPage(event) {
  	console.log($('#tinymce').data("saved"));
    if ($('#tinymce').data("saved") == "false") {
      if (event.type == "turbo:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) { 
          event.preventDefault();
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE;
        return event.returnValue;
      }
    } 
  }

	saved(){
		console.log("AUTOSAVE ✅")
		// CHANGE TITLE
		document.title = `${$('#title').val()} | Reword`;
	}

	error(event){
		console.log("AUTOSAVE ERROR ❌");
    const [ , , res] = event.detail
    if (res.status === 401) {
      $("#logoutModal").modal("show")
    }
	}
}
