import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";

import {cleanInputs, toast} from "../../utility_functions";
import $ from "jquery";

window.sharedCounter = window.sharedCounter || 0;

export default class extends Controller {
    static targets = [
        'container',
        'element',
        'form',
        'form2',
        'form3',
        'form4',
        'addForm',
        'menu'
    ]

    connect() {
        console.log("FILTERS CONTROLLER - connected")

        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        urlParams.forEach((value, key) => {
            if (key.startsWith('filter_')) {
                urlParams.delete(key);
                Turbo.visit(`${window.location.pathname}?${urlParams}`)
            }
        })

        // for debugging
        this.logParams()

        this.closeMenu = this.closeMenu.bind(this);
        this.clearLastParam = this.clearLastParam.bind(this);
        window.addEventListener('filters:closeMenu', this.closeMenu)

        // this to hide wild dropdown
        const menuBug =  document.querySelector('.dropdown-menu.show')
        if (menuBug && menuBug.classList.contains('show')) {
            menuBug.classList.remove('show')
        }
        // hides button bug
        const btnBug = document.querySelector('.addFilter__btn')
        const svg = '<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>'
        if (btnBug && btnBug !== svg) {
            btnBug.innerHTML = svg
        }

        const wildAnd = document.querySelector('.and__seperator:nth-last-child(2)')
        if (wildAnd) {
            wildAnd.remove()
        }

        // START OF PARAMS CODE
        let params = new URL(window.location.href).searchParams;
        let filters = this.decodeFilters(params);
        filters.forEach((item, index) => {

            let operator = item.operator
            if (operator) {
                if (operator.includes('number'))
                    operator = operator.replace('number', '')
                if (operator.includes('date'))
                    operator = operator.replace('date', '')
                if (operator.includes('string'))
                    operator = operator.replace('string', '')


                const selector = `.filter__label[data-search-param-keys='${JSON.stringify(item)}']`
                const labelExists = document.querySelector(selector)
                if (!labelExists) {
                this.createFilterLabel(item)
                }
            }
        });
        // END  OF PARAMS CODE
    }

    encodeFilters(filters) {
        let params = new URLSearchParams();
        for(let i = 0; i < filters.length; i++) {
          if (filters[i].name) params.append(`filters[][name]`, filters[i].name);
          if (filters[i].association) params.append(`filters[][association]`, filters[i].association);
          if (filters[i].field) params.append(`filters[][field]`, filters[i].field);
          if (filters[i].operator) params.append(`filters[][operator]`, filters[i].operator);
          if (filters[i].value) params.append(`filters[][value]`, filters[i].value);
          if (filters[i].type) params.append(`filters[][type]`, filters[i].type);
        }
        return params;
    }

    decodeFilters(params) {
        const filters = [];
        const nameParams = params.getAll('filters[][name]')
      	const associationParams = params.getAll('filters[][association]')
        const fieldParams = params.getAll('filters[][field]');
        const operatorParams = params.getAll('filters[][operator]');
        const valueParams = params.getAll('filters[][value]');
        const typeParams = params.getAll('filters[][type]')
        for(let i = 0; i < fieldParams.length; i++) {
          const filter = {};
          if (nameParams[i]) filter.name = nameParams[i];
          if (associationParams[i]) filter.association = associationParams[i];
          if (fieldParams[i]) filter.field = fieldParams[i];
          if (operatorParams[i]) filter.operator = operatorParams[i];
          if (valueParams[i]) filter.value = valueParams[i];
          if (typeParams[i]) filter.type = typeParams[i];
          filters.push(filter);
        }
        return filters;
    }

    logParams() {
        const params = new URL(window.location.href).searchParams;
        const filters = this.decodeFilters(params);
        console.log("new logparams filters: ", filters);
    }

    setParams(data, filter) {
        let newParams = new URL(window.location.href).searchParams;
        console.log("newParams: ", newParams)
        let filters = this.decodeFilters(newParams);
        const keysData = Object.keys(data).map(key => key)
      	const name = document.querySelector(`[data-filter-value="${filter}"]`).dataset.filterName
      	const association = document.querySelector(`[data-filter-value="${filter}"]`).dataset.filterAssociation

        let operator = keysData[1]
        const valueKey = keysData[1]

        // console.log("filters: ", filters)
        // console.log("keysData: ", keysData)
        // console.log("name: ", name)
        // console.log("association: ", association)

        let type = ''
        if (operator) {
            if (operator.includes('radioNumber')) {
                operator = operator.replace('radioNumber_', '')
                type = 'number'
            }

            if (operator.includes('radioDate')) {
                operator = operator.replace('radioDate_', '')
                type = 'date'
            }

            if (operator.includes('string')) {
                operator = operator.replace('string_', '')
                type = 'string'
            }

            if (operator.includes('indexed')) {
                operator = operator.replace('indexed_', '')
                type = 'indexed'
            }

        }

        const newParamEntry = {
            value: data[valueKey],
            association: association ? association : null,
            name: name,
            type: type,
            field: filter.toLowerCase(),
            operator: operator
        }
        filters.push(newParamEntry)

	const otherParams = new URLSearchParams();
	for (const [key, value] of newParams.entries()) {
	    if (!key.startsWith('filters[')) {
		otherParams.set(key, value);
	    }
	}

        const updatedParams = this.encodeFilters(filters)
	const finalParams = new URLSearchParams(updatedParams.toString() + '&' + otherParams.toString());
        const urlToVisit = `${window.location.pathname}?&${finalParams}`;

        return Turbo.visit(urlToVisit);
    }

    apply(event) {
        event.preventDefault()
        let params = new URLSearchParams(window.location.search);
        const filter = params.get(`filter_${window.sharedCounter}`);
        const form = this.formTarget;
        const formData = new FormData(form)

        let allData = {}

        for (let [key, value] of formData.entries()) {

            if (value) {
                allData[key] = value
            }
        }

        let form2 = this.form2Target;
        let form2Data = new FormData(form2)
        let allData2 = {}

        for (let [key, value] of form2Data.entries()) {
            if (value) {
                allData2[key] = value
            }
        }


        let form3 = this.form3Target;
        let form3Data = new FormData(form3)
        let allData3 = {}

        for (let [key, value] of form3Data.entries()) {
            if (value) {
                allData3[key] = value
            }
        }

        let form4 = this.form4Target;
        let form4Data = new FormData(form4)
        let allData4 = {}

        for (let [key, value] of form4Data.entries()) {
            console.log("key: ", key)
            console.log("value: ", value)
            if (value) {
                allData4[key] = value
            }
        }

        if ((Object.keys(allData).length < 2) &&
            (Object.keys(allData2).length < 2) &&
            (Object.keys(allData3).length < 2) &&
            (Object.keys(allData4).length < 2)) {
            this.closeMenu()
            toast("alert", "The filter was not applied.");
            return;
        }

        if (Object.keys(allData).length > 1) {
            return this.setParams(allData, filter)
        }
        if (Object.keys(allData2).length > 1) {
            return this.setParams(allData2, filter)
        }
        if (Object.keys(allData3).length > 1) {
            return this.setParams(allData3, filter)
        }
        if (Object.keys(allData4).length > 0) {
            return this.setParams(allData4, filter)
        }

        window.sharedCounter++
        cleanInputs()
    }

    formatVerdict(key) {
        const options = {"PASS": "Yes", "NEUTRAL": "No", "FAIL": "Fail"}
        return options[key];
    }

    createFilterLabel(item) {
        const spanAndElement = document.createElement('span');
        spanAndElement.textContent = ' and ';
        spanAndElement.className = 'and__seperator';
        const label = document.createElement('span')
        label.classList.add('filter__label')

        const spacedOperator = item.operator.replace(/_/g, " ")
        const isDate = item.type === 'date'
        const isVerdict = item.name === 'Indexed'

        label.dataset.searchParamKeys = JSON.stringify(item)
        // needs to be here because of the line above
        if (item.field) {
            item.field = item.field.replace(/_/g, " ") // Replace underscores with spaces
                .split(' ') // Split into words
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
                .join(' ') // Join the words back together
        }
        label.dataset.action = 'click->filters#removeFilterLabel keydown->filters#removeFilterLabel'
        label.setAttribute('tabindex', '0')

        label.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>' +
            item.name + ' ' +
            (!isVerdict ? spacedOperator : `is ${this.formatVerdict(item.value)}`) + ' ' +
            (!isVerdict ? item.value : '') + (isDate ? ' days ago' : '') +
            '<span class="filter__remove"><svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg></span>'

        const labelContainer = document.querySelector('.filters__container')
        const addLinkDropdown = document.querySelector('.addFilter__dropdown')
            labelContainer.insertBefore(spanAndElement, addLinkDropdown)
            labelContainer.insertBefore(label, addLinkDropdown)

    }

    filter(event) {
        event.stopImmediatePropagation()
        window.dispatchEvent(new CustomEvent('filters-dropdown:calculateDropdownPositioning'))

        const {filterName, filterAssociation, filterValue, filterType} = event.target.dataset;
        const forms = this.addFormTargets

        const url = new URL(window.location.href);
        url.searchParams.append(`filter_${window.sharedCounter}`, filterValue);

        window.history.replaceState({}, document.title, url.toString());

        const dropdown = document.querySelector('.addFilter__btn + .dropdown-menu');

        if (event.target.dataset.filterName !== 'Indexed') {
            dropdown.classList.remove('filter__radiocheckbox-active');
            dropdown.classList.add('step2');
        } else {
            dropdown.classList.add('filter__radiocheckbox-active');
        }

        forms.forEach(item => {
            if (item.dataset.filterType === filterType) {
                item.classList.add('open')
            } else {
                if (item.classList.contains('open')) item.classList.remove('open')
            }
        })

        // focus input here .radio-group label:first-child input
        const firstRadio = document.querySelector('.filter__menu.open .radio__group label:first-child input')
        if (firstRadio) {
            firstRadio.checked = true;
        }

        const mutateButton = document.querySelector('.addFilter__btn')
        mutateButton.classList.add('activeFilter')

        const spanElement = document.createElement('span');
        spanElement.classList.add('filter__label');
        spanElement.classList.add('inProgress');
        spanElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>' +
            filterValue.replace(/_/g, " ") // Replace underscores with spaces
            .split(' ') // Split into words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
            .join(' ') // Join the words back together
        // add on click effect to span element that would remove it
        // add the svg with the close circle too
        const spanRemove = document.createElement('span')
        spanElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>'
        spanElement.innerHTML += filterName
        spanElement.append(spanRemove)
        spanElement.addEventListener('click', () => {
            this.closeMenu()
        })

        mutateButton.innerHTML = '';
        mutateButton.append(spanElement)

        const spanAndElement = document.createElement('span');
        spanAndElement.classList.add('and__seperator')
        spanAndElement.classList.add('inProgress')
        spanAndElement.textContent = ' and '

        const addLinkDropdown = document.querySelector('.addFilter__dropdown')
        document.querySelector('.filters__container').insertBefore(spanAndElement, addLinkDropdown)

        const activeGroup = document.querySelector('.filter__date-menu.open')
        if (activeGroup) {
            const firstFormGroup = document.querySelector('.filter__menu.open .radio__group label:first-child + .form-group')
            if (firstFormGroup) {
                if (!firstFormGroup.classList.contains('show')) {
                    firstFormGroup.classList.add('show')
                }
            }
        }

        const firstRadioInput = document.querySelector('.filter__menu.open .radio__group label:first-child + .form-group input')
        if (firstRadioInput) {
            if (!firstRadioInput.classList.contains('show')) {
                firstRadioInput.classList.add('show')
            }
            firstRadioInput.focus()
        }

    }

    clearLastParam() {
        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        const keys = Array.from(params.keys())

        if (keys.length > 0) {

            const lastKey = keys[keys.length - 1]
            let secondLast = ''
            const theSlice = lastKey.slice(0,6)
            if (theSlice !== 'filter') {
                secondLast = keys[keys.length - 2]
                if (secondLast && secondLast.slice(0,5) === 'filter') {
                    params.delete(lastKey)
                    params.delete(secondLast)
                }
            } else {
                params.delete(lastKey)
            }

            url.search = params
            window.history.replaceState({}, document.title, url.toString())

        }
    }

    closeMenu() {
        const dropdown = document.querySelector('.addFilter__btn + .dropdown-menu')
        const mutateButton = document.querySelector('.addFilter__btn')
        mutateButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>'

        if (dropdown.classList.contains('show'))
            dropdown.classList.remove('show')

        dropdown.classList.remove('step2')
        dropdown.classList.remove('filter__radiocheckbox-active')

        const menus = document.querySelectorAll('.filter__menu');
        menus.forEach(menu => {
            if (menu.classList.contains('open')) menu.classList.remove('open')
        })

        const discardElements = document.querySelectorAll('.inProgress');
        discardElements.forEach(item => {
            item.remove()
        })

        this.clearLastParam()
        cleanInputs()
    }

    decapitalize(string) {
        return string.split(' ')
            .map(word => word.toLowerCase())
            .join('_')
    }

    filterIsEqual(filter1, filter2) {
        const keysOne = Object.keys(filter1)
        const keysTwo = Object.keys(filter2)

        if (keysOne.length !== keysTwo.length) {
            return false
        }

        for (let key of keysOne) {

            if (key === 'field' && (this.decapitalize(filter1[key]) !== this.decapitalize(filter2[key]))) {

                return false

            } else if (key !== 'field' && filter1[key] !== filter2[key]) { // needed cause of formating
                return false
            }

        }
        return true
    }

    removeFilterLabel(event) {
        if (event.type === 'keydown' && event.key !== 'Enter') {
            return;
        }

        const labelClicked = event.target
        labelClicked.classList.add('hidden')
        const theDataSet = JSON.parse(event.target.dataset.searchParamKeys)

        let params = new URL(window.location.href).searchParams;
        const filters = this.decodeFilters(params);

	const otherParams = new URLSearchParams();
	for (const [key, value] of params.entries()) {
	    if (!key.startsWith('filters[')) {
		otherParams.set(key, value);
	    }
	}

        labelClicked.previousElementSibling.remove()
        labelClicked.remove()

        if (theDataSet) {
            const newFilters = filters.filter(item => {
                return !this.filterIsEqual(item, theDataSet)
            })

            const updatedParams = this.encodeFilters(newFilters)
	    const finalParams = new URLSearchParams(updatedParams.toString() + '&' + otherParams.toString());
	    const urlToVisit = `${window.location.pathname}?${finalParams}`;
            Turbo.visit(urlToVisit, {action: 'replace'});
        }
    }

    disconnect() {
        window.removeEventListener('filters:closeMenu', this.closeMenu)
    }
}
