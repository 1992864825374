import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";
import {numberWithCommas} from "../../../utility_functions";

export default class extends Controller {

  static targets = ['listItem']

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--voice");

    this.voice = $('.writer--voice').data("generation-profile-id");
    this.setVoice();
    this.updateVoice();
  }

  change(e){
    this.voice = $(e.currentTarget).parent().data('generation-profile-id');
    this.setVoice(this.voice);
    this.updateVoice(this.voice);
  }

  setVoice(){
    $('.writer--voice').data("generation-profile-id", this.voice);
    $('.writer--voice ul li .dynamic-check-holder').hide();
    $(`.writer--voice ul li[data-generation-profile-id='${this.voice}'] .dynamic-check-holder`).show();
  }

  updateVoice(){
    setTimeout(() => {
      fetch(`${window.articleId}/voice.json?generation_profile_id=${this.voice}`, {
        headers: { accept: 'application/json' },
        method: "POST"})
        .then( data => {
          return data.json();
        }).then( payload => {
        this.voice = payload.result
        this.setVoice(this.voice);
      });
    }, 500);
  }

}
