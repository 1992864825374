import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'
import 'select2'

export default class extends Controller {
    static targets = ["select"]

    connect() {
        console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: drafts--index");
        console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: ui--select");
        const selectBox = $(this.element);

        const placeholder = selectBox.dataset.placeholder

        selectBox.select2({
            placeholder: placeholder,
            minimumResultsForSearch: 3,
            width: '100%',
        })

        console.log("sb- ", selectBox);
        // Attach event listener for opening the select2 dropdown
        $(this.element).on("select2:opening", this.attachInputListener.bind(this));

        // Cleanup event listener when select2 dropdown is closing
        $(this.element).on("select2:closing", this.detachInputListener.bind(this));
    }

    attachInputListener() {
        setTimeout(() => {
            const inputField = document.querySelector('.select2-search__field');
            if (inputField) {
                inputField.addEventListener('input', this.handleInputChange);
            }
        }, 0);
    }

    detachInputListener() {
        const inputField = document.querySelector('.select2-search__field');
        if (inputField) {
            inputField.removeEventListener('input', this.handleInputChange);
        }
    }

    handleInputChange(event) {
        const inputField = event.target;
        if (inputField.value !== "") {
            inputField.style.backgroundColor = "white";
        } else {
            inputField.style.backgroundColor = "transparent"; // reset to default or other color
        }
    }

    disconnect() {
        $(this.element).off("select2:opening", this.attachInputListener);
        $(this.element).off("select2:closing", this.detachInputListener);
    }

}
