import { Controller } from "@hotwired/stimulus"
import {initializeTooltips, isMobile} from "../../../utility_functions";

export default class extends Controller {
    static targets = [
      "commentBox",
      "action",
      "dropdown"
    ]

    connect(){
      console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: comments--all");

      window.handleClickOutside = this.handleClickOutside.bind(this);

      // VALIDATE COMMENTS
      window.dispatchEvent(new CustomEvent("comments:validate"));

      // SET SIDEBAR STATE
      // $('#sidebar__container').data("sidebar-state", "comments--all");
      $('.navSidebar .nav-link').removeClass("active");
      $('.nav-link#comments').addClass("active");

      // UPDATE TO LATEST DATA STORE
      this.shortcodes = $('#data__store #data__store_comments').data("value");

      // PREPARATION OF COMMENTS
      window.addEventListener('comments:allcomments', this.allComments.bind(this));
      window.addEventListener('comments:showdropdown', this.showDropdown.bind(this));
      window.addEventListener('comments:hidedropdown', this.hideDropdown.bind(this));
      document.querySelector('.commentsDropdown__container').addEventListener('hidden.bs.dropdown', this.hiddenEvent.bind(this));
      document.querySelector('.commentsDropdown__container').addEventListener('shown.bs.dropdown', this.shownEvent.bind(this));

      // PREP TOOLTIPS
      initializeTooltips();

      // DEFAULT TO ALL COMMENTS
      this.allComments();
    }

    toggleDropdown() {
      // DROPDOWN TOGGLE
      document.querySelectorAll('.dropdown-menu.show').forEach((dropdown) => {
        if (dropdown !== this.dropdownTarget) {
          dropdown.classList.remove('show');
          let parentDropdown = dropdown.parentElement;
          let bsDropdown = new bootstrap.Dropdown(parentDropdown);
          bsDropdown.hide();
        }
      });

      if ($(this.dropdownTarget).hasClass('show')) {
        this.hideDropdown();
      } else {
        this.showDropdown();

        // SHOW TOUR
        chmln.show('653988be444cef0014813b93', {once: true});
      }
    }

    hideDropdown(){
      // THIS IS REQUIRED, TO TRIGGER BS EVENT HOOKS
      //debugger;
      $('.commentsDropdown__container').dropdown('hide');

      $(this.dropdownTarget).removeClass("show");
      document.querySelector('.commentsDropdown__container').classList.remove("show");
    }

    hiddenEvent(){
      document.removeEventListener('click', window.handleClickOutside);

      // VALIDATE COMMENTS
      window.dispatchEvent(new CustomEvent("comments:validate"));

      // REMOVE ANY ACTIVE CLASSES
      $('#tinymce .comment').removeClass("selected");
    }

    showDropdown(){
      // PREPARE
      this.preparer();
      //debugger;
      const theTarget = $(this.dropdownTarget)
      // THIS IS REQUIRED, TO TRIGGER BS EVENT HOOKS
      $('.commentsDropdown__container').dropdown('show');
      // ADD CLASSES
      theTarget.addClass("show");
      document.querySelector('.commentsDropdown__container').classList.add("show");

      // SELECT A COMMENT
      this.selectCurrent();
    }

    shownEvent(){
      document.addEventListener('click', window.handleClickOutside);

      // VALIDATE COMMENTS
      window.dispatchEvent(new CustomEvent("comments:validate"));
    }

    handleClickOutside(event) {
      event.stopPropagation();
      const isInAddNew = document.querySelector('.comments__new')
      if (isInAddNew) {

        if (!event.target.closest('.comments__new')) {
          this.allComments();
          this.hideDropdown()
        }
        return
      }

     if (this.dropdownTarget.classList.contains('show') && !this.dropdownTarget.contains(event.target) && !$(event.target).hasClass("comment") && !$(event.target).hasClass("comment-dropdown-trigger")) {
       this.hideDropdown();
     }
    }

    allComments(){
      // MAKE PARTIAL HTML REQUEST
      const loaderHtml = '<div class="aiLoader__allComments">\n' +
        '<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>\n' +
        '  </div>'
      $(this.dropdownTarget).html(loaderHtml)
      fetch(`${window.articleId}/comments/all.json?source=dropdown.json`, {
        headers: { accept: 'application/json' }})
        .then( data => {
          return data.json();
        }).then( payload => {


          if (this.dropdownTarget) {
            $(this.dropdownTarget).html(payload.html);
          }

          // VALIDATE COMMENTS
          window.dispatchEvent(new CustomEvent("comments:validate"));

      });

      initializeTooltips();
    }

    newComment(){
      // TOOLTIP
      const hangedTooltips = document.querySelectorAll('.tooltip')
      if (hangedTooltips.length) {
        hangedTooltips.forEach((element) => {
          element.parentNode.removeChild(element);
        });
      }

      // DISPLAY NEW COMMENT UI
      this.request(`${window.articleId}/comments/new.json`);

      // OPEN DROPDOWN
      this.showDropdown();
    }

    request(url){
      // MAKE PARTIAL HTML REQUEST
      const loaderHtml = '<div class="aiLoader__allComments">\n' +
          '<svg version="1.1" id="loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40" height="40" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve"><path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"><animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.6s" repeatCount="indefinite"></animateTransform></path></svg>\n' +
          '  </div>'
      $(this.dropdownTarget).html(loaderHtml)
      fetch(url, {
        headers: { accept: 'application/json' }})
      .then( data => {
        return data.json();
      }).then( payload => {
        setTimeout(() => {
          if (this.dropdownTarget) {
            $(this.dropdownTarget).html(payload.html);
          }

          // VALIDATE COMMENTS
          window.dispatchEvent(new CustomEvent("comments:validate"));
        }, 750)

      });
      
      initializeTooltips();
    }

    preparer(){
      // PREPARE THE COMMENTS SIDEBAR
      const idValue = window.articleId;

      // CHECK ALL COMMENTS EXIST IN CONTENT
      // IF THEY DON'T, ADD THEM IF SELECTION STILL EXISTS
      $(this.commentBoxTargets).each(function( i ){
        // GET INFORMATION
        const comment_shortcode = $(this).data("comment-shortcode");
        const comment_selection = $(this).data("comment-selection");

        // CHECK IF CONTENT INCLUDES COMMENT ALREADY
        const content_missing = ($(`#tinymce .comment[data-comment='${comment_shortcode}']`).length == 0);

        // CHECK IF CONTENT INCLUDES SELECTION
        var selection_missing = true;
        $('#tinymce').find('p, h2, h3, li, blockquote, th, td').each(function () {
          if(~$(this).html().indexOf(comment_selection)){
            selection_missing = false;
          };
        });

        // REPAIRS
        if(selection_missing){
          // MAKE ARCHIVE REQUEST
          fetch(`${idValue}/comments/archived/${comment_shortcode}.json`, {
            headers: { accept: 'application/json' },
            method: "POST"})
          .then( data => {
            return data.json();
          }).then( payload => {
            // UPDATE DATA STORE
            $('#data__store #data__store_comments').data("value", payload.comments);
          });

          // REMOVE COMMENT
          $(`.comments__container #comment_${comment_shortcode}`).remove();
        } else if(content_missing){
          $('#tinymce').find('p, h2, h3, li, blockquote, th, td').each(function () {
            // CHECK IF MISTAKE EXISTS
            if(~$(this).html().indexOf(comment_selection)){
              // NEED TO MAKE SURE NO COMMENTS EXIST ALREADY IN THE SAME PLACE

              // UPDATE TEXT
              const encoded_selection = $("<div></div>").text(comment_selection).html();
              const modified = $(this).html().replace(encoded_selection, `<span class="comment" data-comment="${comment_shortcode}">${comment_selection}</span>`);

              // REPLACE EXISTING IN DOM
              $(this).html(modified);

              // AUTOSAVE EVENT
              window.dispatchEvent(new CustomEvent("tinymce:change"));

              // QUIT LOOP
              return false;
            }
          });
        }
      });

      // HIDE LOADER
      $('.sideBar__comment #ai-loader').hide();
      // // SHOW COMMENTS
      // $('.comments__container').show();
    }

    selectCurrent(){
      var selection = tinymce.activeEditor.selection.getNode();
      var shortcode = $(selection).data("comment");
      if(shortcode != undefined){
        var match = $(`#commentDropdownTarget #comment_${shortcode}`);
        if(match != undefined){
          $(match).trigger('click');
        }
      } else {
        // this was braking the mobile, had to localize
        if (!isMobile()) $('#commentDropdownTarget .comment__box').first().trigger('click');
      }
    }

    select(event){
    // ONLY SCROLL IF REQUIRED
    var shortcode = $(event.currentTarget).data('comment-shortcode');
    var selection = $('#tinymce').find(`.comment[data-comment='${shortcode}']`);

    if(selection.length != 0){
      // HANDLE CLASSES
      $(this.commentBoxTargets).removeClass('active');
      $(event.currentTarget).addClass('active');

      // REMOVE ANY ACTIVE CLASSES
      $('#tinymce .comment').removeClass("selected");

      // ADD CLASS
      $(selection).addClass("selected");

      if (isMobile()) {
        scrollToCenterInViewport(selection);
        // close the dropdown
        window.dispatchEvent(new CustomEvent("comments:hidedropdown"));
        return;
      }

      if(this.isInViewport(event.currentTarget, '#commentDropdownTarget') == false){
        $('#commentDropdownTarget').animate({
          scrollTop: ($(event.currentTarget).offset().top - 100)
        }, 300);
      }

      if(this.isInViewport(selection, '.editor__container') == false){
        $('.editor__container').animate({
          scrollTop: (($('.editor__container').scrollTop() - $('.editor__container').offset().top + $(selection).offset().top ) - 100)
        }, 300);
      }
    }
  }

    isInViewport(el, container){
      var elPosition = ($(container).scrollTop() - $(container).offset().top + $(el).offset().top);
      var containerHeight = $(container).height();
      var scrollPosition = $(container).scrollTop();
      var startingRange = (scrollPosition);
      var endingRange = (scrollPosition + containerHeight);
      return (elPosition > startingRange && elPosition < endingRange);
    }

    resolved( event ){
      event.preventDefault();
      event.stopPropagation();
      var comment_id = $(event.currentTarget).data("comment-id");

      // REMOVE ITEM FROM COMMENTS
      $(`.comment__box[data-comment-id="${comment_id}"]`).remove();

      fetch(`${window.articleId}/comments/resolved/${comment_id}.json`, {
        headers: { accept: 'application/json' },
        method: "POST"})
      .then( data => {
        return data.json();
      }).then( payload => {
        // UPDATE DATA STORE
        $('#data__store #data__store_comments').data("value", payload.comments);

        // CLEAN TOOLTIPS THAT STICK
        $('.tooltip').remove();

        // VALIDATE COMMENTS
        window.dispatchEvent(new CustomEvent("comments:validate"));
        // AUTOSAVE EVENT
        window.dispatchEvent(new CustomEvent("tinymce:change"));

        // IF NO MORE COMMENTS GO BACK TO EMPTY LOOK
        const comments = document.querySelectorAll('.comment__box.card');
        goToEmptyView(comments)
        this.hideDropdown()
        // TOAST NOTIFICATION
        this.toast('notice', "Comment marked as resolved.");
      });
    }

    ignored( event ){
      event.preventDefault();
      event.stopPropagation();
      var comment_id = $(event.currentTarget).data("comment-id");

      // REMOVE ITEM FROM COMMENTS
      $(`.comment__box[data-comment-id="${comment_id}"]`).remove();

      fetch(`${window.articleId}/comments/ignored/${comment_id}.json`, {
        headers: { accept: 'application/json' },
        method: "POST"})
      .then( data => {
        return data.json();
      }).then( payload => {
        // UPDATE DATA STORE
        $('#data__store #data__store_comments').data("value", payload.comments);

        // CLEAN TOOLTIPS THAT STICK
        $('.tooltip').remove();

        // VALIDATE COMMENTS
        window.dispatchEvent(new CustomEvent("comments:validate"));
        // AUTOSAVE EVENT
        window.dispatchEvent(new CustomEvent("tinymce:change"));

        // IF NO MORE COMMENTS GO BACK TO EMPTY LOOK
        const comments = document.querySelectorAll('.comment__box.card');
        goToEmptyView(comments)

        // TOAST NOTIFICATION
        this.toast('notice', "Comment marked as ignored.");
      });
    }

    toast(mode, message){
      var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
      var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]

      $('.toast').remove();
      $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
      var toast = new bootstrap.Toast($('.toast'));
      toast.show();
    }
}

function scrollToCenterInViewport(el) {
  const elPosition = $(el).offset().top;
  const viewportHeight = $(window).height();
  const elementHeight = $(el).outerHeight();

  const newScrollTopPosition = elPosition - (viewportHeight / 2) + (elementHeight / 2);

  if (window.innerWidth > 768) {
    $('html, body').animate({
      scrollTop: newScrollTopPosition
    }, 300);
  } else {
    const editorContainer = $('.editor__container');
    editorContainer.animate({
      scrollTop: newScrollTopPosition
    }, 300);
  }
}

function goToEmptyView(list) {
  if (!list.length) {
    window.dispatchEvent(new CustomEvent("comments:allcomments"));
  }
}