import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', this.handleLinkClick.bind(this))
  }

  handleLinkClick(event) {
    if (event.target.tagName === 'A') {
      this.doScrollTop();
    }
  }

  doScrollTop()  {
   this.element.scrollTop = 0;
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleLinkClick.bind(this))
  }
}