import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {isMobile} from "../../../utility_functions";

export default class extends Controller {
  static targets = [
    'h1',
    'textarea'
  ]

  adjusted = false;

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--title");
    // TRIGGER RESIZE
    this.adjustHeight();
  }

  change( event ){
    // CONTENT CHANGE
    this.adjustHeight();

    const customEvent = new CustomEvent("tinymce:change");
    window.dispatchEvent(customEvent);
    if (event.keyCode == '40' || event.keyCode == '13') {
      if(($(this.textareaTarget).text().length) == (event.target.selectionStart)){
        event.preventDefault();
        $('#tinymce').focus();
      }
    }
  }

  adjustHeight(){
    // CALCULATE HEIGHT
    if (!isMobile()) {
      this.textareaTarget.style.height = "1px";
      this.textareaTarget.style.height = (this.textareaTarget.scrollHeight + "px");
    } else {
      // needs the timeout, otherwise it's very large on the phone
      setTimeout(() => {
        if (!this.adjusted) {
          const title = $('#title');
          title.height(0);
          console.log("title.prop('scrollHeight'): ", title.prop('scrollHeight'))
          title.height(title.prop('scrollHeight') - 10);
          this.adjusted = true
        }
      }, 1000)
    }
  }

  focus(){
    // SET TOOLBAR BLOCK FORMAT
    $(".tox-tbtn--select.tox-tbtn--bespoke .tox-tbtn__select-label").text("Title");

    // OPEN SIDEBAR
    if($('.writer__ai-sidebar').data("sidebar-state") != "title_variants"){
      $("button[data-tag-type='title']").click();
    }

    if (isMobile()) {
      setTimeout(() => {
        window.scrollTo(0,0)
      }, 100)
    }
  }

  blur(){
    //
  }

}
