import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import consumer from "channels/consumer";
import Cookies from "js-cookie"
import {
  autosaveState,
  customSpellcheckAction,
  initializeTooltips,
  isMobile,
  isSafari,
  makeEditorTooltips,
  numberWithCommas,
  placeCursorAtClickPosition,
  reloadPage,
  removeAiCaret,
  unwrapSelectionFromSpan,
  wrapSelectionWithEmph,
  toast
} from "../../utility_functions";

import {notImplementedLangs} from "../../utility_functions";

export default class extends Controller {
  static targets = ['input', 'preloader', 'wsidebar', 'sidebar']

  initializeTinyMCE() {
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: tinymce");

    // METHODS
   // const aiLock = this.aiLock;
    const aiUnlock = this.aiUnlock;
    const article_id = window.articleId;
    const sanitize = this.sanitize;
    const addToSpellcheckIgnore = this.addToSpellcheckIgnore;
    const getIgnoredWords = this.getIgnoredWords;
    window.keyPhrases = this.getKeyPhrases(article_id);

    // SLASH COMMANDS PLUGIN
    tinymce.PluginManager.add('slashcommands', function (editor) {
      var insertActions = [
        {
          type: 'separator',
          text: 'AI Assistance'
        },
        {
          text: 'Cowriter',
          tooltip: "Cowriter",
          icon: 'aiCommand',
          classes: 'whatever',
          action: function () {
            editor.execCommand('aiCommand');
            editor.selection.select(editor.selection.getNode());
          }
        },
        {
          type: 'separator',
          text: 'Text'
        },
        {
          text: 'Paragraph',
          icon: 'p',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<p>Paragraph</p>');
            editor.selection.select(editor.selection.getNode());
          }
        },

        {
          text: 'Heading 2',
          icon: 'h2',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h2>Heading 2</h2>');
            editor.selection.select(editor.selection.getNode());
          }
        },
        {
          text: 'Heading 3',
          icon: 'h3',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h3>Heading 3</h3>');
            editor.selection.select(editor.selection.getNode());
          }
        },
        {
          text: 'Heading 4',
          icon: 'h4',
          action: function () {
            editor.execCommand('mceInsertContent', false, '<h4>Heading 4</h4>');
            editor.selection.select(editor.selection.getNode());
          }
        },
        {
          text: 'Quote',
          icon: 'blockquote',
          action: function () {
            editor.execCommand('mceBlockQuote');
          }
        },
        {
          type: 'separator',
          text: 'Formatting'
        },
        {
          text: 'Link',
          icon: 'link',
          action: function () {
            editor.execCommand('mceLink', false);
          }
        },

        {
          text: 'Bulleted list',
          icon: 'unordered-list',
          action: function () {
            editor.execCommand('InsertUnorderedList', false);
          }
        },
        {
          text: 'Numbered list',
          icon: 'ordered-list',
          action: function () {
            editor.execCommand('InsertOrderedList', false);
          }
        },
        {
          text: 'Image',
          icon: 'image',
          action: function () {
            editor.execCommand('mceImage');
          }
        },
        {
          text: 'Table',
          icon: 'table',
          action: function () {
            editor.execCommand('mceInsertTable', false, {rows: 3, columns: 2, options: {headerRows: 1}});
          }
        },

        {
          text: 'Research',
          icon: 'research',
          action: function () {
            window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
          }
        },
        {
          text: 'Comments',
          icon: 'comments',
          action: function () {
            window.dispatchEvent(new CustomEvent("sidebar:comments"));
          }
        },
        {
          text: 'Performance',
          icon: 'performance',
          action: function () {
            window.dispatchEvent(new CustomEvent("sidebar:performance"));
          }
        },
        {
          text: 'Information',
          icon: 'information',
          action: function () {
            window.dispatchEvent(new CustomEvent("sidebar:info"));
          }
        }
      ];

      // Register the slash commands autocompleter
      editor.ui.registry.addAutocompleter('slashcommands', {
        ch: '/',
        minChars: 0,
        columns: 1,
        fetch: function (pattern) {
          const matchedActions = insertActions.filter(function (action) {
            return action.type === 'separator' || action.type === 'reparator' ||
              action.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
          });

          return new Promise((resolve) => {
            var results = matchedActions.map(function (action) {
              return {
                meta: action,
                text: action.text,
                icon: action.icon,
                value: action.text,
                type: action.type
              }
            });
            resolve(results);
          });
        },
        onAction: function (autocompleteApi, rng, action, meta) {
          editor.selection.setRng(rng);
          // Some actions don't delete the "slash", so we delete all the slash
          // command content before performing the action
          editor.execCommand('Delete');
          meta.action();
          autocompleteApi.hide();
        },
        itemRenderer: function (item, escape) {
          var html = '<div class="custom-autocomplete-item">';

          // Customize the HTML structure of the autocomplete item
          html += '<span class="custom-autocomplete-icon">' + item.meta.icon + '</span>';
          html += '<span class="custom-autocomplete-text">' + item.meta.text + '</span>';

          html += '</div>';

          return html;
        }
      });
      return {};
    });

    const language = $('.writer--language').data("language");

    if (notImplementedLangs.includes(language)) {
      toast('alert', 'Spellcheck is not available for this language yet.');
      $('.spellCheck__dropdown').children('.dynamic-check-holder').hide();
      $('.spellCheck__dropdown-mobile').children('.dynamic-check-holder').hide();
    }

    let enableSpellcheck = (Cookies.get('enableSpellcheck') == 'true');

    if (!enableSpellcheck && !notImplementedLangs.includes(language)) {
      $('.toast').remove();
    } else if (enableSpellcheck && notImplementedLangs.includes(language)) {
      Cookies.set('enableSpellcheck', false);
      setTimeout(() => {
        this.reconnect();
      }, 2000);
    }

    this.defaults = {
      selector: '.tinymce',
      height: 500,
      placeholder: "Press the tab key for Cowriter assistance, or just start typing",
      inline: true,
      menubar: false,
      entity_encoding: 'raw',
      paste_auto_cleanup_on_paste: true,
      forced_root_blocks: false,
      end_container_on_empty_block: true,
      valid_elements: "@[id|class|style|title|dir<ltr?rtl|lang|xml::lang|onclick|ondblclick|"
        + "onmousedown|onmouseup|onmouseover|onmousemove|onmouseout|onkeypress|"
        + "onkeydown|onkeyup],a[rel|rev|charset|hreflang|tabindex|accesskey|type|"
        + "name|href|target|title|class|onfocus|onblur],strong/b,em/i,strike,u,"
        + "-p,-ol[type|compact],-ul[type|compact],-li,br,img[longdesc|usemap|"
        + "src|border|alt=|title|hspace|vspace|width|height|align],-sub,-sup,"
        + "-blockquote,-table[border=0|cellspacing|cellpadding|width|frame|rules|"
        + "height|align|summary|bgcolor|background|bordercolor],-tr[rowspan|width|"
        + "height|align|valign|bgcolor|background|bordercolor],tbody,thead,tfoot,"
        + "#td[colspan|rowspan|width|height|align|valign|bgcolor|background|bordercolor"
        + "|scope],#th[colspan|rowspan|width|height|align|valign|scope],caption,"
        + "-code,-pre,address,-h1,-h2,-h3,-h4,-h5,-h6,hr[size|noshade],-font[face"
        + "|size|color],dd,dl,dt,cite,abbr,acronym,del[datetime|cite],ins[datetime|cite],"
        + "object[classid|width|height|codebase|*],param[name|value|_value],embed[type|width"
        + "|height|src|*],map[name],area[shape|coords|href|alt|target],bdo,"
        + "button,col[align|char|charoff|span|valign|width],colgroup[align|char|charoff|span|"
        + "valign|width],dfn,fieldset,form[action|accept|accept-charset|enctype|method],"
        + "input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value],"
        + "kbd,label[for],legend,noscript,optgroup[label|disabled],option[disabled|label|selected|value],"
        + "q[cite],samp,select[disabled|multiple|name|size],small,"
        + "textarea[cols|rows|disabled|name|readonly],tt,var,big",
      fixed_toolbar_container: "#writer__toolbar",
      plugins: 'searchreplace slashcommands autolink powerpaste lists link image preview visualblocks fullscreen media table help wordcount quickbars tinydrive' +
        (enableSpellcheck ? ' tinymcespellchecker' : '') +
        (language === 'en-us' || language === 'en-gb' ? ' autocorrect ' : ''),
      block_formats: 'Paragraph=p; Header 2=h2; Header 3=h3; Header 4=h4; Quote=blockquote;',
      tinydrive_token_provider: `/p/${window.projectShortcode}/jwt/tinydrive.json`,
      image_dimensions: false,
      text_patterns: [
        {start: '1. ', cmd: 'InsertOrderedList'},
        {start: '* ', cmd: 'InsertUnorderedList'},
        {start: '- ', cmd: 'InsertUnorderedList'}
      ],
      formats: {
        pending_comment: {inline: 'span', classes: 'comment'},
        comment: {inline: 'span', attributes: {'class': 'comment', 'data-comment': '%shortcode'}}
      },
      table_default_styles: {
        width: '100%'
      },
      table_sizing_mode: 'relative',
      table_column_resizing: 'preservetable',
      table_default_attributes: {},
      table_resize_bars: false,
      content_langs: [
        {title: 'English (US)', code: 'en_US'},
        {title: 'English (US Medical)', code: 'en_US', customCode: 'en_US-medical'},
        {title: 'English (UK)', code: 'en_UK'},
        {title: 'English (UK Medical)', code: 'en_UK', customCode: 'en_UK-medical'},
        {title: 'Spanish', code: 'es-ES'},
        {title: 'Spanish Latin America', code: 'es-MX'},
        {title: 'French', code: 'fr'},
        {title: 'German', code: 'de'},
        {title: 'Portuguese', code: 'pt'},
        {title: 'Chinese', code: 'zh'},
        {title: 'Italian', code: 'it'},
        {title: 'Bulgarian', code: 'bg'},
      ],
      object_resizing: false,
      contextmenu: 'spellchecker | copy paste | link image inserttable | table',
      language: 'en', // UI LANGUAGE
      spellchecker_active: enableSpellcheck,
      spellchecker_language: (language && !notImplementedLangs.includes(language)) ? language : false,
      spellchecker_dialog: false,
      spellchecker_ignore_list: {
        en_US: [...getIgnoredWords(), 'Cowriter', 'cowriter']
       },
      autocorrect_autocorrect: true,
      autocorrect_capitalize: true,
      toolbar: ' aiCommand | ' +
        ' blocks | ' +
        ' bold italic underline | ' +
        ' link image insertfile | ' +
        ' bullist numlist table | ' +
        ' alignleft aligncenter alignright | ' +
        ' outdent indent | ' +
        ' newComment allComments | ' +
        ' strikethrough hr |' +
        ' undo redo',
      quickbars_selection_toolbar:
        "aiCommandSmall | bold italic underline | link | newComment",
      quickbars_insert_toolbar: "aiCommandSmall",
      init_instance_callback: function (editor) {

        // TRICK EDITOR TO SHOW TOOLBAR
        editor.fire('focus');

        // firing on any command
        editor.on('ExecCommand', async function (e) {
          //console.log("e.command: ", e.command)

          if (e.command === "mceFocus") {
               if (isMobile()) { // NOTE - when working on the tablets
              setTimeout(() => {
                //if (window.scrollTop > 0) window.scrollTo(0,0)
              }, 100)
            }
          }

          // on link or image modal open
          if (e.command === "mceLink" || e.command === "mceImage") {

            $(".navbar").addClass("openModal");
            setTimeout(() => {
              // timeout is set because backdrop does not exist when function runs...
              try {
                // Use [0] to access the first element of the jQuery object (native DOM element)
                const backdropElement = $('.tox-dialog-wrap__backdrop')[0];
                // Add a click event listener to the backdrop element
                backdropElement.addEventListener('click', () => {
                  $('.tox-dialog__footer-end button:first-child').click();
                });
              } catch (error) {
                console.error("error in add link")
              }
            }, 200);
          }

          // on link modal close (this fires a lot)
          if (e.command === "unlink" || e.command === "mceFocus") {
            $(".navbar.openModal").removeClass("openModal");
          }

          if (e.command === 'Paste') {
            e.preventDefault()

            try {
              if (!isSafari) {
                const text = await navigator.clipboard.readText()
                editor.insertContent(text);
                setTimeout(() => {
                  $('.tox-notification .tox-notification__dismiss').click();
                }, 150)
              }
            } catch (error) {
              console.error('Failed to read clipboard');
            }
          }
      });

        editor.on('mceLink', function (e) {
            console.log('Link modal opened');
        });

        setTimeout(() => {
          makeEditorTooltips()
          initializeTooltips()
        }, 2000)
      },
      setup: function (editor) {

        function adjustInitialModalPosition(modal) {
          if (modal) {
            const modalParent = modal.parentElement;
            const modalWidthRect = modal.getBoundingClientRect().width;
            modalParent.style.paddingTop = '40px';
            modalParent.classList.add('findReplace__modal')
            modalParent.style.right = `calc(50% - ${modalWidthRect/2}px)`; // needs to be adjusted for small screens... base calculation off of modal width
          }
        }

        editor.on('OpenWindow', function(event) {
          // TODO - find and replace has a positioninng bug cause it is dragable
          //      - some tablet and small browser sizes have bugged depth (including find and replace)

          if (event.type === 'openwindow') {
            wrapSelectionWithEmph()
            const modal = document.querySelector('.tox-dialog.tox-dialog-inline');
            const title = modal ? modal.querySelector('.tox-dialog__title') : null;
            if (title && title.innerText === 'Find and Replace') {
              adjustInitialModalPosition(modal); // Your custom function to adjust the modal position
            }
            const openModal = $('.tox-dialog');
            if (openModal.length) {
              autosaveState.setPreventAutosave(true)
              $('.tox-dialog .tox-form .tox-form__group:first-child input').focus()
            }
          }
        });

        editor.on('CloseWindow', function (event) {
          $('nav.openModal').removeClass('openModal')
          autosaveState.setPreventAutosave(false)
          unwrapSelectionFromSpan()
        });

        editor.on('init', function () {
          setTimeout(() => {
            tinymce.activeEditor.focus();
            makeEditorTooltips()
            initializeTooltips()
          }, 2000)
          const imageModalBtn = editor.ui.registry.getAll().buttons.image;
          if(imageModalBtn){
            imageModalBtn.onAction = function () {
              editor.execCommand('mceImage');
            };
          }
        });

        editor.on('SpellcheckerIgnore', function (event) {
          const ignoredWord = event.word;

          tinymce.activeEditor.plugins.tinymcespellchecker.addIgnoredWords([ignoredWord]);
          addToSpellcheckIgnore([ignoredWord]);
        });

        editor.on('SpellcheckerIgnoreAll', function (event) {
          event.preventDefault();

          // Find all spans with class mce-spellchecker-annotation in the editor content
          var spellcheckerAnnotations = editor.dom.select('span.mce-spellchecker-annotation');

          // Extract words from the annotations
          var ignoredWordsInEditor = spellcheckerAnnotations.map(function (annotation) {
            return annotation.innerText || annotation.textContent;
          });

          tinymce.activeEditor.plugins.tinymcespellchecker.addIgnoredWords([...ignoredWordsInEditor, event.word]);
          addToSpellcheckIgnore([...ignoredWordsInEditor, event.word]);

        });

        // USE SELECTION CHANGES FOR COMMENT DETECTION
        editor.on('SelectionChange', function () {
          // WORD COUNT ON SELECTION
          var content = editor.selection.getContent();
          if ( content.length > 0 ){
            var wordcount = tinymce.activeEditor.plugins.wordcount;
            $('.viewWordCount .selectedWordCount').text(`${wordcount.selection.getWordCount()} Words Selected`);
            $('.viewWordCount .totalWordCount').hide();
            $('.viewWordCount .selectedWordCount').show();
            if (Cookies.get('viewWordCount') == 'false'){
              $('.viewWordCount').show();
            }
          } else {
            $('.viewWordCount .selectedWordCount').hide();
            $('.viewWordCount .totalWordCount').show();
            if (Cookies.get('viewWordCount') == 'false'){
              $('.viewWordCount').hide();
            }
          }

          // DIALOG CLEAN UPS
          setTimeout(() => {
            const popUpBtns = document.querySelectorAll('.tox-pop__dialog .tox-tbtn');
            // clean hanging tooltip
            const hangedTooltips = document.querySelectorAll('.tooltip')
            if (hangedTooltips.length) {
              hangedTooltips.forEach((element) => {
                element.parentNode.removeChild(element);
              });
            }
            if (popUpBtns.length > 0) {
              // data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="AI Shorten"
              popUpBtns.forEach(btn => {
                const text = btn.getAttribute("title")
                const btnBsTitle = btn.dataset['data-bs-original-title'];

                if (text && text !== btnBsTitle) {
                  btn.setAttribute("data-bs-toggle", 'tooltip');
                  btn.setAttribute("data-bs-placement", 'bottom');
                  btn.setAttribute("data-bs-original-title", text);
                }

                // TOOLTIPS
                const tooltipTriggerList = document.querySelectorAll('.tox-pop__dialog [data-bs-toggle="tooltip"]')
                const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
              })
            }
          }, 500);

          // ENSURE FOCUSED IN TINYMCE BEFORE PROCEEDING
          if ($('#tinymce').is(":focus")) {
            // MANAGE SIDEBAR STATE
            var sidebarState = $('#sidebar__container').data('sidebar-state');
            if ($(editor.selection.getNode()).data("comment") != undefined) {
              // COMMENT TRIGGERS ALL COMMENTS
              window.dispatchEvent(new CustomEvent("comments:showdropdown"));
            } else if (sidebarState == 'comments--new') {
              if (!isMobile()) {
                // ABANDONING NEW COMMENT
                window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
              }
            }
          }

          // UPDATE WORD COUNT
          if (Cookies.get('viewWordCount') == 'true') {
            var wordcount = tinymce.activeEditor.plugins.wordcount;
            $('.viewWordCount span.totalWordCount').text(`${numberWithCommas(wordcount.body.getWordCount())} Words`);
          }
        });

        editor.on('click', (event) => {
          // REMOVE PROMP ON EDITOR CLICK
          const prompt = $('.inline__command-prompt')

          // hide is offset by 750ms in execute
          if (prompt && prompt.hasClass('open')) {
            // this is for case when selection exists
            const customSelection = tinymce.activeEditor.dom.select('.emph__selection')
            // if customSelection still exists it means that we are not hiding from execute
            if (customSelection.length) {
              if (customSelection.length) {
                customSelection.forEach(selection => {
                  selection.classList.remove('emph__selection')
                })
              }
              sanitize()
            }

            // Set the cursor position
            editor.focus();
            placeCursorAtClickPosition(editor, event.clientX, event.clientY);

            prompt.removeClass('open');
          }
        })

        // FOCUS
        editor.on('focus', function () {
          var sidebarState = $('#sidebar__container').data('sidebar-state');
          if (sidebarState == "title_variants" || sidebarState == "comments--new") {
            if (!isMobile()) {
              const event = new CustomEvent("sidebar:backtodefault");
              window.dispatchEvent(event);
            }
          }

        });

        editor.on('change redo undo', function () {
          if (!window.insertingGeneration) {
            // AUTOSAVE EVENT
            window.dispatchEvent(new CustomEvent("tinymce:change"));

            // VALIDATE COMMENTS
            window.dispatchEvent(new CustomEvent("comments:validate"));
          }
        });

        editor.on("keydown", function (event) {
          if (event.keyCode === 9) {
            event.preventDefault();
            command();
          }

          if (event.keyCode === 38) {
            const selection = editor.selection;
            let node = selection.getNode();
            let thisNode = $(node);
            // Check if the node is a <span>, and if so, get its parent node
            // spellchecker fix for safari (spellchecker anotations were braking this)
            if (node.nodeName.toLowerCase() === 'span') {
              node = node.parentNode;
              thisNode = $(node);
            }
            const container = $("#tinymce");
            const isFirstChild = thisNode.is(container.children().first());
            const isFirstLine = selection.getSel().anchorOffset === 0;

            // Check if thisNode is the first child of container
            if (isFirstChild && isFirstLine) {
              event.preventDefault();
              $('#title').focus();
            }
          }

          if (document.querySelector('div[role="menu"]') && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
            const box = document.querySelector('div[role="menu"]');
            const selectedItem = document.querySelector('.tox-collection__item--active'); // get Y from this

            const boxRect = box.getBoundingClientRect()
            const itemRect = selectedItem.getBoundingClientRect()
            const position = {
              x: itemRect.left - boxRect.left,
              y: itemRect.top - boxRect.top + 40
            };

            if (selectedItem.parentNode
              && selectedItem.parentNode.childNodes[1] === selectedItem
              && selectedItem.parentNode.childNodes.length < 4
              && event.key === 'ArrowUp') {
              box.scrollTop = 450
            } else if (selectedItem.parentNode && selectedItem.parentNode.childNodes[9] === selectedItem && event.key === 'ArrowDown') {
              box.scrollTop = 0;
            } else if (position.y > 260 && event.key === 'ArrowDown') {
              box.scrollTop += 60;
            } else if (position.y < 106 && event.key === 'ArrowUp') {
              box.scrollTop -= 60;
            }
          }
        });

        editor.on('blur', function () {
          return false;
        });

        // ADDING CUSTOM COMMANDS
        editor.addCommand('aiCommand', function (ui, v) {
          command();
        });

        // ADDING CUSTOM ICONS
        //
        editor.ui.registry.addIcon('p', '<svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.9913 40C19.5638 40 19.2083 39.8563 18.925 39.5688C18.6417 39.2813 18.5 38.925 18.5 38.5V27H17.5C14.8717 27 12.6312 26.0732 10.7787 24.2197C8.92625 22.3662 8 20.1245 8 17.4947C8 14.8649 8.92625 12.625 10.7787 10.775C12.6312 8.925 14.8717 8 17.5 8H34.5C34.925 8 35.2813 8.14458 35.5688 8.43375C35.8563 8.72295 36 9.08128 36 9.50875C36 9.93625 35.8563 10.2917 35.5688 10.575C35.2813 10.8583 34.925 11 34.5 11H31.5V38.5C31.5 38.925 31.3554 39.2813 31.0663 39.5688C30.7771 39.8563 30.4187 40 29.9913 40C29.5638 40 29.2083 39.8563 28.925 39.5688C28.6417 39.2813 28.5 38.925 28.5 38.5V11H21.5V38.5C21.5 38.925 21.3554 39.2813 21.0663 39.5688C20.7771 39.8563 20.4187 40 19.9913 40Z" fill="black"/></svg>');
        editor.ui.registry.addIcon('h2', '<svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49125 34C7.06375 34 6.70833 33.8563 6.425 33.5688C6.14167 33.2813 6 32.925 6 32.5V15.5C6 15.075 6.14458 14.7188 6.43375 14.4313C6.72295 14.1438 7.08128 14 7.50875 14C7.93625 14 8.29167 14.1438 8.575 14.4313C8.85833 14.7188 9 15.075 9 15.5V22.5H18V15.5C18 15.075 18.1446 14.7188 18.4337 14.4313C18.7229 14.1438 19.0813 14 19.5087 14C19.9362 14 20.2917 14.1438 20.575 14.4313C20.8583 14.7188 21 15.075 21 15.5V32.5C21 32.925 20.8554 33.2813 20.5663 33.5688C20.2771 33.8563 19.9187 34 19.4913 34C19.0638 34 18.7083 33.8563 18.425 33.5688C18.1417 33.2813 18 32.925 18 32.5V25.5H9V32.5C9 32.925 8.85542 33.2813 8.56625 33.5688C8.27705 33.8563 7.91872 34 7.49125 34ZM28.5 34C28.075 34 27.7188 33.8563 27.4313 33.5688C27.1438 33.2813 27 32.925 27 32.5V25.5C27 24.675 27.2938 23.9688 27.8813 23.3813C28.4688 22.7938 29.175 22.5 30 22.5H39V17H28.5C28.075 17 27.7188 16.8554 27.4313 16.5663C27.1438 16.2771 27 15.9187 27 15.4913C27 15.0638 27.1438 14.7083 27.4313 14.425C27.7188 14.1417 28.075 14 28.5 14H39C39.825 14 40.5313 14.2938 41.1188 14.8813C41.7063 15.4688 42 16.175 42 17V22.5C42 23.325 41.7063 24.0313 41.1188 24.6188C40.5313 25.2063 39.825 25.5 39 25.5H30V31H40.5C40.925 31 41.2813 31.1446 41.5688 31.4337C41.8563 31.7229 42 32.0813 42 32.5087C42 32.9362 41.8563 33.2917 41.5688 33.575C41.2813 33.8583 40.925 34 40.5 34H28.5Z" fill="black"/></svg>');
        editor.ui.registry.addIcon('h3', '<svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.49125 34C7.06375 34 6.70833 33.8563 6.425 33.5688C6.14167 33.2813 6 32.925 6 32.5V15.5C6 15.075 6.14458 14.7188 6.43375 14.4313C6.72295 14.1438 7.08128 14 7.50875 14C7.93625 14 8.29167 14.1438 8.575 14.4313C8.85833 14.7188 9 15.075 9 15.5V22.5H18V15.5C18 15.075 18.1446 14.7188 18.4337 14.4313C18.7229 14.1438 19.0813 14 19.5087 14C19.9362 14 20.2917 14.1438 20.575 14.4313C20.8583 14.7188 21 15.075 21 15.5V32.5C21 32.925 20.8554 33.2813 20.5663 33.5688C20.2771 33.8563 19.9187 34 19.4913 34C19.0638 34 18.7083 33.8563 18.425 33.5688C18.1417 33.2813 18 32.925 18 32.5V25.5H9V32.5C9 32.925 8.85542 33.2813 8.56625 33.5688C8.27705 33.8563 7.91872 34 7.49125 34ZM28.5 34C28.075 34 27.7188 33.8554 27.4313 33.5663C27.1438 33.2771 27 32.9187 27 32.4913C27 32.0638 27.1438 31.7083 27.4313 31.425C27.7188 31.1417 28.075 31 28.5 31H39V25.5H32.5C32.075 25.5 31.7188 25.3554 31.4313 25.0663C31.1438 24.7771 31 24.4187 31 23.9913C31 23.5638 31.1438 23.2083 31.4313 22.925C31.7188 22.6417 32.075 22.5 32.5 22.5H39V17H28.5C28.075 17 27.7188 16.8554 27.4313 16.5663C27.1438 16.2771 27 15.9187 27 15.4913C27 15.0638 27.1438 14.7083 27.4313 14.425C27.7188 14.1417 28.075 14 28.5 14H39C39.825 14 40.5313 14.2938 41.1188 14.8813C41.7063 15.4688 42 16.175 42 17V31C42 31.825 41.7063 32.5313 41.1188 33.1188C40.5313 33.7063 39.825 34 39 34H28.5Z" fill="black"/></svg>');
        editor.ui.registry.addIcon('h4', '<svg width="17" height="17" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.49125 34C6.06375 34 5.70833 33.8563 5.425 33.5688C5.14167 33.2813 5 32.925 5 32.5V15.5C5 15.075 5.14458 14.7188 5.43375 14.4313C5.72295 14.1438 6.08128 14 6.50875 14C6.93625 14 7.29167 14.1438 7.575 14.4313C7.85833 14.7188 8 15.075 8 15.5V22.5H17V15.5C17 15.075 17.1446 14.7188 17.4337 14.4313C17.7229 14.1438 18.0813 14 18.5087 14C18.9362 14 19.2917 14.1438 19.575 14.4313C19.8583 14.7188 20 15.075 20 15.5V32.5C20 32.925 19.8554 33.2813 19.5663 33.5688C19.2771 33.8563 18.9187 34 18.4913 34C18.0638 34 17.7083 33.8563 17.425 33.5688C17.1417 33.2813 17 32.925 17 32.5V25.5H8V32.5C8 32.925 7.85542 33.2813 7.56625 33.5688C7.27705 33.8563 6.91872 34 6.49125 34ZM37.4913 34C37.0638 34 36.7083 33.8563 36.425 33.5688C36.1417 33.2813 36 32.925 36 32.5V27.5H27.5C27.075 27.5 26.7188 27.3563 26.4313 27.0688C26.1438 26.7813 26 26.425 26 26V15.5C26 15.075 26.1446 14.7188 26.4337 14.4313C26.7229 14.1438 27.0813 14 27.5087 14C27.9362 14 28.2917 14.1438 28.575 14.4313C28.8583 14.7188 29 15.075 29 15.5V24.5H36V15.5C36 15.075 36.1446 14.7188 36.4337 14.4313C36.7229 14.1438 37.0813 14 37.5087 14C37.9362 14 38.2917 14.1438 38.575 14.4313C38.8583 14.7188 39 15.075 39 15.5V24.5H41.5C41.925 24.5 42.2813 24.6446 42.5688 24.9337C42.8563 25.2229 43 25.5813 43 26.0087C43 26.4362 42.8563 26.7917 42.5688 27.075C42.2813 27.3583 41.925 27.5 41.5 27.5H39V32.5C39 32.925 38.8554 33.2813 38.5663 33.5688C38.2771 33.8563 37.9187 34 37.4913 34Z" fill="black"/></svg>');
        editor.ui.registry.addIcon('blockquote', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M4.58341 17.3211C3.55316 16.2274 3 15 3 13.0103C3 9.51086 5.45651 6.37366 9.03059 4.82318L9.92328 6.20079C6.58804 8.00539 5.93618 10.346 5.67564 11.822C6.21263 11.5443 6.91558 11.4466 7.60471 11.5105C9.40908 11.6778 10.8312 13.159 10.8312 15C10.8312 16.933 9.26416 18.5 7.33116 18.5C6.2581 18.5 5.23196 18.0095 4.58341 17.3211ZM14.5834 17.3211C13.5532 16.2274 13 15 13 13.0103C13 9.51086 15.4565 6.37366 19.0306 4.82318L19.9233 6.20079C16.588 8.00539 15.9362 10.346 15.6756 11.822C16.2126 11.5443 16.9156 11.4466 17.6047 11.5105C19.4091 11.6778 20.8312 13.159 20.8312 15C20.8312 16.933 19.2642 18.5 17.3312 18.5C16.2581 18.5 15.232 18.0095 14.5834 17.3211Z"></path></svg>');
        editor.ui.registry.addIcon('bold', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M8 11H12.5C13.8807 11 15 9.88071 15 8.5C15 7.11929 13.8807 6 12.5 6H8V11ZM18 15.5C18 17.9853 15.9853 20 13.5 20H6V4H12.5C14.9853 4 17 6.01472 17 8.5C17 9.70431 16.5269 10.7981 15.7564 11.6058C17.0979 12.3847 18 13.837 18 15.5ZM8 13V18H13.5C14.8807 18 16 16.8807 16 15.5C16 14.1193 14.8807 13 13.5 13H8Z"></path></svg>');
        editor.ui.registry.addIcon('italic', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M15 20H7V18H9.92661L12.0425 6H9V4H17V6H14.0734L11.9575 18H15V20Z"></path></svg>');
        editor.ui.registry.addIcon('underline', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M8 3V12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12V3H18V12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12V3H8ZM4 20H20V22H4V20Z"></path></svg>');
        editor.ui.registry.addIcon('strike-through', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M17.1538 14C17.3846 14.5161 17.5 15.0893 17.5 15.7196C17.5 17.0625 16.9762 18.1116 15.9286 18.867C14.8809 19.6223 13.4335 20 11.5862 20C9.94674 20 8.32335 19.6185 6.71592 18.8555V16.6009C8.23538 17.4783 9.7908 17.917 11.3822 17.917C13.9333 17.917 15.2128 17.1846 15.2208 15.7196C15.2208 15.0939 15.0049 14.5598 14.5731 14.1173C14.5339 14.0772 14.4939 14.0381 14.4531 14H3V12H21V14H17.1538ZM13.076 11H7.62908C7.4566 10.8433 7.29616 10.6692 7.14776 10.4778C6.71592 9.92084 6.5 9.24559 6.5 8.45207C6.5 7.21602 6.96583 6.165 7.89749 5.299C8.82916 4.43299 10.2706 4 12.2219 4C13.6934 4 15.1009 4.32808 16.4444 4.98426V7.13591C15.2448 6.44921 13.9293 6.10587 12.4978 6.10587C10.0187 6.10587 8.77917 6.88793 8.77917 8.45207C8.77917 8.87172 8.99709 9.23796 9.43293 9.55079C9.86878 9.86362 10.4066 10.1135 11.0463 10.3004C11.6665 10.4816 12.3431 10.7148 13.076 11H13.076Z"></path></svg>');
        editor.ui.registry.addIcon('link', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M18.3638 15.5355L16.9496 14.1213L18.3638 12.7071C20.3164 10.7545 20.3164 7.58866 18.3638 5.63604C16.4112 3.68341 13.2453 3.68341 11.2927 5.63604L9.87849 7.05025L8.46428 5.63604L9.87849 4.22182C12.6122 1.48815 17.0443 1.48815 19.778 4.22182C22.5117 6.95549 22.5117 11.3876 19.778 14.1213L18.3638 15.5355ZM15.5353 18.364L14.1211 19.7782C11.3875 22.5118 6.95531 22.5118 4.22164 19.7782C1.48797 17.0445 1.48797 12.6123 4.22164 9.87868L5.63585 8.46446L7.05007 9.87868L5.63585 11.2929C3.68323 13.2455 3.68323 16.4113 5.63585 18.364C7.58847 20.3166 10.7543 20.3166 12.7069 18.364L14.1211 16.9497L15.5353 18.364ZM14.8282 7.75736L16.2425 9.17157L9.17139 16.2426L7.75717 14.8284L14.8282 7.75736Z"></path></svg>');
        editor.ui.registry.addIcon('browse', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"  viewBox="0 0 24 24" fill="currentColor"><path d="M1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811L7 21C3.64378 20.7257 1 17.9216 1 14.5ZM16.8483 18.9868C19.1817 18.8093 21 16.8561 21 14.5C21 12.927 20.1884 11.4962 18.8771 10.6781L18.0714 10.1754L17.9517 9.23338C17.5735 6.25803 15.0288 4 12 4C8.97116 4 6.42647 6.25803 6.0483 9.23338L5.92856 10.1754L5.12288 10.6781C3.81156 11.4962 3 12.927 3 14.5C3 16.8561 4.81833 18.8093 7.1517 18.9868L7.325 19H16.675L16.8483 18.9868ZM13 13V17H11V13H8L12 8L16 13H13Z"></path></svg>');
        editor.ui.registry.addIcon('image', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M2.9918 21C2.44405 21 2 20.5551 2 20.0066V3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918ZM20 15V5H4V19L14 9L20 15ZM20 17.8284L14 11.8284L6.82843 19H20V17.8284ZM8 11C6.89543 11 6 10.1046 6 9C6 7.89543 6.89543 7 8 7C9.10457 7 10 7.89543 10 9C10 10.1046 9.10457 11 8 11Z"></path></svg>');
        editor.ui.registry.addIcon('indent', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="black"><path d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM11 14H21V16H11V14ZM11 9H21V11H11V9ZM7 12.5L3 16V9L7 12.5Z" fill="black"></path></svg>');
        editor.ui.registry.addIcon('outdent', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="black"><path d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM11 14H21V16H11V14ZM11 9H21V11H11V9ZM3 12.5L7 9V16L3 12.5Z" fill="black"></path></svg>');
        editor.ui.registry.addIcon('align-left', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M3 4H21V6H3V4ZM3 19H17V21H3V19ZM3 14H21V16H3V14ZM3 9H17V11H3V9Z"></path></svg>');
        editor.ui.registry.addIcon('align-center', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M3 4H21V6H3V4ZM5 19H19V21H5V19ZM3 14H21V16H3V14ZM5 9H19V11H5V9Z"></path></svg>');
        editor.ui.registry.addIcon('align-right', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M3 4H21V6H3V4ZM7 19H21V21H7V19ZM3 14H21V16H3V14ZM7 9H21V11H7V9Z"></path></svg>');
        editor.ui.registry.addIcon('table', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M13 10V14H19V10H13ZM11 10H5V14H11V10ZM13 19H19V16H13V19ZM11 19V16H5V19H11ZM13 5V8H19V5H13ZM11 5H5V8H11V5ZM4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3Z"></path></svg>');
        editor.ui.registry.addIcon('unordered-list', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M8 4H21V6H8V4ZM3 3.5H6V6.5H3V3.5ZM3 10.5H6V13.5H3V10.5ZM3 17.5H6V20.5H3V17.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"></path></svg>');
        editor.ui.registry.addIcon('ordered-list', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"></path></svg>');
        editor.ui.registry.addIcon('aiCommand', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="#FF6702"><path fill="#FF6702" d="M6.93912 14.0328C6.7072 14.6563 6.51032 15.2331 6.33421 15.8155C7.29345 15.1189 8.43544 14.6767 9.75193 14.5121C12.2652 14.198 14.4976 12.5385 15.6279 10.4537L14.1721 8.99888L15.5848 7.58417C15.9185 7.25004 16.2521 6.91614 16.5858 6.58248C17.0151 6.15312 17.5 5.35849 18.0129 4.2149C12.4197 5.08182 8.99484 8.50647 6.93912 14.0328ZM17 8.99739L18 9.99669C17 12.9967 14 15.9967 10 16.4967C7.33146 16.8303 5.66421 18.6636 4.99824 21.9967H3C4 15.9967 6 1.99669 21 1.99669C20.0009 4.99402 19.0018 6.99313 18.0027 7.99402C17.6662 8.33049 17.3331 8.66382 17 8.99739Z"></path></svg>');

        editor.ui.registry.addIcon('newComment', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M14 3V5H4V18.3851L5.76282 17H20V10H22V18C22 18.5523 21.5523 19 21 19H6.45455L2 22.5V4C2 3.44772 2.44772 3 3 3H14ZM19 3V0H21V3H24V5H21V8H19V5H16V3H19Z"></path></svg>');
        editor.ui.registry.addIcon('allComments', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M21 7C22.6569 7 24 5.65685 24 4C24 2.34315 22.6569 1 21 1C19.3431 1 18 2.34315 18 4C18 5.65685 19.3431 7 21 7ZM22 18V8.89998C21.6769 8.96557 21.3425 9 21 9C20.6575 9 20.3231 8.96557 20 8.89998V17H5.76282L4 18.3851V5H16.1C16.0344 4.67689 16 4.34247 16 4C16 3.65753 16.0344 3.32311 16.1 3H3C2.44772 3 2 3.44772 2 4V22.5L6.45455 19H21C21.5523 19 22 18.5523 22 18Z"></path></svg>');
        editor.ui.registry.addIcon('undo', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M5.82843 6.99955L8.36396 9.53509L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68584 16.3137 6.99955 13 6.99955H5.82843Z"></path></svg>');
        editor.ui.registry.addIcon('redo', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M18.1716 6.99955H11C7.68629 6.99955 5 9.68584 5 12.9996C5 16.3133 7.68629 18.9996 11 18.9996H20V20.9996H11C6.58172 20.9996 3 17.4178 3 12.9996C3 8.58127 6.58172 4.99955 11 4.99955H18.1716L15.636 2.46402L17.0503 1.0498L22 5.99955L17.0503 10.9493L15.636 9.53509L18.1716 6.99955Z"></path></svg>');
        editor.ui.registry.addIcon('more-drawer', '<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="currentColor"><path d="M4.5 10.5C3.675 10.5 3 11.175 3 12C3 12.825 3.675 13.5 4.5 13.5C5.325 13.5 6 12.825 6 12C6 11.175 5.325 10.5 4.5 10.5ZM19.5 10.5C18.675 10.5 18 11.175 18 12C18 12.825 18.675 13.5 19.5 13.5C20.325 13.5 21 12.825 21 12C21 11.175 20.325 10.5 19.5 10.5ZM12 10.5C11.175 10.5 10.5 11.175 10.5 12C10.5 12.825 11.175 13.5 12 13.5C12.825 13.5 13.5 12.825 13.5 12C13.5 11.175 12.825 10.5 12 10.5Z"></path></svg>');
        // AI FUNCTIONS


        const command = () => {
          // VALIDATE INPUT
          var input_length = editor.selection.getContent({format: "html"}).length;
          if (input_length > 7500) {
            // EVENTUALLY CAN CHUNK IT
            // ALSO THIS IS BASED ON CHARS NOT WORDS
            toast("alert", "Too long! Select less content.");
            return;
          } else {
            window.dispatchEvent(new CustomEvent("writer--command:command"));
          }
        }

        // ADDING CUSTOM BUTTONS + SHORTCUTS

        editor.ui.registry.addButton('customSpellcheck', {
          icon: 'customSpellcheckdialog',
          tooltip: 'Spellcheck',
          onAction: function (_) {
            customSpellcheckAction(editor)
          }
        });

        editor.addShortcut('meta+alt+c', 'Command', () => {
          command()
        });

        editor.ui.registry.addButton('aiCommandSmall', {
          icon: 'aiCommand',
          onAction: function (_) {
            command();
          }
        });

        editor.ui.registry.addButton('aiCommand', {
          icon: 'aiCommand',
          text: 'Cowriter',
          tooltip: 'Cowriter',
          onAction: function (_) {
            command();
          }
        });

        editor.ui.registry.addButton('newComment', {
          tooltip: 'New Comment',
          icon: 'newComment',
          onAction: function (_) {
            if (editor.selection.getContent({format: 'html'}).length > 0) {
              const event = new CustomEvent("comments:newcomment");
              window.dispatchEvent(event);
            } else {
              toast("alert", "You must select some content to comment on.");
            }
          },
          onSetup: (btn) => {
            const popUpBtns = document.querySelectorAll('.tox-pop__dialog .tox-tbtn');
            // CLEAN HANGING TOOLTIP
            const hangedTooltips = document.querySelectorAll('.tooltip')
            if (hangedTooltips.length) {
              hangedTooltips.forEach((element) => {
                element.parentNode.removeChild(element);
              });
            }
            if (popUpBtns.length > 0) {
              // data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="AI Shorten"

              popUpBtns.forEach(btn => {
                const text = btn.getAttribute("title")
                const btnBsTitle = btn.dataset['data-bs-original-title'];

                if (text && text !== btnBsTitle) {
                  btn.setAttribute("data-bs-toggle", 'tooltip');
                  btn.setAttribute("data-bs-placement", 'bottom');
                  btn.setAttribute("data-bs-original-title", text);
                }

                // TOOLTIPS
                const tooltipTriggerList = document.querySelectorAll('.tox-pop__dialog [data-bs-toggle="tooltip"]')
                const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
              })
            }

          },

        });

        editor.ui.registry.addButton('allComments', {
          tooltip: 'All Comments',
          icon: 'allComments',
          onAction: function (_) {
            window.dispatchEvent(new CustomEvent("comments:showdropdown"));
          }
        });
      },
      mobile: {
        toolbar_mode: 'floating', // fixed mobile toolbar problems
      }
    }
  }

  connect() {
    // SET WINDOW VARIABLES
    window.articleId = $('.writer__container').data('article-id');
    window.projectShortcode = $('body').data('shortcode');

    setTimeout(() => {
      // Add event listener for custom reconnect event
      document.addEventListener('tinymce:reconnect', this.reconnect.bind(this));
      // Add event listener for custom commentPrep event
      document.addEventListener('tinymce:commentPrep', this.commentPrep.bind(this));

      try {

        this.initializeTinyMCE();

      } catch (error) {
          console.error('Caught TypeError:', error.message);
          Turbo.visit(window.location.href, { action: 'replace' });
      }

      // INITIALISE TINYMCE
      const config = Object.assign({target: this.inputTarget}, this.defaults)

      tinymce.init({...config, extended_valid_elements: 'span', skin: false})

      tinymce.activeEditor.fire('blur');
      this.element.addEventListener('tinymce:sanitize', this.sanitize.bind(this));

      // FOCUS ON EDITOR (DELAY FOR BUG)
      setTimeout(() => {
        this.setupOrientationChangeListener()
        this.showEditor()
        if (!isMobile()) window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
      }, 2000);

      // START TOUR
      // if (!isMobile()) chmln.show('64cb8934a252b80016e1a0cb', {once: true});
      // modify for mobile
      this.showMobile()
      window.addEventListener('scroll', this.scrollMobile.bind(this));
    }, 750)
  }

  commentPrep() {
    Cookies.set('enableSpellcheck', false);
    toast('alert', 'Spellchecker was turned off');
    this.reconnect()
  }

  reconnect() {
    $(this.preloaderTarget).show();
    try {
      this.disconnect()
      this.connect();
    } catch (error) {
      console.error("error -> ", error)
    }
  }

  disconnect() {
    tinymce.remove()
    this.destroyTinyMCE();
    this.element.removeEventListener('tinymce:sanitize', this.sanitize.bind(this));
    document.removeEventListener('turbo:load', this.initializeTinyMCE.bind(this));
    document.removeEventListener('tinymce:reconnect', this.reconnect.bind(this));
    window.removeEventListener('scroll', this.scrollMobile.bind(this));
    this.teardownOrientationChangeListener();
  }

  destroyTinyMCE() {
    if (tinymce.activeEditor) {
      tinymce.activeEditor.remove();
      this.editorTarget.dataset.tinymce = 'false';
    }
  }
  
  toggleSpellchecker(event) {
    const editor = tinymce.activeEditor; // Update to your selector

    if (editor) {
      const isSpellcheckerEnabled = Cookies.get('enableSpellcheck') === 'true'
      if (editor.plugins && isSpellcheckerEnabled) {
        Cookies.set('enableSpellcheck', false);
        const dynamicCheckHolder = $(event.currentTarget).find('.dynamic-check-holder');
        dynamicCheckHolder.css('display', 'none');
        tinymce.activeEditor.execCommand('mceSpellcheckDisable');
        $('.spellCheck__dropdown-mobile').children('.dynamic-check-holder').hide();
      } else {
        Cookies.set('enableSpellcheck', true);
        const dynamicCheckHolder = $(event.currentTarget).find('.dynamic-check-holder');
        dynamicCheckHolder.css('display', 'block');
        tinymce.activeEditor.execCommand('mceSpellcheckEnable')
        $('.spellCheck__dropdown-mobile').children('.dynamic-check-holder').show()
      }
    } else {
      console.error('TinyMCE instance not found.');
    }
  }

  getKeyPhrases(article_id) {
    var json = {};
    $.ajax({
      url: `${article_id}/key_phrases.json`,
      dataType: 'json',
      async: false,
      success: function (data) {
        json = data;
      }
    });

    return json;
  }

  scroll() {
    $('.tox-pop').hide();

    if (!window.settingUIData && !window.insertingGeneration) {
      const editorContainer = document.querySelector('.editor__container')
      const commandPrompt = document.querySelector('.inline__command-prompt')
      if (commandPrompt.classList.contains('open') && window.promptUIData) {
        const initialPromptTop = window.promptUIData.initialPromptTop
        const initialEditorScroll = window.promptUIData.initialEditorScroll
        const currentEditorScroll = editorContainer.scrollTop;
        const difference = initialEditorScroll - currentEditorScroll
        commandPrompt.style.top = `${initialPromptTop + difference}px`;
      }
    }
  }

  showEditor() {
    $(this.preloaderTarget).hide();
    $('#tinymce').slideDown({duration: 200, easing: 'swing'});
    $('.writerToolbar__container').slideDown({duration: 200, easing: 'swing'});
    // $('#writer__toolbar').show();
    $('.navbar-nav').show();

    // VALIDATE COMMENTS
    window.dispatchEvent(new CustomEvent("comments:validate"));
    window.dispatchEvent(new CustomEvent("comments:fullcheck"));

    if (!isMobile()) {
      // SET SIDEBAR TO DEFAULT
      window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
    }

    // REMOVE ANY ACTIVE CLASSES
    $('#tinymce .comment').removeClass("selected");

    this.focus();
  }

  focus() {
    setTimeout(() => tinymce.activeEditor.focus(), 150);
    $('.inline__command-prompt').removeClass('open');
  }

  aiLock() {
    tinymce.activeEditor.execCommand('mceSpellcheckDisable');
    // SET TYPER
    $('#tinymce').data("typer", "ai");

    // LOCK EDITOR
    $('.editor__container').append("<div id='readonly' data-action='touchstart->tinymce#aiUnlock click->tinymce#aiUnlock keydown@window->tinymce#aiUnlock'></div>");
  }

  aiUnlock(event) {
    window.insertingGeneration = false;
    event.stopPropagation()
    event.preventDefault()

    const editor = tinymce.activeEditor;
    removeAiCaret(editor);

    // SET TYPER
    $('#tinymce').data("typer", "human");

    // HIDE LARGE ELEMENT LOADER
    const largeLoader = $('#customCursorLoaderLarge')
    $('body').removeClass('showingLargeLoader')
    largeLoader.hide();
    largeLoader.removeClass('size-2', 'size-3', 'size-4');

    // AUTOSAVE
    window.dispatchEvent(new CustomEvent("tinymce:change"));

    // UNLOCK EDITOR
    $('#readonly').remove();
    tinymce.activeEditor.execCommand('mceSpellcheckEnable');
  }

  keydownUnlock(event) {
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      // Ignore the key press
      return;
    } else {
      this.aiUnlock();
    }
  }

  sanitize() {
    const editor = tinymce.activeEditor;

    if (editor) {
      var content = editor.getContent();

      var tempElement = document.createElement('div');
      tempElement.innerHTML = content;

      // Find all <span> elements, they could be more depending on how many times you select and cancel
      var spanElements = tempElement.querySelectorAll('span');
      for (var i = 0; i < spanElements.length; i++) {
        var spanElement = spanElements[i];
        var parentElement = spanElement.parentElement;
        while (spanElement.firstChild) {
          parentElement.insertBefore(spanElement.firstChild, spanElement);
        }
        parentElement.removeChild(spanElement);
      }

      // Get the updated content
      var updatedContent = tempElement.innerHTML;
      // Create a temporary element to manipulate the content

      editor.setContent(updatedContent);
    }
  }

  scrollMobile() {
    if (isMobile()) {
      const mobileMenu = this.sidebarTarget
      if (mobileMenu.classList.contains('show')) {
        mobileMenu.classList.remove('show')
      }
    }
  }

  openSidebar() {
    if ($('.newWriter-navBarLeft').hasClass('show')) {
      $('.newWriter-navBarLeft').removeClass('show')
    } else {
      $('.newWriter-navBarLeft').addClass('show')
    }
  }

  showMobile() {
    const editorContainer = $('.editor__container');

    if (isMobile()) {
      this.wsidebarTarget.classList.add('hidden')
      editorContainer.addClass('expanded')
      $('#research').removeClass('active')
    } else if (Cookies.get('viewSidebar') != 'false') {
      editorContainer.removeClass('expanded');
    }
  }

  getIgnoredWords() {
    return JSON.parse(localStorage.getItem('ignoredWords')) || [];
  }

  addToSpellcheckIgnore(listOfWords) {
    let ignoredWords = JSON.parse(localStorage.getItem('ignoredWords')) || [];
    ignoredWords = ignoredWords.concat(listOfWords);
    ignoredWords = [...new Set(ignoredWords)];

    localStorage.setItem('ignoredWords', JSON.stringify(ignoredWords));
  }

  setupOrientationChangeListener() {
      window.addEventListener("orientationchange", reloadPage);
  }

  teardownOrientationChangeListener() {
    window.removeEventListener("orientationchange", reloadPage);
  }
}