import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "source" ]

  copy(){
    navigator.clipboard.writeText(this.sourceTarget.value);
    this.toast("Copied to clipboard!");
  }

  toast(){
    $('.toast').remove();
    $('body').append('<div class="toast align-items-center text-bg-success" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="alert-circle.svg"> Copied to clipboard!<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>');
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
  }
}
