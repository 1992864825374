import { Controller } from "stimulus";
import {scrollToSelected} from "../../../utility_functions";

export default class extends Controller {
  static targets = ["prompt", "submitBtn"]

  connect() {
    const promptTarget = this.promptTarget
    let activeBtn = 0;

    $('#inlinecommandInput').keydown(function(event) {
      var key = event.key || event.which;
      const btns = $('.command__btn');

      // Check if there are buttons available
      if (btns.length === 0) return;

      const dropdown = $('.inline__command-dropdown')

      switch(key) {
        case "ArrowUp":
        case 38: // Key code for arrow up
          $('.command__btn.selected').removeClass('selected');
          activeBtn--;
          if (activeBtn < 0) {
            activeBtn = btns.length - 1; // Go to the last button if the current index is below 0
          }
          btns.eq(activeBtn).addClass('selected');
          scrollToSelected(btns.eq(activeBtn), dropdown)
          break;

        case "ArrowDown":
        case 40:
          $('.command__btn.selected').removeClass('selected');
          activeBtn++;
          if (activeBtn >= btns.length) {
            activeBtn = 0;
          }
          btns.eq(activeBtn).addClass('selected');
          scrollToSelected(btns.eq(activeBtn), dropdown)
          break;

        default:
          if (promptTarget.value.length > 5) {
            const selectedBtn = $('.command__btn.selected');
            if (selectedBtn) selectedBtn.removeClass('selected');
          } else if (promptTarget.value.length === 1) {
            $('.command__btn').eq(activeBtn).addClass('selected')
          }
          break;
      }
    });
  }

  quickCommand(event) {
    // Prevent further clicks on the target
    event.currentTarget.disabled = true;
    // Use event target's inner text as button text
    const buttonText = event.target.innerText.trim();
    this.promptTarget.value = buttonText;

    console.log(this.promptTarget.value)

    // Submit the form
    this.submitBtnTarget.click();
  }
}
