import {
    hideCachedLoader,
    hideModal,
    initializeTooltips,
    initializeValidetta,
    showContent,
    showModal
} from "../utility_functions";

$(document).on('turbo:load', function() {
    hideCachedLoader()
    showContent()
    initializeTooltips()

    // TOAST
    $('.toast').each(function(i, obj) {
        showToast(obj);
    });


    initializeValidetta()


    // CONFIRMATION DIALOGUE
    document.querySelectorAll('a[data-confirms="true"], button[data-confirms="true"]').forEach(item => {
        item.addEventListener('click', function(evt) {
            evt.preventDefault();
            evt.stopPropagation();
            console.log("⚠️ EVENT TARGET CONFIRMS: ", evt.target);

            const isDropdown = event.target.parentNode.classList.contains("dropdown-menu")
            if (isDropdown) {
                event.target.parentNode.classList.remove("show")
                event.target.parentNode.parentNode.classList.remove("show")
            }

            const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
                    innerMessage.textContent = evt.target.dataset.confirmsmessage;

            const confirmButton = document.querySelector("#confirmationModal .btn.orange");
            let method = '';

            const href = evt.target.href;

            const isTurbo = event.target.dataset.turboMethod
            if (isTurbo) {
                const turboMethod = event.target.dataset.turboMethod;
                const turboFrame = event.target.dataset.turboFrame;
                confirmButton.setAttribute('data-turbo-method', turboMethod);
                confirmButton.setAttribute('data-turbo-frame', turboFrame);
            } else {
                method = evt.target.dataset.method;
                confirmButton.setAttribute('data-method', method);
            }

            confirmButton.href = href;

            const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
            myModal.show();

        }, false);
    });

    $('[data-toggle="modal"]').click(function( e ){
        // BECAUSE FOR SOME REASON THE HTML TRIGGERS DON'T WORK
        // GET TARGET
        var modalClass = $(e.target).attr("data-target");
        // SHOW MODAL
        if(modalClass !== 'undefined'){
            $(modalClass).modal('show');
        }
    });

    $('.navbar-toggler').click(() => {
        const sidebar = $('.mobile-menu');
            sidebar.toggleClass('show');
            if (sidebar.hasClass('show')) {
                sidebar.addClass('expanded');
            } else {
                sidebar.removeClass('expanded');
            }
    });

    document.addEventListener('contextmenu', function (event) {
        // Check if the right-clicked element has the specified class name
        if (event.target.classList.contains('mce-spellchecker-annotation')) {
            const elementRect = event.target.getBoundingClientRect();
            const containerElement = document.querySelector('.editor__container')
            const containerRect = containerElement.getBoundingClientRect();
            // checks if element will leave container, and adjusts with class
            if ((elementRect.right + 250) > containerRect.width) {
                const parentWithToxMenu = $('.tox-menu')
                parentWithToxMenu.addClass('tox-menu--left');
            }
        }
    });
});

$(document).on("turbo:frame-render", function(event) {
    hideCachedLoader()
    showContent()
    initializeTooltips()
})
$(document).on('turbo:click', function(event) {
    const shouldPreventLoader = $(event.target).closest('[data-opensmodal="true"]').length > 0;
    const hasCachedLoader = $('.cached-skel-loader')
    if (!shouldPreventLoader && hasCachedLoader.length) {
        hasCachedLoader.show()
    }
})

document.addEventListener('turbo:click', function(event) {

    const hangedTooltips = document.querySelectorAll('.tooltip')
    if (hangedTooltips.length) {
        hangedTooltips.forEach((element) => {
            element.parentNode.removeChild(element);
        });
    }
})

document.addEventListener('turbo:submit-end', function(event) {

    const voiceModal = $('#newVoiceModal')
    if (voiceModal.length > 0) {
        console.log("ROLL turbo:submit-end to close first modal on successful cowriter creation")
    const showingVoiceModal = !voiceModal.hasClass('hidden')
        if (showingVoiceModal) {
            const { ok } = event.detail.fetchResponse.response;
            if (ok) {
                // Handle successful submission
                hideModal('newVoiceModal');
            }
        }
    }

    setTimeout(() => {
        initializeTooltips()

        $('.btnLoader').each(function () {
            // If .btnLoader is visible, hide it
            if (!$(this).is(':hidden')) {
                $(this).css('display', 'none');

                // Find the previous sibling span and change its opacity to 1
                $(this).prev('span').css('opacity', '1');
            }
        });
    }, 202)
});

const showToast = (domNode) => {
    const toast = new bootstrap.Toast(domNode);
            toast.show()
}

