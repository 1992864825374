import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.addEventListener('click', this.handleClickOutside.bind(this));
  }

  disconnect() {
    window.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  handleClickOutside(event) {
    const button = $("button[aria-label='Reveal or hide additional toolbar items'].tox-tbtn--enabled");

    // Check if the click is outside the button
    if (button.length && !button.is(event.target) && button.has(event.target).length === 0) {
      button.click();
      button.blur();
    }
  }
}