import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ['trendingUp', 'trendingDown', 'trendOverlay', 'trendPercent']

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: articles--trend-overlay");

    // SET VARIABLES
    this.currentValue = this.data.get("current")
    this.previousValue = this.data.get("previous")
    this.changePercent = this.calculateChange()

    if(this.changePercent){

      // SHOW TREND
      if(this.changePercent > 0){
        $(this.trendingUpTargets).show().css('display', 'inline-block');
      } else {
        $(this.trendingDownTargets).show().css('display', 'inline-block');
      }

      // SET PERCENT IN DIALOG
      $(this.trendPercentTarget).text(`${this.changePercent}%`)
    }
  }

  calculateChange(){
    // CALCULATE CHANGE PERCENT
    var previousValue = parseFloat(this.previousValue);
    if (previousValue === 0 && this.currentValue > 0){
      return 100;
    } else if (previousValue > 0) {
      let change = ((this.currentValue - previousValue) / previousValue * 100).toFixed(0);
      return change;
    } else {
      return false;
    }
  }

  mouseover(){
    if (window.innerWidth > 768 && this.changePercent) {

      $(this.trendOverlayTarget).show()
    }
  }

  mouseout(){
    if (window.innerWidth > 768 && this.changePercent) {
      $(this.trendOverlayTarget).hide();
    }
  }
}
