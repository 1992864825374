import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {cleanInputs} from "../../utility_functions";

export default class extends Controller {
    static targets = [
        'content',
        'container'
    ]

    static values = {
        id: Number
    }

    connect() {
        console.log("CONNECTED DROPDOWN")
        this.calculateDropdownPositioning = this.calculateDropdownPositioning.bind(this);
        this.closeOutsideBound = this.closeOutside.bind(this);

        window.addEventListener('filters-dropdown:calculateDropdownPositioning', this.calculateDropdownPositioning)
        document.addEventListener('click', this.closeOutsideBound)

        this._onClickRadio = this.onClickRadio.bind(this);
        this._passRadioEvent = this.passRadioEvent.bind(this);

        $('.container-label').on('click', this._onClickRadio);
        $('.container-label input').on('click', this._passRadioEvent);
        //debugger;
    }

    calculateDropdownPositioning(event) {
        setTimeout(() => {
            const container = document.querySelector('.filter__bar')
            const element = document.querySelector('.filter__bar .addFilter__dropdown .dropdown-menu')
            const dropdownRect = element.getBoundingClientRect()
            const containerRect = container.getBoundingClientRect()
            const elementOffsetLeft = containerRect.left + containerRect.width <= dropdownRect.left + dropdownRect.width

            if (elementOffsetLeft) {
                if (element.classList.contains('fixLeft'))
                    element.classList.remove('fixLeft')
                element.classList.add('fixRight')
            } else if (containerRect.left > dropdownRect.left) {
                if (element.classList.contains('fixRight'))
                    element.classList.remove('fixRight')
                element.classList.add('fixLeft')
            }

            if (!element.classList.contains('show')) {
                element.classList.add('show')
            }

        }, 50)
    }

    toggle(event) {
        this.calculateDropdownPositioning(event)
        this.contentTarget.classList.toggle('show')
    }

    closeOutside(event) {
        if (
            !this.containerTarget.contains(event.target) // check if button
            &&
            this.contentTarget.classList.contains('show')
        ) {

            window.dispatchEvent(new CustomEvent('filters:closeMenu'))
            if (!this.element.contains(event.target)) {
                this.contentTarget.classList.remove('show')
            }
        }
    }

    passRadioEvent(event) {
        const radioElement = event.target;
        const labelElement = radioElement.parentElement
        labelElement.click()
    }

    onClickRadio(event) {
        cleanInputs()
        const label = event.target;
        const labelFor = label.getAttribute("for")

        const allInputs = document.querySelectorAll('.form-group input')

        if (labelFor) {
            allInputs.forEach(anInput => {
                if (anInput.classList.contains('show')) {
                    anInput.classList.remove('show')
                }
            })
            const input = document.querySelector(`label[for=${labelFor}] + .form-group input`)
            setTimeout(() => {
                input.classList.remove('hidden')
                input.classList.add('show')
                input.focus()
            }, 75)

            // date inputs fix
            const isFilterMenu = document.querySelector('.filter__date-menu.open')
            if (isFilterMenu) {
                const allFormGroups = document.querySelectorAll('.filter__date-menu .form-group')

                if (allFormGroups) {
                    allFormGroups.forEach(formGroupItem => {
                        if (formGroupItem.classList.contains('show')) {
                            formGroupItem.classList.remove('show')
                            formGroupItem.classList.add('hidden')
                        }
                    })
                }

                const formGroup = document.querySelector(`.filter__date-menu label[for=${labelFor}] + .form-group`)
                setTimeout(() => {
                    if (formGroup.classList) {
                        formGroup.classList.remove('hidden')
                        formGroup.classList.add('show')
                        formGroup.focus()
                    }

                }, 75)
            }
        }
    }

    disconnect() {
        window.removeEventListener('filters-dropdown:calculateDropdownPositioning', this.calculateDropdownPositioning);
        document.removeEventListener('click', this.closeOutsideBound);
        $('.container-label').off('click', this._onClickRadio);
        $('.container-label input').off('click', this._passRadioEvent);
    }
}
