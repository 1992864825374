import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";
import {numberWithCommas} from "../../../utility_functions";

export default class extends Controller {

    static targets = [
        'modal',
    ]

    connect(){
        console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--manage-apps");
    }


    changeCategory(e){
        var category = $(e.currentTarget).data("category")

        // UPDATE NAV STATE
        $('#mam__sidebar button').removeClass("active")
        $(e.currentTarget).addClass("active")

        if(category.length > 0){
            // THERE IS A CATEGORY SELECTED
            $(`.tabPane__allApps .modal__content-box:not([data-category="${category}"])`).hide()
            $(`.tabPane__allApps .modal__content-box[data-category="${category}"]`).show()
        } else {
            // NO CATEGORY, ALL
            $('.tabPane__allApps .modal__content-box').show()
        }
    }

    toggleState(event){
        var name = $(event.currentTarget).data('sidebar-id')
        var state = $(event.currentTarget).data("state")
        var element = $(event.currentTarget)
        const refreshNav = this.refreshNav.bind(this);

        // ENTER LOADER UI STATE
        $(event.currentTarget).find(".loader").show();
        $(event.currentTarget).find(".badge").hide();

        fetch(`apps/toggle/${ name }/${state}.json`, {
            headers: {accept: 'application/json'},
            method: "POST"
        })
        .then(data => {
            return data.json();
        }).then(payload => {
            element.data("state", payload.state)

            if(payload.state){
                // ENABLED APP
                element.removeClass("disabled")
                element.addClass("enabled")
                $(element).find('.badge.enabled').show();
            } else {
                // DISABLED APP
                element.removeClass("enabled")
                element.addClass("disabled")
                $(element).find('.badge.disabled').show();

                // RETURN TO DEFAULT INCASE IT'S OPEN
                window.dispatchEvent(new CustomEvent("sidebar:backtodefault"));
            }

            // EXIT LOADING STATE
            $("#manageAppsModal .loader").hide();

            // UPDATE SIDEBAR NAV TURBO FRAME
            refreshNav();
        });
    }

    refreshNav(){
        Turbo.visit(`/p/${window.projectShortcode}/writer/apps`, { responseKind: 'turbo-frame', frame: 'sidebar_nav' })
        Turbo.visit(`/p/${window.projectShortcode}/writer/apps`, { responseKind: 'turbo-frame', frame: 'top_nav_apps' })
    }
}
