
export function initializeTooltips() {
    // CLEAN TOOLTIPS THAT STICK
    $('.tooltip').remove(); // might be this thing...
    // INITIALIZE BOOTSTRAP TOOLTIPS AGAIN
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))
}

export function cleanTooltips() {
    $('.tooltip').remove();
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const isMobile = () => window.innerWidth <= 769;

export function customSpellcheckAction(editor) {

    var SpellCheckCount = 0;

    const updateCount = (increment) => {
        if (increment) {
            SpellCheckCount++;
        } else {
            SpellCheckCount--;
        }
    }

    editor.execCommand('mceSpellcheckDialog'); // This triggers the spellcheck dialog

    // Wait for the dialog to be available in the DOM
    // THIS MIGHT BE MOBILE PROBLEM
    setTimeout(function() {

        const currentActive = document.querySelector(".mce-spellchecker-annotation")
            currentActive.classList.add("correcting")

        const buttonNext = document.querySelector('.tox-dialog__body .tox-button[title="Next"]');
        const buttonPrevious = document.querySelector('.tox-dialog__body .tox-button[title="Previous"]');



        if (buttonNext) {

            buttonNext.addEventListener(isMobile()? 'touchstart' : 'click', function() {
               if (!document.querySelector('.tox-dialog__body .tox-button[title="Next"]').disabled) {
                   const previousSelected =  document.querySelector(".mce-spellchecker-annotation.correcting")

                   // remove .correcting
                   if (previousSelected) {
                       previousSelected.classList.remove("correcting")
                   }

                   updateCount(true); // Increment count
                   const allMistakes = document.querySelectorAll('.mce-spellchecker-annotation')
                   const editorContainer = document.querySelector('.editor__container');

                   // Check if the selected mistake exists
                   if (allMistakes && allMistakes[SpellCheckCount]) {

                       // remove .correcting
                       const previousSelected =  document.querySelector(".mce-spellchecker-annotation.correcting")
                       if (previousSelected) {
                           previousSelected.classList.remove("correcting")
                       }

                       // Scroll the mistake into view
                       allMistakes[SpellCheckCount].scrollIntoView();
                       allMistakes[SpellCheckCount].classList.add("correcting")

                       // Calculate the position to scroll to
                       const mistakeRect = allMistakes[SpellCheckCount].getBoundingClientRect();
                       const containerRect = editorContainer.getBoundingClientRect();
                       const relativeTop = mistakeRect.top - containerRect.top;
                       const offsetMod =  (window.innerWidth <= 768) ? 546 : 150
                       const desiredTop = relativeTop - offsetMod; // 150px from the top of the container
                       editorContainer.scrollTop = editorContainer.scrollTop + desiredTop;

                   }
               }
            });
        }

        if (buttonPrevious) {
            buttonPrevious.addEventListener(isMobile()? 'touchstart' : 'click', function() {
                if (!document.querySelector('.tox-dialog__body .tox-button[title="Previous"]').disabled) {
                    // remove .correcting
                    const previousSelected = document.querySelector(".mce-spellchecker-annotation.correcting")
                    if (previousSelected) {
                        previousSelected.classList.remove("correcting")
                    }

                    updateCount(false); // Decrement count
                    const allMistakes = document.querySelectorAll('.mce-spellchecker-annotation')

                    const editorContainer = document.querySelector('.editor__container');

                    // Check if the selected mistake exists
                    if (allMistakes && allMistakes[SpellCheckCount]) {

                        // Scroll the mistake into view
                        allMistakes[SpellCheckCount].scrollIntoView();
                        allMistakes[SpellCheckCount].classList.add("correcting")

                        // Calculate the position to scroll to
                        const mistakeRect = allMistakes[SpellCheckCount].getBoundingClientRect();
                        const containerRect = editorContainer.getBoundingClientRect();
                        const relativeTop = mistakeRect.top - containerRect.top;
                        const offsetMod = (window.innerWidth <= 768) ? 535 : 150
                        const desiredTop = relativeTop - offsetMod; // 150px from the top of the container

                        // Scroll the container

                        editorContainer.scrollTop = editorContainer.scrollTop + desiredTop;

                    }
                }
            });
        }

    }, 500);
}

export const hideCachedLoader = () => {
    const hasCachedLoader = $('.cached-skel-loader')
    if (hasCachedLoader.length) {
        hasCachedLoader.hide();

        const hr = $('.index__hr-modded')
        if (hr.length) {
            const isShown = hr.hasClass('show')
            if (!isShown) {
                hr.addClass('show')
            }
        }
        const infoBar = $('.index__info-bar')
        if (infoBar.length) {
            const isShown = infoBar.hasClass('show')
            if (!isShown) {
                infoBar.addClass('show')
            }
        }
    }
}

export const showContent = () => {
    // this needs to be out of previous block to prevent bugs
    const tableContainer = $('.index__table-container')
    if (tableContainer.length) {
        const isShown = tableContainer.hasClass('show')
        if (!isShown) {
            tableContainer.addClass('show')
        }
    }
}

function getPreviousNode(container, offset) {
    if (offset > 0) {
        return container.childNodes[offset - 1];
    } else if (container.previousSibling) {
        return container.previousSibling.lastChild;
    } else {
        return container.parentNode;
    }
}
export const followView = (theEditor) => {
    const selection = theEditor.selection
    const range = selection.getRng()
    const cursorRect = range.getBoundingClientRect()
    const cursorBottom = cursorRect.bottom
    const container = document.querySelector('.editor__container');
    const containerRect = container.getBoundingClientRect()
    const containerBottom = containerRect.bottom

    const startContainer = range.startContainer;
    const previousNode = getPreviousNode(startContainer, range.startOffset);
    let isCursorInView = cursorBottom < containerBottom

    if (isMobile()) {
        const theCustomCursor = document.querySelector('.customCursorLoader');
        if (!theCustomCursor) {
            // here means that new node was added from the large ones,
            // cause cursor does not exist
            return;
        }
        const cursorRect = theCustomCursor.getBoundingClientRect();
        // console.log("theCursor - ", theCustomCursor)
        // console.log("cursorRect - ", cursorRect)
        // console.log("cursorBottom: ", cursorRect.bottom)
        // console.log("containerBottom: ", containerBottom)
        isCursorInView = cursorRect.bottom < (containerBottom / 1.8)

        if (!isCursorInView) {
            container.scrollTo({
                top: container.scrollTop + 100
            })
        }
    } else if (window.innerWidth > 768 &&
                window.innerWidth < 960 &&
                (screen.orientation.type.startsWith("portrait"))) {
        // like mobile but changed magic numbers (keyboard buffer and scroll amount)
        // console.log("this is the tablet thing!")
        const theCustomCursor = document.querySelector('.customCursorLoader');
        if (!theCustomCursor) {
            //console.log("NO custom cursor")
            return;
        }
        const cursorRect = theCustomCursor.getBoundingClientRect();
        isCursorInView = cursorRect.bottom < (containerBottom * 0.33)

        if (!isCursorInView) {
            container.scrollTo({
                top: container.scrollTop + 220
            })
        }
    } else {
        if (previousNode && !isCursorInView) {
            // this follow view is in a different place
            //  command_controller.js followView() in comment
        } else if (cursorBottom === containerBottom) {
            container.scrollTo({
                top: container.height,
                behavior: 'smooth'
            })
        } else if (!isCursorInView) {
            container.scrollTo({
                top: container.scrollTop + 300,
                behavior: 'smooth'
            })
        }
    }
}

export const notImplementedLangs = ['hrv', 'bg', 'nl', 'et', 'el', 'id', 'ja', 'lv', 'lt', 'pl', 'ro', 'ru', 'sk', 'sl', 'tr', 'ua']

export const isValidLink = (link) => {
    const pattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return pattern.test(link);
}

export function scrollToSelected(selectedButton, container) {
    const containerTop = container.scrollTop();
    const containerBottom = containerTop + container.height();
    const elemTop = selectedButton.position().top + containerTop;
    const elemBottom = elemTop + selectedButton.height();
    const leewayValue = 32

    if (elemTop < containerTop) {
        container.scrollTop(elemTop - leewayValue);
    } else if (elemBottom > containerBottom) {
        container.scrollTop(elemBottom - container.height() + leewayValue);
    }
}

export const makeEditorTooltips = () => {
    const buttons = document.querySelectorAll('.tox-editor-container .tox-toolbar__group .tox-tbtn');
    buttons.forEach(button => {
        if (button.getAttribute('aria-label') !== "Cowriter") {
            button.setAttribute("data-bs-toggle", "tooltip");
            button.setAttribute("data-bs-placement", "bottom");
        }
    });
}

// Check if the browser is Safari
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const removeAiCaret = (editor) => {
    if (editor && editor.selection && editor.dom) {
        const oldSpinners = editor.dom.select('.customCursorLoader');
        if (oldSpinners.length > 0) {
            // Save the current selection
            var bookmark = editor.selection.getBookmark(2, true);
            // Use undoManager.ignore to perform the DOM removal without affecting the undo stack
            editor.undoManager.ignore(() => {
                oldSpinners.forEach(spinner => {
                    // Remove each spinner found
                    spinner.parentNode.removeChild(spinner);
                });
            });
        }
        const prompt = $('.inline__command-prompt.open')
        if (!prompt.length && bookmark) {
            // Restore the saved selection if prompt is closed
            editor.selection.moveToBookmark(bookmark);
        }
    }

    $('body').removeClass('showingLargeLoader generating')
    const largeLoader = $('#customCursorLoaderLarge')
    largeLoader.hide()
    largeLoader.removeClass('size-2', 'size-3', 'size-4')
}

export const cleanHtml = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    // Remove elements with class .hideThis
    const hiddenElements = tempDiv.querySelectorAll('.hideThis');
    hiddenElements.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    // Handle elements with class .emph__selection
    const spans = tempDiv.querySelectorAll('span.emph__selection');
    if (spans.length > 0) {
        spans.forEach((hangedSelection) => {
            var docFrag = document.createDocumentFragment();
            while (hangedSelection.firstChild) {
                docFrag.appendChild(hangedSelection.firstChild);
            }
            hangedSelection.parentNode.replaceChild(docFrag, hangedSelection);
        });
    }

    const contentDocument = tinymce.activeEditor.getDoc();
    const hiddenElementsInDom = contentDocument.querySelectorAll('.hideThis');
    hiddenElementsInDom.forEach((element) => {
        element.parentNode.removeChild(element);
    });

    return tempDiv.innerHTML;
}

export const placeCursorAtClickPosition = (editor, x, y)=> {
    const editorBody = editor.getBody();

    if (document.caretRangeFromPoint) {
        const range = editorBody.ownerDocument.caretRangeFromPoint(x, y);

        if (range) {
            const sel = editorBody.ownerDocument.defaultView.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    } else if (document.caretPositionFromPoint) {
        const caretPos = editorBody.ownerDocument.caretPositionFromPoint(x, y);

        if (caretPos) {
            const range = editorBody.ownerDocument.createRange();
            range.setStart(caretPos.offsetNode, caretPos.offset);
            range.collapse(true);

            const sel = editorBody.ownerDocument.defaultView.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    } else {
        console.log("Browser does not support caret positioning from point.");
    }
}

// Function to calculate the top offset to the relative .editor__container
export const getOffsetTopRelativeToContainer = (element, container) => {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    // The top offset of the element relative to the container
    const offsetTop = elementRect.top - containerRect.top;

    return offsetTop;
}

export const getParentTagOfSelection = (editor) => {
    let parentTag = null;
    const selectedNode = editor.selection.getNode(); // Get the currently selected node

    if (selectedNode.nodeName.toLowerCase() === 'span') {
        // If the selected node is a span, get its parent node's tag name
        parentTag = selectedNode.parentNode.nodeName.toLowerCase();
    } else {
        // If the selected node is not a span but is one of the other target tags, use it directly
        if (['p', 'h2', 'h3', 'h4'].includes(selectedNode.nodeName.toLowerCase())) {
            parentTag = selectedNode.nodeName.toLowerCase();
        }
    }

    return parentTag; // Return the parent tag, or null if not found or not applicable
}

export const reloadPage = () => {
    Turbo.visit(window.location.toString(), { action: "replace" });
}

export const focusPrompt = () => {
    try {
        const promptInput = $('#inlinecommandInput')
        const promptContainer = $('.inline__command-prompt')
        // Check if the element with the ID exists and if the specific class has 'open'
        if (promptInput.length > 0 && promptContainer.hasClass('open')) {
            // If the element exists and the condition is true, set focus to the element
            promptInput.focus();
        }
    } catch (error) {
        //console.log('Handled focusPrompt error:', error);
    }
}

export const closeMobileMenus = () => {
    $('.writer__mobile-menu.open').removeClass("open")
    $('.mobile-menu.show.expanded').removeClass("show expanded")
}

export const editorDropLock = () => {
    const editorContainer = $('.editor__container');
    $('<div id="readonly"></div>').appendTo(editorContainer);
    $('#tinymce').attr("data-typer", "ai");
}

export const editorDropUnlock = () => {
    $('#readonly').remove();
    $('#tinymce').attr("data-typer", "human");
}
export const cleanInputs = () => {
    const cleanInputs = document.querySelectorAll('.radio__group input[type="text"], .radio__group input[type="number"]')
    cleanInputs.forEach(item => {
        item.value = '';
        if (item.classList.contains('show')) {
            item.classList.remove('show')
        }
    })

    const formGroupsDate = document.querySelectorAll('.filter__date-menu .form-group')
    formGroupsDate.forEach(item => {
        if (item.classList.contains('show')) {
            item.classList.remove('show')
        }
    })
}

export const autosaveState = {
    preventAutosave: false,
    setPreventAutosave(prevent) {
        this.preventAutosave = prevent;
    },
    shouldPreventAutosave() {
        return this.preventAutosave;
    }
};

export const wrapSelectionWithEmph = () => {
    const editor = tinymce.activeEditor
    const selection = editor.selection.getContent()
    const bookmark = editor.selection.getBookmark()
    const wrappedSelection = `<span class="emph__selection">${selection}</span>`
    editor.selection.setContent(wrappedSelection);
    editor.selection.moveToBookmark(bookmark);
}

export const unwrapSelectionFromSpan = () => {
    const editor = tinymce.activeEditor;
    const bookmark = editor.selection.getBookmark();

    console.log("bookmark: ", bookmark)

    const spans = editor.dom.select('span.emph__selection');
    spans.forEach(span => {
        const content = span.innerHTML;
        editor.dom.remove(span);
        editor.selection.setContent(content);
    });
    editor.selection.moveToBookmark(bookmark);
};

export const showModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    const modalContent = modalElement.querySelector('.modal__content')
    const modalBackdrop = modalElement.querySelector('.modal__backdrop')
    modalElement.classList.remove('hidden')
    setTimeout(() => {
        modalBackdrop.classList.add('show')
        modalContent.classList.add('show')
    }, 1)

    // CLEAN TOOLTIPS
    initializeTooltips()
}

export const hideModal = (modalId) => {
    // CLEAN TOOLTIPS
    initializeTooltips()
    
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
        const modalContent = modalElement.querySelector('.modal__content')
        const modalBackdrop = modalElement.querySelector('.modal__backdrop')
        modalElement.classList.add('hidden')
        setTimeout(() => {
            modalBackdrop.classList.remove('show')
            modalContent.classList.remove('show')
        }, 1)
    }
}

export const initializeValidetta = () => {
    let validettaOptions = {
        realTime: true,
        validators: {
            regExp: {
                valid_url: {
                    pattern : /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()@:%_\+.~#?&=/,-]*$/i,
                    errorMessage: "Enter a URL that begins with http or https."
                }
            }
        },
        onError: function( event ){
            $($(event.currentTarget).find('button')).blur();
        }
    }
    $("form.validetta").validetta(validettaOptions);
}

export const toast = (mode, message) => {
    var imagePath = {'alert': '/alert-circle.svg', 'notice': '/check-circle.svg'}[mode]
    var className = {'alert': 'text-bg-danger', 'notice': 'text-bg-success'}[mode]
    $('.toast').remove();
    $('body').append(`<div class="toast align-items-center ${className}" role="alert" aria-live="assertive" aria-atomic="true"><div class="toast-body"><img src="${imagePath}"> ${message}<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button></div></div>`);
    var toast = new bootstrap.Toast($('.toast'));
    toast.show();
}