import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips, showModal} from "../../../utility_functions";


export default class extends Controller {

  static targets = [ "pdfInput", "pdfFormSubmitButton", "inputUrlContainer", "option" ]

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: voices--new");
  }

  clearLinkInput() {
    this.addSkelLoader()
    this.element.querySelector('#page-url-input').value = ''
  }

  modalHide(event) {
    const url = event.target.dataset.redirectUrl
    Turbo.visit(url);
  }

  newInstance(){
    initializeTooltips();

    // GENERATE TEMPORARY IDENTIFIER FOR SOURCES
    this.temporaryIdentifier = [...Array(12)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');

    // SHOW THE MODAL
    showModal('newVoiceModal')
  }

  renameModal(){
    const voiceId = event.currentTarget.dataset["voices-NewId"];
    const voiceName = event.currentTarget.dataset["voices-NewName"];
    document.getElementById("generation_profile_edit_name").value = voiceName;
    document.getElementById("edit_voice_form").action = "voices/" + voiceId;
    showModal('renameVoiceModal')
  }

  trainingModal(e){
    initializeTooltips();

    // SET ID
    this.id = $(e.currentTarget).data("id");

  }

  toggleInputUrlContainer(){
    if(this.inputUrlContainerTarget.classList.contains("hidden")){
      this.inputUrlContainerTarget.classList.remove("hidden");
      document.getElementById("page-url-input").focus()
    } else {
      this.inputUrlContainerTarget.classList.add("hidden");
    }
  }

  hideInputUrlContainer(){
    if(this.hasInputUrlContainerTarget && !this.inputUrlContainerTarget.classList.contains("hidden")){
      this.inputUrlContainerTarget.classList.add("hidden");
    }
  }

  hideOptions(){
    this.optionTargets.forEach((ele) => {
      if(!ele.classList.contains("hidden")){
        ele.classList.add("hidden")
      }
    })
  }

  toggleOptions(){
    // this.hideOptions()
    const eleId = event.currentTarget.getAttribute("attr-source-id")
    let optionContainer = document.getElementById(eleId)
    if(optionContainer.classList.contains("hidden")){
      optionContainer.classList.remove("hidden")
    } else {
      optionContainer.classList.add("hidden")
    }
  }

  submitPdf(event){
    const input = this.pdfInputTarget;
    const file = input.files[0];
    const maxFileSize = 52428800; // 50 MB in bytes

    if (file) {
      if (file.size > maxFileSize) {
        const event = new CustomEvent('flash', {
          detail: {
            mode: 'alert',
            message: 'File must be less than 50MB.'
          }
        });
        document.dispatchEvent(event);
        input.value = "";

        return
      }

      if (file.type !== "application/pdf") {
        const event = new CustomEvent('flash', {
          detail: {
            mode: 'alert',
            message: 'Only PDF files are allowed'
          }
        });
        document.dispatchEvent(event);
        input.value = "";

        return
      }

      this.addSkelLoader()
      this.pdfFormSubmitButtonTarget.click()
    }
  }

  deleteSource(){
    const deleteUrl = event.currentTarget.getAttribute("attr-delete-sourse-url");
    const source_material_id = event.currentTarget.getAttribute("attr-source-material-id");

    $(`#source_material-${source_material_id}`).remove();
    var requestType = "DELETE"  
    this.hitEndPoint(deleteUrl,requestType);
  }

  retrySource(){
    const retryUrl = event.currentTarget.getAttribute("attr-retry-sourse-url");
    const source_material_id = event.currentTarget.getAttribute("attr-source-material-id");
    var requestType = "POST"  
    this.hitEndPoint(retryUrl,requestType);
    const statusIcon = document.getElementById('status-icon-'+source_material_id);
    statusIcon.innerHTML = '<img src="/assets/loading.svg" alt="Failed" style="width: 23px;">';
  }

   async hitEndPoint(retryUrl,requestType){
   
    try {
      const response = await fetch(retryUrl, {
        method: requestType,
        headers: {
          "Content-Type": "application/json",
          Accept: requestType === "DELETE" ? "application/json" : "text/html",
          "X-CSRF-Token": this.getMetaValue("csrf-token"),
        }
      });
     
    }catch (error) {
      // Handle any errors that occurred during the fetch request
      console.log(error)
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute("content");
  }

  handleDragOver(event){
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  }

  handleFileSelect(event) {
    event.preventDefault()
    event.stopPropagation()
    const files = event.dataTransfer.files; // FileList object

    if (files.length > 0) {
      this.pdfInputTarget.files = files; // Set the file input's files to the dropped files
      this.submitPdf(event)
    }
  }

  addSkelLoader() {
    const sourcesList = document.querySelector('#voiceTrainingModal .sideBar__list-container');
    const skeletonLoaderHTML = `<div class="sideBar__list d-flex justify-content-between">
      <div class="skel-loader">
        <div class="skel-circle"></div>
        <div class="flex-col skel-row">
          <div class="skel-col mt-0 mb-0"></div>
          <div class="skel-col mt-0 mb-0"></div>
        </div>
        <div class="flex-row skel-row">
          <div class="skel-col"></div>
          <div class="skel-col w-10"></div>
        </div>
      </div>
    </div>`
    const skeletonLoaderNode = document.createElement('div')
    skeletonLoaderNode.innerHTML = skeletonLoaderHTML
    sourcesList.insertBefore(skeletonLoaderNode, sourcesList.firstChild);
  }
}
