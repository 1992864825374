import {Controller} from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "form",
    "formArchive",
    "formPublish",
    "formRestore",
    "formDuplicate",
    "formRemoveCluster",
    "removeFromFolder",
    "removeFolder",
    "record"
  ]

  connect() {
    $("#confirmationModal").on('shown.bs.modal', () => {
      if ($("#confirmationModal").hasClass('show'))
        document.querySelector("#confirmationModal .btn.orange").focus();
    })
  }

  publish(event) {
    event.preventDefault()

    const anyRecordIsSelected = window.selectedIds.length > 0

    if (anyRecordIsSelected) {
      this.addRecordsToForm()
      this.publishRecords(event)
    } else {
      this.showError()
    }
  }

  archive(event) {
    event.preventDefault()

    const anyRecordIsSelected = window.selectedIds.length > 0

    if (anyRecordIsSelected) {
      this.addRecordsToForm()
      this.confirmAndArchiveRecords(event)
    } else {
      this.showError()
    }
  }

  restore(event) {
    event.preventDefault()

    const anyRecordIsSelected = window.selectedIds.length > 0
    if (anyRecordIsSelected) {
      this.addRecordsToRestoreForm()
      this.confirmAndRestoreRecords(event)
    } else {
      this.showError()
    }
  }

  addRecordsToRestoreForm() {
    window.selectedIds.map( record_id => {
      let newRecordField = document.createElement('input')
      newRecordField.name = 'record_ids[]'
      newRecordField.type = 'hidden';
      newRecordField.value = record_id;

      this.formRestoreTarget.append(newRecordField)
    })
  }

  confirmAndRestoreRecords() {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
          e.preventDefault();

          Rails.fire(this.formRestoreTarget, 'submit');

          myModal.hide();
      });
    }

    myModal.show();
  }

  duplicate(event) {
    event.preventDefault()

    const anyRecordIsSelected = window.selectedIds.length > 0

    if (anyRecordIsSelected) {
      this.addRecordsToForm()
      this.confirmAndDuplicateRecords(event)
    } else {
      this.showError()
    }
  }

  showError() {
    this.dispatch('notification', {
      detail: {
        mode: 'alert',
        message: 'Please select at least one record to archive'
      }
    })
  }

  publishRecords(event) {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
          e.preventDefault();

          Rails.fire(this.formPublishTarget, 'submit');

          myModal.hide();
      });
    }

    myModal.show();

    // TRY FOCUS HERE
  }

  confirmAndArchiveRecords(event) {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
          e.preventDefault();

          Rails.fire(this.formArchiveTarget, 'submit');

          myModal.hide();
      });
    }

    myModal.show();

    // TRY FOCUS HERE
  }

  confirmAndDuplicateRecords(event) {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
          e.preventDefault();

          Rails.fire(this.formDuplicateTarget, 'submit');

          myModal.hide();
      });
    }

    myModal.show();

    // TRY FOCUS HERE
  }

  addRecordsToForm() {
    window.selectedIds.map( record_id => {
      let newRecordField = document.createElement('input')
      newRecordField.name = 'record_ids[]'
      newRecordField.type = 'hidden';
      newRecordField.value = record_id;

      $(this.formTargets).append(newRecordField)
    })
  }

  removeFromFolder(event) {
    event.preventDefault()
    event.stopPropagation()

    const anyRecordIsSelected = window.selectedIds.length > 0

    if (anyRecordIsSelected) {
      this.addRecordsToFolderRemoveForm()
      this.confirmAndRemoveFromFolder(event)
    } else {
      this.showError()
    }
  }
  
  addRecordsToFolderRemoveForm() {
    window.selectedIds.map( record_id => {
      let newRecordField = document.createElement('input')
      newRecordField.name = 'record_ids[]'
      newRecordField.type = 'hidden';
      newRecordField.value = record_id
      this.removeFromFolderTarget.append(newRecordField)
    })
  }

  confirmAndRemoveFromFolder(event) {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
        e.preventDefault();

        Rails.fire(this.removeFromFolderTarget, 'submit');

        myModal.hide();
      });
    }

    myModal.show();
  }

  confirmAndRemoveFolder(event) {
    event.preventDefault();

    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
        e.preventDefault();

        Rails.fire(this.removeFolderTarget, 'submit');

        myModal.hide();
      });
    }

    myModal.show();
  }

  removeFromCluster(event) {
    event.preventDefault()
    event.stopPropagation()

    const anyRecordIsSelected = window.selectedIds.length > 0

    if (anyRecordIsSelected) {
      this.addRecordsToClusterForm()
      this.confirmAndRemoveFromCluster(event)
    } else {
      this.showError()
    }
  }

  confirmAndRemoveFromCluster(event) {
    const myModal = new bootstrap.Modal(document.getElementById("confirmationModal"), {});
    const { confirmsMessage } = event.currentTarget.dataset
    const innerMessage = document.querySelector("#confirmationModal span.confirmMessage");
    innerMessage.textContent = confirmsMessage;
    const confirmButton = document.querySelector("#confirmationModal .btn.orange");

    if (confirmButton) {
      confirmButton.addEventListener('click', (e) => {
        e.preventDefault();

        Rails.fire(this.formRemoveClusterTarget, 'submit');

        myModal.hide();
      });
    }

    myModal.show();
  }
  addRecordsToClusterForm() {
    window.selectedIds.map( record_id => {
      let newRecordField = document.createElement('input')
      newRecordField.name = 'record_ids[]'
      newRecordField.type = 'hidden';
      newRecordField.value = record_id
      this.formRemoveClusterTarget.append(newRecordField)
    })
  }

  disconnect() {
    $("#confirmationModal").off('shown.bs.modal');
  }
}
