import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {isValidLink} from "../../utility_functions";

export default class extends Controller {
    static targets = [
        'input',
        'preview',
        'inputUrlContainer',
        'newVoiceUrl',
        'voiceLink'
    ]

    connect() {
        console.log("🔌 CONNECTED: upload_controller")
        if (this.hasInputTarget) {
            this.inputTarget.multiple = true // might be unnecessary
        }
    }

    preview() {
        if (this.hasInputTarget) {
            if (this.inputTarget.files && this.inputTarget.files.length > 0) {
                Array.from(this.inputTarget.files).forEach((file) => {
                    const reader = new FileReader()
                    reader.onload = (event) => {

                        // validate pdf
                        if (file.name.split('.').pop() !== 'pdf') {
                            const event = new CustomEvent('flash', {
                                detail: {
                                    mode: 'alert',
                                    message: 'Please upload a PDF file'
                                }
                            });
                            document.dispatchEvent(event);
                            return
                        }

                        this.createContainerBox(file.name)
                    }
                    reader.readAsDataURL(file)
                })
            }
        }
    }

    urlFormReset(event) {
      this.toggleInputUrlContainer();
      event.target.reset();
    }

    addLinks(event) {
        const newLink = this.newVoiceUrlTarget.value;

        const isLink = isValidLink(newLink)
        if (isLink) {
            $('.preview__box-urlSubmit').click()
        }
        this.toggleInputUrlContainer()
    }

    toggleInputUrlContainer(){
        if(this.inputUrlContainerTarget.classList.contains("hidden")){
            this.inputUrlContainerTarget.classList.remove("hidden");
            document.getElementById("page-url-input").focus()
        } else {
            this.inputUrlContainerTarget.classList.add("hidden");
        }
    }

    updateVoiceLink(event) {
        event.preventDefault(); // prevent the default form submission

        // Append the new link to the current list, separated by a comma or any other delimiter
        const currentLinks = this.voiceLinkTarget.value;
        const newLink = this.newVoiceUrlTarget.value;

        const isLink = isValidLink(newLink)
        if (!isLink) {
            const event = new CustomEvent('flash', {
                detail: {
                    mode: 'alert',
                    message: 'Please enter a valid link ("http://" or "https://")'
                }
            });
            document.dispatchEvent(event);
            return
        }

        if (currentLinks) {
            this.voiceLinkTarget.value = `${currentLinks},${newLink}`;
        } else {
            this.voiceLinkTarget.value = newLink;
        }

        this.createContainerBox(newLink)

        this.newVoiceUrlTarget.value = '';
        this.newVoiceUrlTarget.focus();
    }

    createContainerBox(newLink) {
        const container = this.previewTarget

        const containerBox = document.createElement('div');
        containerBox.classList.add('preview__box');

        const checkCircleContainer = document.createElement('span');
        checkCircleContainer.classList.add('checkCircle__container');
        checkCircleContainer.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">\n' +
            '<circle cx="17.5008" cy="17.4998" r="13.7879" fill="#C2EED8"/>\n' +
            '<path d="M15.4309 20.6305L12.0431 17.2426C11.8412 17.0407 11.5874 16.9374 11.2817 16.9327C10.976 16.9281 10.7175 17.0313 10.5062 17.2426C10.295 17.4539 10.1893 17.71 10.1893 18.011C10.1893 18.312 10.295 18.5682 10.5062 18.7794L14.5082 22.7814C14.7718 23.045 15.0794 23.1769 15.4309 23.1769C15.7824 23.1769 16.0899 23.045 16.3536 22.7814L24.4669 14.6681C24.6689 14.4662 24.7722 14.2124 24.7768 13.9067C24.7815 13.601 24.6782 13.3425 24.4669 13.1312C24.2557 12.92 23.9995 12.8143 23.6985 12.8143C23.3975 12.8143 23.1414 12.92 22.9301 13.1312L15.4309 20.6305ZM17.5031 31.3547C15.5869 31.3547 13.7858 30.9911 12.0998 30.2639C10.4137 29.5367 8.94711 28.5498 7.69992 27.3031C6.45271 26.0565 5.46533 24.5905 4.73779 22.9052C4.01025 21.2199 3.64648 19.4192 3.64648 17.5031C3.64648 15.5869 4.0101 13.7858 4.73732 12.0998C5.46454 10.4137 6.45147 8.94712 7.6981 7.69992C8.94476 6.45271 10.4107 5.46533 12.096 4.73779C13.7813 4.01026 15.582 3.64648 17.4982 3.64648C19.4143 3.64648 21.2154 4.0101 22.9014 4.73732C24.5875 5.46454 26.0541 6.45147 27.3013 7.6981C28.5485 8.94476 29.5359 10.4107 30.2634 12.096C30.991 13.7813 31.3547 15.582 31.3547 17.4982C31.3547 19.4143 30.9911 21.2154 30.2639 22.9014C29.5367 24.5875 28.5498 26.0541 27.3031 27.3013C26.0565 28.5485 24.5905 29.5359 22.9052 30.2634C21.2199 30.991 19.4192 31.3547 17.5031 31.3547ZM17.5006 29.1673C20.7576 29.1673 23.5162 28.0371 25.7767 25.7767C28.0371 23.5162 29.1673 20.7576 29.1673 17.5006C29.1673 14.2437 28.0371 11.485 25.7767 9.22457C23.5162 6.96416 20.7576 5.83395 17.5006 5.83395C14.2437 5.83395 11.485 6.96416 9.22457 9.22457C6.96416 11.485 5.83395 14.2437 5.83395 17.5006C5.83395 20.7576 6.96416 23.5162 9.22457 25.7767C11.485 28.0371 14.2437 29.1673 17.5006 29.1673Z" fill="#228252"/>\n' +
            '</svg>'
        containerBox.append(checkCircleContainer);

        const fileName = document.createElement('span')

        fileName.className = "preview__file-name"
        fileName.innerText = newLink
        containerBox.append(fileName)

        // some weird part with the dropdown
        const dropdownDiv = document.createElement('div');
        dropdownDiv.className = "dropdown";

        const span = document.createElement('span');
        span.setAttribute("data-bs-toggle", "dropdown");
        span.setAttribute("aria-haspopup", "true");
        span.setAttribute("aria-expanded", "false");

        span.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>';
        dropdownDiv.append(span);

        const dropdownMenuDiv = document.createElement('div');
        dropdownMenuDiv.className = "dropdown-menu";
        dropdownMenuDiv.setAttribute("aria-labelledby", "dropdownMenuButton");

        // you'll need to modify the link_to part
        dropdownMenuDiv.innerHTML = "<!-- Link Content Here -->";
        dropdownDiv.append(dropdownMenuDiv);

        containerBox.append(dropdownDiv);

        container.append(containerBox)
    }
}
