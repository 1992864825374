import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";
import Rails from "@rails/ujs";
import {editorDropLock, editorDropUnlock} from "../../../utility_functions";

export default class extends Controller {

  static targets = [
    "intentsTable",
    "sortableItem" // these need to have data-sortable-order
  ]

  static values  = {
    source: String,
    url: String
  }

  connect() {
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--sortable")

    if (this.hasSourceValue && this.sourceValue === "writer") {
      document.addEventListener('sortable:init', this.initSortable.bind(this))
      document.addEventListener('sortable:destroy', this.destroySortable.bind(this))
    }

    if (this.hasSourceValue && this.sourceValue === "drafts_flow") {
      console.log("it does runs")
      document.addEventListener('sortable:init', this.initSortable.bind(this))
      this.initSortable()
    }
  }

  onDragStart(event) {
    if (this.hasSourceValue && this.sourceValue === "writer") {
      editorDropLock()
    }
  }

  onDragEnd(event) {
    const items = this.sortableItemTargets;

    // update data-sortable-order
    items.forEach((item, index) => {
      if (item.dataset.sortableOrder !== index.toString()) {
        item.dataset.sortableOrder = index
      }
    })

    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: this.urlValue.replace(":id", id),
      type: "PATCH",
      data: data
    })

    if (this.hasSourceValue && this.sourceValue === "writer") {
      editorDropUnlock()
    }
  }

  initSortable() {
    const containerElement = this.element
    this.sortable = Sortable.create(containerElement, {
      handle: '.dr__dragIcon', // specifies the handle selector
      animation: 150,
      ghostClass: 'sortable-ghost', // Class name for the drop placeholder
      dragClass: 'sortable-drag',
      onStart: (event) => {
        this.onDragStart(event)
      },
      onEnd: this.onDragEnd.bind(this),
    });
  }

  destroySortable() {
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }

  disconnect() {
    if (this.hasSourceValue && this.sourceValue === "writer" && this.sortable) {
      // Correct method name is removeEventListener
      document.removeEventListener('sortable:init', this.initSortable.bind(this))
      document.removeEventListener('sortable:destroy', this.destroySortable.bind(this))
    }
    if (this.sortable) {
      this.sortable.destroy()
      this.sortable = null
    }
  }
}
