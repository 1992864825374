import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import {initializeTooltips} from "../../../utility_functions";
import {numberWithCommas} from "../../../utility_functions";
import Cookies from "js-cookie"

export default class extends Controller {

  static targets = ['listItem']

  connect(){
    console.log("🟢 STIMULUS CONTROLLER OPERATIONAL: writer--font")

    if(Cookies.get('fontPreference') === undefined){
      this.font = "space_grotesk"
    } else {
      this.font = Cookies.get('fontPreference')
    } 

    this.setFont()
    this.updateFont()
  }

  change(e){
    e.preventDefault();

    this.font = $(e.currentTarget).parent().data('font-id')
    console.log(this.font)
    this.updateFont(this.font)
  }

  setFont(){
    $('.writer--font ul li .dynamic-check-holder').hide()
    $(`.writer--font ul li[data-font-id='${this.font}'] .dynamic-check-holder`).show()
    $('.content__container').attr("class", `content__container ${this.font}`);
  }

  updateFont(){
    Cookies.set('fontPreference', this.font)
    this.setFont()
  }
  
}
