import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "checkboxLabel",
    "checkboxInput",
    "valueStore"
  ]

  change(e){
    var checked = $(e.currentTarget).is(':checked') ? true : false
    var parent = $(e.currentTarget).parent();

    $(this.checkboxLabelTargets).removeClass("active")
    $(this.checkboxInputTargets).prop("checked", false);

    if(checked){
      $(parent).addClass("active")
      $(e.currentTarget).find('input').prop("checked", true);
      $(this.valueStoreTarget).val($(parent).find('h5').text());
    }
  }
}
