import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "name", "output" ]
    shown = document.querySelectorAll(".formInputs__container .form__group").length;


    addArticleInput() {
        const articleGroup = `<div class="form__group"><input class="form-control" type="text" placeholder="Enter a URL" data-validetta="url" name="articles[${this.shown+1}]"></div>`
        const containerNode = document.createElement('div');
        containerNode.innerHTML = articleGroup
        this.outputTarget.appendChild(containerNode)
        this.shown+=1;
    }
    addTeammateInput() {
        const teammateGroup = `<div class="form__group"><input class="form-control" type="text" placeholder="name@yourcompany.com" data-validetta="email" name="teammates[${this.shown+1}]"></div>`
        const containerNode = document.createElement('div');
                containerNode.innerHTML = teammateGroup
        this.outputTarget.appendChild(containerNode)
        this.shown+=1;
    }
}